import React from 'react';
import { Toast } from 'primereact/toast';
import { useTranslation } from 'react-i18next';
import { setIsLoading } from '../../../store/actions/Loading';
import axiosInstance from '../../../containers/services/axiosInstance';

const ServiceApplicantCard = ({ item, ...props }) => {

    const toast = React.useRef(null);
    const { t, i18n } = useTranslation();
    const Lang = localStorage.getItem("i18nextLng");
    const [error, setError] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    function markDone() {
        setIsLoading(true);
        axiosInstance().post(`/service-applicant/${item.id}/status`, { 'status': 'done' }, { 'headers': { 'locale': Lang } })
            .then((res) => {
                setIsLoading(false);
                toast.current.show({ severity: 'success', summary: t("success"), detail: t("successMsg"), life: 2000 });
                props.onDone(item.id)
            })
            .catch((err) => {
                setIsLoading(false);
                if (err.response !== null & err.response !== undefined) {
                    toast.current.show({ severity: 'warn', summary: t("warn"), detail: err.response.data.details.message })
                } else {
                    toast.current.show({ severity: 'warn', summary: t("warn"), detail: t("tryLater") })
                }
            })
    }

    return (
        <div className="card service-card h-100">
            <div className="pb-4">
                <div className="card-body">
                    <h5 className="card-title">{item.migrant.name}</h5>
                    <h5 className="card-title">{item.migrant.phone}</h5>
                    <h5 className="card-title">{item.migrant.gender}</h5>
                    <h5 className="card-title">{item.migrant.id_number}</h5>
                    <h5 className="card-title">{item.comment}</h5>
                    {
                        item.status != 'done' ?
                            <button className="btn btn-primary" onClick={markDone}>{t('statuses.done')}</button>
                            : ''
                    }
                </div>
            </div>

            {
                error
                    ?
                    < Toast position="top-center" ref={toast} />
                    :
                    < Toast position="top-center" ref={toast} />
            }

            {
                isLoading
                &&
                <div className="text-center spinner-container">
                    <i className="fas fa-circle-notch fa-spin fa-4x"></i>
                </div>
            }
        </div>
    )
}

export default ServiceApplicantCard
