import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../services/axiosInstance';
import { Toast } from 'primereact/toast';
import { useSelector } from "react-redux";
import { Field, Form, Formik } from 'formik';
import { TabView, TabPanel } from "primereact/tabview";


function ReferralFormView({ id }) {

    const { t, i18n } = useTranslation();

    const toast = React.useRef(null);

    const Lang = localStorage.getItem("i18nextLng");

    // loading state
    const [isLoading, setIsLoading] = React.useState(true);

    // saved states
    const SP_Name = useSelector((state) => state.authReducer.user.name);

    // refs
    const formRef = React.useRef();
    const focal_ref = React.useRef();

    const [initialValues, setInitialValues] = useState({

        asylum_type: {
            id: "",
            name: ""
        },
        beneficiary_address: "",
        beneficiary_birthdate: "",
        beneficiary_code: "",
        beneficiary_gender: "",
        beneficiary_informed: "",
        beneficiary_informed_explain: null,
        beneficiary_is_minor: "",
        beneficiary_name: "",
        beneficiary_phone: "",
        id_number: "",
        beneficiary_restrictions: "",
        beneficiary_restrictions_explain: null,
        beneficiary_signed: "",
        beneficiary_signed_explain: "",
        caregiver_contact_info: "",
        caregiver_informed: "",
        caregiver_informed_explain: null,
        caregiver_name: "",
        caregiver_relation: "",
        date: "",
        description: "",

        from_focal_point:
        {
            email: "",
            id: "",
            name: "",
            phone: "",
            position: "",
        },

        to_focal_point:
        {
            email: "",
            id: "",
            name: "",
            phone: "",
            position: "",
        },

        to_service_provider: {
            user: {
                name: '',
                phone: '',
                email: ''
            }
        },

        from_service_provider: {
            user: {
                name: '',
                phone: '',
                email: ''
            }
        }
    });

    useEffect(() => {

        axiosInstance().get(`/referral/${id}`, { 'headers': { 'locale': Lang } })
            .then((res) => {
                setIsLoading(false)
                setInitialValues({ ...res.data.data });
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error);
            })

        let elements = document.querySelectorAll(".referralForm input, .referralForm textarea, .referralForm select")

        for (var i = 0; i < elements.length; i++) {
            elements[i].disabled = true
        }


    }, [Lang]);


    const checkboxTemp = (num) => {

        return (
            <>
                <TabView>

                    {
                        num
                            ?
                            <TabPanel header={t("yes")} />
                            :
                            num !== null
                                ?
                                <TabPanel header={t("no")} />
                                :
                                null
                    }


                </TabView>
                {
                    num == null
                    &&
                    <div className="mt-3">
                        <span className="mx-3">yes</span>
                        <span className="mx-3">no</span>
                    </div>
                }
            </>
        )
    }

    function formBody() {
        return (
            <Form>

                {
                    initialValues.priority
                    &&

                    <div className="row no-gutters px-2 mt-4 mb-4">
                        <div className="labels d-flex justify-content-between w-100">
                            <label>Priority</label>
                            <label>الاولوية</label>
                        </div>
                        <select
                            disabled
                            className="w-100 input-style"
                            style={{ marginTop: "1px" }}
                        >
                            <option value="" disabled selected>
                                {t(`referral.${initialValues.priority}`)}
                            </option>

                        </select>
                    </div>
                }

                {/* 1st row */}
                <div className="row">

                    <div className="from-col col-md-6 px-4">

                        <h2 className="h1 reset-bold title-color form-head">REFERRAL FORM</h2>
                        <p className="text-start ref-hint">Referred by: specify name of agency making referral and name of person making  the referral</p>
                        <p className="text-end ref-hint">إحالة من: تحديد اسم المنظمة المُرسِلة والشخص المسؤول الذي قام بالاحالة </p>

                        <div className="selects mt-5">

                            <div className="mb-3">
                                <div className="labels d-flex justify-content-between w-100">
                                    <label>agency</label>
                                    <label>المنظمة</label>
                                </div>

                                <select
                                    disabled
                                    className="w-100 input-style"
                                    style={{ marginTop: "1px" }}
                                >
                                    <option>
                                        {initialValues.from_service_provider.user.name}
                                    </option>

                                </select>
                            </div>

                            <div className="mb-3">
                                <div className="labels d-flex justify-content-between w-100">
                                    <label>Name</label>
                                    <label>الاسم</label>
                                </div>

                                <select
                                    disabled
                                    ref={focal_ref}
                                    className="w-100 input-style"
                                    style={{ marginTop: "1px" }}
                                >
                                    <option disabled selected>
                                        {initialValues.from_focal_point.name}
                                    </option>

                                </select>

                            </div>

                            <div className="mb-3">
                                <div className="labels d-flex justify-content-between w-100">
                                    <label>Position</label>
                                    <label>المنصب</label>
                                </div>

                                <select
                                    disabled
                                    className="w-100 input-style"
                                    style={{ marginTop: "1px" }}
                                >
                                    <option disabled selected>
                                        {initialValues.from_focal_point.position}
                                    </option>


                                </select>

                            </div>

                            <div className="row mb-5 no-gutters">
                                <div className="labels d-flex justify-content-between w-100">
                                    <label>Contact</label>
                                    <label>الهاتف</label>
                                </div>
                                <select
                                    disabled
                                    className="w-100 input-style"
                                    style={{ marginTop: "1px" }}
                                >
                                    <option disabled selected>
                                        {initialValues.from_focal_point.phone}
                                    </option>


                                </select>

                            </div>

                        </div>

                    </div>

                    <div className="to-col col-md-6 px-4">
                        <h2 className="h1 reset-bold title-color form-head text-end"> الإحالة بين المنظمات</h2>
                        <p className="text-start">Referred to: specify name of agency and name of person receiving the referral (include name/phone number of the NGOs Focal Point </p>
                        <p className="text-end">إحالة إلى: تحديد اسم المنظمة واسم الشخص الذي يستلم هذه الإحالة (من ضمنها الاسم/ رقم الهاتف للشخص المسؤول) </p>

                        <div className="selects mt-5">
                            <div className="mb-3">
                                <div className="labels d-flex justify-content-between w-100">
                                    <label>agency</label>
                                    <label>المنظمة </label>
                                </div>

                                <select
                                    disabled
                                    className="w-100 input-style"
                                    style={{ marginTop: "1px" }}
                                >
                                    <option disabled selected>
                                        {initialValues.to_service_provider.user.name}
                                    </option>


                                </select>

                            </div>

                            <div className="mb-3">
                                <div className="labels d-flex justify-content-between w-100">
                                    <label>Name</label>
                                    <label>الاسم</label>
                                </div>

                                <select
                                    disabled
                                    className="w-100 input-style"
                                    style={{ marginTop: "1px" }}
                                >
                                    <option disabled selected>
                                        {initialValues.to_focal_point.name}
                                    </option>

                                </select>

                            </div>

                            <div className="mb-3">
                                <div className="labels d-flex justify-content-between w-100">
                                    <label>Position</label>
                                    <label>المنصب</label>
                                </div>

                                <select
                                    disabled
                                    className="w-100 input-style"
                                    style={{ marginTop: "1px" }}
                                >
                                    <option>
                                        {initialValues.to_focal_point.position}
                                    </option>


                                </select>

                            </div>

                            <div className="row mb-5 no-gutters">
                                <div className="labels d-flex justify-content-between w-100">
                                    <label>Contact</label>
                                    <label>الهاتف</label>
                                </div>
                                <select
                                    disabled
                                    className="w-100 input-style"
                                    style={{ marginTop: "1px" }}
                                >
                                    <option>
                                        {initialValues.to_focal_point.phone}
                                    </option>


                                </select>

                            </div>


                        </div>
                    </div>

                </div>

                {/* 2nd row */}
                <div className="2nd-row">

                    <div className="row mb-3">

                        <div className="col-md-6 px-4 checkbox">

                            <p className="mb-3">Consent</p>

                            <div className="labels d-flex justify-content-between w-100">
                                <label>Beneficiary has been informed of referral?</label>
                                <label>هل تم إعلام المستفيد بالإحالة؟</label>
                            </div>

                            {checkboxTemp(initialValues.beneficiary_informed)}

                        </div>

                        <div className="col-md-6 px-4">

                            <div className="my-3">
                                <div className="labels d-flex justify-content-between w-100">
                                    <label>If no, explain</label>
                                    <label>في حالة كانت الإجابة لا.. وضح لماذا</label>
                                </div>
                                <Field
                                    className="w-100 input-style"
                                    as="textarea"
                                    name="beneficiary_informed_explain"
                                    rows="6"
                                    placeholder="If no, explain"
                                />
                            </div>

                        </div>

                    </div>

                    <div className="row mb-3">

                        <div className="col-md-6 px-4 checkbox">

                            <div className="labels d-flex justify-content-between w-100">
                                <label>Beneficiary has signed consent to release information?</label>
                                <label>هل وافقت الحالة كتابيًا على الإفصاح بمعلوماته/ها؟</label>
                            </div>

                            {checkboxTemp(initialValues.beneficiary_signed)}

                        </div>

                        <div className="col-md-6 px-4 mt-3">
                            <div className="labels d-flex justify-content-between w-100">
                                <label>If no, explain</label>
                                <label>في حالة كانت الإجابة لا.. وضح لماذا</label>
                            </div>
                            <Field
                                className="w-100 input-style"
                                as="textarea"
                                name="beneficiary_signed_explain"
                                rows="6"
                                placeholder="If no, explain"
                            />
                        </div>

                    </div>

                    <div className="row">
                        <div className="col-md-6 px-4 checkbox">

                            <div className="labels d-flex justify-content-between w-100">
                                <label>Any contact or other restrictions?</label>
                                <label>هل هنالك أي قيود على الإتصال بالحالة أو غيرها</label>
                            </div>

                            {checkboxTemp(initialValues.beneficiary_restrictions)}
                        </div>

                        <div className="col-md-6 px-4">

                            <div className="my-3">
                                <div className="labels d-flex justify-content-between w-100">
                                    <label>If Yes, explain</label>
                                    <label>في حالة كانت الإجابة نعم.. وضح لماذا</label>
                                </div>
                                <Field
                                    className="w-100 input-style"
                                    as="textarea"
                                    name="beneficiary_restrictions_explain"
                                    rows="6"
                                    placeholder="If Yes, explain"
                                />

                            </div>

                        </div>


                    </div>

                </div>

                {/* 3rd row */}
                <div className="row">

                    <div className="labels d-flex justify-content-between w-100 my-4 row">
                        <div className="col-md-6 px-4">
                            <p>Beneficiary Information (All personal information is OPTIONAL depending on level of detail the beneficiary consents to disclose)</p>
                        </div>
                        <div className="col-md-6 px-4 text-end">
                            <p>معلومات الحالة  (إعطاء جميع المعلومات الشخصية هو أمر اختياري اعتمادًا على مستوى التفاصيل وموافقة الحالة على الكشف عليها)</p>
                        </div>
                    </div>

                    <div className="col-md-6 px-4">

                        <div className="mb-3">
                            <div className="labels d-flex justify-content-between w-100">
                                <label>Name</label>
                                <label>الاسم</label>
                            </div>

                            <input
                                className="w-100 input-style"
                                value={initialValues.beneficiary_name}
                            />
                        </div>
                        <div className="mb-3">
                            <div className="labels d-flex justify-content-between w-100">
                                <label>Address</label>
                                <label>العنوان</label>
                            </div>

                            <input
                                className="w-100 input-style"
                                value={initialValues.beneficiary_address}
                            />
                        </div>
                        <div className="mb-3 row">
                            <div className="col-6">
                                <div className="labels d-flex justify-content-between w-100">
                                    <label>Phone</label>
                                    <label>الهاتف</label>
                                </div>


                                <input
                                    className="w-100 input-style"
                                    value={initialValues.beneficiary_phone}
                                />
                            </div>

                            <div className="col-6">
                                <div className="labels d-flex justify-content-between w-100">
                                    <label>ID Number</label>
                                    <label>رقم الهوية</label>
                                </div>


                                <input
                                    className="w-100 input-style"
                                    value={initialValues.id_number}
                                />
                            </div>
                        </div>

                    </div>

                    <div className="col-md-6 px-4 row no-gutters">

                        <div className="col-12 input-group mt-4 mb-3 w-100 justify-content-around">
                            <div className="labels d-flex justify-content-between w-100 px-2">
                                <label>Beneficiary Code (if any)</label>
                                <label>رمز المستفيد (اذا وجد)</label>
                            </div>
                            <input className="input-style ben-code" value={initialValues.beneficiary_code} />
                            <button style={{ "lineHeight": "0" }} className="input-style btn btn-outline-secondary h3-responsive" type="button">{t("apply")}</button>
                        </div>

                        <div className="col-md-6 mb-3">
                            <div className="labels d-flex justify-content-between w-100">
                                <label>date of birth</label>
                                <label>تاريخ الميلاد</label>
                            </div>
                            <fieldset id="birthdate" className="row">

                                <div className="col">

                                    <input className={"input-style w-100"} value={initialValues.beneficiary_birthdate} />
                                </div>

                            </fieldset>
                        </div>

                        <div className="col-md-6 pl-2 mb-3">
                            <div className="labels d-flex justify-content-between w-100">
                                <label>gender</label>
                                <label>النوع</label>
                            </div>

                            <select
                                className="w-100 input-style"
                                style={{ marginTop: "1px" }}
                            >
                                <option value="" disabled selected>
                                    {initialValues.beneficiary_gender}
                                </option>
                            </select>

                        </div>

                        <div className="col-md-6 pr-2">
                            <div className="labels d-flex justify-content-between w-100">
                                <label>Language</label>
                                <label>اللغة</label>
                            </div>
                            <select
                                className="w-100 input-style"
                                style={{ marginTop: "1px" }}
                            >
                                <option value="" disabled selected>
                                    {initialValues.language}
                                </option>
                            </select>

                        </div>

                        <div className="col-md-6 pl-2 my-3">
                            <div className="labels d-flex justify-content-between w-100">
                                <label>Type of Migrant</label>
                                <label>نوع المهاجر</label>
                            </div>
                            <select
                                className="w-100 input-style"
                                style={{ marginTop: "1px" }}
                            >
                                <option value="" disabled selected>
                                    {initialValues.asylum_type.name}
                                </option>

                            </select>

                        </div>

                        <div className="labels d-flex justify-content-between w-100 px-2">
                            <label>Asylum seeker – Yellow card or White certificate holder</label>
                            <label>باحث لجوء - بطاقة صفراء او حامل شهادة بيضاء</label>
                        </div>
                        <div className="labels d-flex justify-content-between w-100 px-2">
                            <label>Registered refugee – Blue card holder</label>
                            <label>لاجئ مسجل - حامل بطاقة زرقاء</label>
                        </div>

                    </div>

                </div>

                {/* 4th row */}
                <div className="row mb-4">

                    <div className="labels d-flex justify-content-between w-100 my-4">
                        <div className="col-md-6 px-4">
                            <p>If Beneficiary Is a Minor</p>
                        </div>
                        <div className="col-md-6 px-4 text-end">
                            <p>إذا كانت الحالة قاصرًا</p>
                        </div>
                    </div>

                    <div className="col-md-6 px-4">
                        <div className="mb-3">
                            <div className="labels d-flex justify-content-between w-100">
                                <label>Name of primary caregiver</label>
                                <label>اسم الراعي</label>
                            </div>

                            <Field
                                className="w-100 input-style"
                                name="caregiver_name"
                                placeholder="Name of primary caregiver"
                            />
                        </div>
                        <div className="mb-3">
                            <div className="labels d-flex justify-content-between w-100">
                                <label>Relationship to child</label>
                                <label>علاقته بالطفل</label>
                            </div>

                            <Field
                                className="w-100 input-style"
                                name="caregiver_relation"
                                placeholder="Relationship to child"
                            />
                        </div>
                        <div className="mb-3">
                            <div className="labels d-flex justify-content-between w-100">
                                <label>Contact information for caregiver</label>
                                <label>معلومات الاتصال الخاصة بالراعي</label>
                            </div>

                            <Field
                                className="w-100 input-style"
                                name="caregiver_contact_info"
                                placeholder="Contact information for caregiver"
                            />
                        </div>
                    </div>

                    <div className="col-md-6 px-4">
                        <div className="checkbox">

                            <div className="labels d-flex justify-content-between w-100">
                                <label>Caregiver is informed of referral?</label>
                                <label>هل تم إعلام الراعي بالإحالة؟</label>
                            </div>

                            {checkboxTemp(initialValues.caregiver_informed)}
                        </div>
                        <div className="mt-4 pt-3">
                            <div className="labels d-flex justify-content-between w-100">
                                <label>If no, explain</label>
                                <label>في حالة كانت الإجابة لا.. وضح لماذا</label>
                            </div>
                            <Field
                                className="w-100 input-style mt-2"
                                as="textarea"
                                name="caregiver_informed_explain"
                                rows="4"
                                placeholder="If no, explain"
                            />
                        </div>

                    </div>

                </div>

                {/* 5th row */}
                <div className="row no-gutters px-2">
                    <div className="labels d-flex justify-content-between w-100">
                        <label>Background Information/Reason for Referral: (problem description, duration, frequency, etc.)</label>
                        <label>(معلومات أساسية / سبب الإحالة: (وصف المشكلة، والمدة، والتكرار، وغيرها</label>
                    </div>

                    <Field
                        className="w-100 input-style"
                        as="textarea"
                        name="description"
                        rows="6"
                        placeholder="Background Information/Reason for Referral: (problem description, duration, frequency, etc.)"
                    />
                </div>

            </Form>
        );
    }

    return (
        <div className="referralForm">
            <Toast position="top-center" ref={toast} />
            {
                isLoading
                &&
                <div className="text-center spinner-container">
                    <i className="fas fa-circle-notch fa-spin fa-4x"></i>
                </div>
            }
            <div className="min-H container-fluid my-5 SP-Dash px-5">

                <Formik enableReinitialize innerRef={formRef} initialValues={initialValues}>
                    {formBody}
                </Formik>

            </div>
        </div>
    );
}

export default ReferralFormView;
