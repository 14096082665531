//  TODO REFACTOR THE WHOLE BLOODY CODE
import React, { useState } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { FileUpload } from "primereact/fileupload";
import { useSelector, useDispatch } from "react-redux";
import { clearTemp, updateTags } from "../../../store/actions/temp";
import { TabView, TabPanel } from "primereact/tabview";
import { Toast } from 'primereact/toast';
import axiosInstance from "../../services/axiosInstance";
import { TimePickerComponent } from '@syncfusion/ej2-react-calendars';

function AdditionalInfo({ serviceProvider }) {

  const toast = React.useRef(null);
  const formRef = React.useRef(null);
  const localGovRef = React.useRef(null);

  // language
  const Lang = localStorage.getItem("i18nextLng");

  const [activeIndex, setActiveIndex] = React.useState(null);

  // make the actions on the state
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();

  // loading state
  const [isLoading, setIsLoading] = React.useState(true);

  // upload setting
  const noFile = null;
  const inputFile = React.useRef(null);
  const [fileName, setFileName] = React.useState(noFile);
  const btnClicked = () => {
    inputFile.current.click();
  };

  const [servicesList, setServicesList] = React.useState([
    {
      id: "",
      name: "",
    },
  ]);

  const [asylumList, setAsylumList] = useState([
    {
      id: "",
      name: "",
    },
  ]);

  const [registriesList, setRegistriesList] = useState([]);
  let wh = {};

  Object.keys(serviceProvider.working_hours).map((day) => {
    wh[day] = {
      'from': serviceProvider.working_hours[day].from ? new Date(`1/1/2022 ${serviceProvider.working_hours[day].from}`) : "",
      'to': serviceProvider.working_hours[day].to ? new Date(`1/1/2022 ${serviceProvider.working_hours[day].to}`) : "",
    }
  })

  let working_hours = {};
  Object.keys(serviceProvider.working_hours).map((day) => {
    working_hours[day] = {
      'from': serviceProvider.working_hours[day].from ? serviceProvider.working_hours[day].from : "",
      'to': serviceProvider.working_hours[day].to ? serviceProvider.working_hours[day].to : "",
    }
  })


  const [initialValues, setInitialValues] = useState({
    working_hours: working_hours,

    logo: "",
    services: serviceProvider.service_categories.map(item => item.id),
    local_government_registry: serviceProvider.local_government_registry,
    local_government_registry_id: serviceProvider.local_government_registry_ent ? serviceProvider.local_government_registry_ent.id : "",
    asylum_types: serviceProvider.asylum_types.map(item => item.id),
    local_government_registry_type: serviceProvider.local_government_registry_type,
    description: serviceProvider.description,
  });

  let list = useSelector((state) => {
    return state.TempReducer.length ? state.TempReducer : initialValues.services;
  })
  let listII = JSON.parse(localStorage.getItem("target")) || initialValues.asylum_types;

  // handleValidation
  function handleValidation() {

    const schema = Yup.object().shape({
      description: Yup.string().required(t("req")),
      services: Yup.array().min(1, t("req")),
      asylum_types: Yup.array().min(1, t("req")),
      local_government_registry: Yup.string().required(t("req")),
      local_government_registry_type: Yup
        .string()
        .nullable()
        .when("original_name", {
          is: "other",
          then: Yup.string().nullable().required(t("req"))
        }),

      local_government_registry_id: Yup.string().when("local_government_registry", {
        is: "yes",
        then: Yup.string().required(t("req")),
      }),

      working_hours: Yup.object().shape({
        sunday: Yup.object().shape({
          from: Yup.string()
            .when("to", {
              is: (value) => value && value.length > 0,
              then: Yup.string().required(t("req"))
            })
            .matches(/(^(([1-9])|(1[0-2])):([0-5])([0-9])\s(A|P)M)$/, t("wrongIn")),
          to: Yup.string().nullable(true)
            .when("from", {
              is: (value) => value && value.length > 0,
              then: Yup.string().required(t("req"))
            })
            .test("to", t("wrongIn"), function (str, sch) {
              return !sch.parent.from || !sch.parent.to || new Date(`12/12/12 ${sch.parent.from}`) < new Date(`12/12/12 ${sch.parent.to}`)
            })
            .matches(/(^(([1-9])|(1[0-2])):([0-5])([0-9])\s(A|P)M)$/, t("wrongIn"))
        }, ["from", "to"]),
        monday: Yup.object().shape({
          from: Yup.string()
            .when("to", {
              is: (value) => value && value.length > 0,
              then: Yup.string().required(t("req"))
            })
            .matches(/(^(([1-9])|(1[0-2])):([0-5])([0-9])\s(A|P)M)$/, t("wrongIn")),
          to: Yup.string().nullable(true)
            .when("from", {
              is: (value) => value && value.length > 0,
              then: Yup.string().required(t("req")),
            })
            .matches(/(^(([1-9])|(1[0-2])):([0-5])([0-9])\s(A|P)M)$/, t("wrongIn"))
            .test("to", t("wrongIn"), function (str, sch) {
              return !sch.parent.from || !sch.parent.to || new Date(`12/12/12 ${sch.parent.from}`) < new Date(`12/12/12 ${sch.parent.to}`)
            })
        }, ["from", "to"]),
        tuesday: Yup.object().shape({
          from: Yup.string()
            .when("to", {
              is: (value) => value && value.length > 0,
              then: Yup.string().required(t("req"))
            })
            .matches(/(^(([1-9])|(1[0-2])):([0-5])([0-9])\s(A|P)M)$/, t("wrongIn")),
          to: Yup.string().nullable()
            .when("from", {
              is: (value) => value && value.length > 0,
              then: Yup.string().required(t("req"))
            })
            .test("to", t("wrongIn"), function (str, sch) {
              return !sch.parent.from || !sch.parent.to || new Date(`12/12/12 ${sch.parent.from}`) < new Date(`12/12/12 ${sch.parent.to}`)
            })
            .matches(/(^(([1-9])|(1[0-2])):([0-5])([0-9])\s(A|P)M)$/, t("wrongIn"))
        }, ["from", "to"]),
        wednesday: Yup.object().shape({
          from: Yup.string()
            .when("to", {
              is: (value) => value && value.length > 0,
              then: Yup.string().required(t("req"))
            })
            .matches(/(^(([1-9])|(1[0-2])):([0-5])([0-9])\s(A|P)M)$/, t("wrongIn")),
          to: Yup.string()
            .when("from", {
              is: (value) => value && value.length > 0,
              then: Yup.string().required(t("req"))
            })
            .test("to", t("wrongIn"), function (str, sch) {
              return !sch.parent.from || !sch.parent.to || new Date(`12/12/12 ${sch.parent.from}`) < new Date(`12/12/12 ${sch.parent.to}`)
            })
            .matches(/(^(([1-9])|(1[0-2])):([0-5])([0-9])\s(A|P)M)$/, t("wrongIn"))
        }, ["from", "to"]),
        thursday: Yup.object().shape({
          from: Yup.string()
            .when("to", {
              is: (value) => value && value.length > 0,
              then: Yup.string().required(t("req"))
            })
            .matches(/(^(([1-9])|(1[0-2])):([0-5])([0-9])\s(A|P)M)$/, t("wrongIn"))
          ,
          to: Yup.string()
            .when("from", {
              is: (value) => value && value.length > 0,
              then: Yup.string().required(t("req"))
            })
            .test("to", t("wrongIn"), function (str, sch) {
              return !sch.parent.from || !sch.parent.to || new Date(`12/12/12 ${sch.parent.from}`) < new Date(`12/12/12 ${sch.parent.to}`)
            })
            .matches(/(^(([1-9])|(1[0-2])):([0-5])([0-9])\s(A|P)M)$/, t("wrongIn"))
          ,
        }, ["from", "to"]),
        friday: Yup.object().shape({
          from: Yup.string()
            .when("to", {
              is: (value) => value && value.length > 0,
              then: Yup.string().required(t("req"))
            })
            .matches(/(^(([1-9])|(1[0-2])):([0-5])([0-9])\s(A|P)M)$/, t("wrongIn")),
          to: Yup.string()
            .when("from", {
              is: (value) => value && value.length > 0,
              then: Yup.string().required(t("req"))
            })
            .test("to", t("wrongIn"), function (str, sch) {
              return !sch.parent.from || !sch.parent.to || new Date(`12/12/12 ${sch.parent.from}`) < new Date(`12/12/12 ${sch.parent.to}`)
            })
            .matches(/(^(([1-9])|(1[0-2])):([0-5])([0-9])\s(A|P)M)$/, t("wrongIn"))
        }, ["from", "to"]),
        saturday: Yup.object().shape({
          from: Yup.string()
            .when("to", {
              is: (value) => value && value.length > 0,
              then: Yup.string().required(t("req"))
            })
            .matches(/(^(([1-9])|(1[0-2])):([0-5])([0-9])\s(A|P)M)$/, t("wrongIn")),
          to: Yup.string()
            .when("from", {
              is: (value) => value && value.length > 0,
              then: Yup.string().required(t("req"))
            })
            .test("to", t("wrongIn"), function (str, sch) {
              return !sch.parent.from || !sch.parent.to || new Date(`12/12/12 ${sch.parent.from}`) < new Date(`12/12/12 ${sch.parent.to}`)
            })
            .matches(/(^(([1-9])|(1[0-2])):([0-5])([0-9])\s(A|P)M)$/, t("wrongIn")),
        }, ["from", "to"]),
      }, ['to'])

    }, [])
    return schema;
  }

  React.useEffect(() => {

    axiosInstance().get("/service-categories", { 'headers': { 'locale': Lang } })
      .then((response) => {
        setServicesList(response.data.data);
        document.querySelectorAll("select")[0].disabled = false;
      })

    axiosInstance().get(`/asylum-types`, { 'headers': { 'locale': Lang } })
      .then((response) => {
        setAsylumList(response.data.data);
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
      });

    if (initialValues.local_government_registry == 'yes') {
      setActiveIndex(0)
      localGovRef.current.disabled = false
      axiosInstance().get("/government-registries", { 'headers': { 'locale': Lang } })
        .then((response) => {

          let original = response.data.data.find(x => x.id === initialValues.local_government_registry_id).original_name;

          formRef.current.setFieldValue("original_name", original)


          setRegistriesList(response.data.data);
          localGovRef.current.value = serviceProvider.local_government_registry_ent.id
          setIsLoading(false)
        })
    }

    if (initialValues.local_government_registry == 'no') {
      setActiveIndex(1)
    }

    if (initialValues.local_government_registry == 'na') {
      setActiveIndex(2)
    }

    dispatch(clearTemp());
    localStorage.removeItem("target")


  }, [Lang]);

  function handleSubmit(values) {
    setIsLoading(true)

    if (formRef.current.values.original_name !== "other") {
      delete values["local_government_registry_type"]
    }

    // create formdata
    let formData = new FormData();
    for (const [key, val] of Object.entries(values)) {
      if (val !== null || val !== "") {
        if (key == "working_hours") {
          for (const [key2, val2] of Object.entries(val)) {
            for (const [key3, val3] of Object.entries(val2)) {
              formData.append(`working_hours[${key2}][${key3}]`, val3 || "");
            }
          }
        } else if (key == "asylum_types") {
          listII.map((v, i) => {
            formData.append(`asylum_types[${i}]`, v);
          });
        } else if (key == "services") {
          list.map((v, i) => {
            formData.append(`services[${i}]`, v);
          });
        } else {
          formData.append(key, val);
        }
      }
    }

    axiosInstance().post("/service-provider/profile/third", formData)
      .then((response) => {
        setIsLoading(false)
        dispatch(clearTemp());
        toast.current.show({ severity: 'success', summary: t("success"), detail: t("successMsg"), life: 2000 });
      })
      .catch((err) => {
        setIsLoading(false)
        if (err.response !== null & err.response !== undefined) {
          err.response.data.errors
            ?
            toast.current.show({ severity: 'warn', summary: t("warn"), detail: Object.values(err.response.data.errors) })
            :
            toast.current.show({ severity: 'warn', summary: t("warn"), detail: t("tryLater") })
        } else {
          toast.current.show({ severity: 'warn', summary: t("warn"), detail: t("tryLater") })
        }
      });
  }

  function handleChange(e, form, name) {
    if (name == "services") {

      list = [...list, e.target.value];
      list = [...new Set(list)];
      dispatch(updateTags(list));
      form.setFieldValue(name, list)

    } else if (name == "asylum_types") {
      listII = [...listII, e.target.value];
      listII = [...new Set(listII)];
      localStorage.setItem("target", JSON.stringify(listII))
      form.setFieldValue(name, listII)

    } else if (name == "local_government_registry_id") {
      form.setFieldValue(name, e.target.value)
      form.setFieldValue("original_name", e.target.selectedOptions[0].getAttribute("data-myAttri"))

    } else if (name.startsWith("working")) {
      form.setFieldValue(name, e)
    } else {

      form.setFieldValue(name, e.target.value)

    }

  }

  const serviceCategoriesInput = React.useRef(null);
  const asylumTypesInput = React.useRef(null);

  function handleRemove(form, i, name) {

    if (name == "services") {

      list.splice(i, 1);
      dispatch(updateTags(list));
      initialValues.services = [...list]
      setInitialValues(initialValues)
      form.setFieldValue(name, [...list])

      if (list.length == 0) {
        serviceCategoriesInput.current.value = 0;
      }

    } else {

      listII.splice(i, 1);
      localStorage.setItem("target", JSON.stringify(listII))
      form.setFieldValue(name, [...listII])

      if (listII.length == 0) {
        asylumTypesInput.current.value = 0;
      }

    }





  }


  function handleTabChange(e, form) {
    setActiveIndex(e.index);
    localGovRef.current.disabled = true
    switch (e.index) {
      case 1:
        form.setFieldValue("local_government_registry", "no");
        break;

      case 2:
        form.setFieldValue("local_government_registry", "na");
        break;

      default:
        setIsLoading(true)
        localGovRef.current.disabled = false
        form.setFieldValue("local_government_registry", "yes");
        if (!registriesList.length) {
          axiosInstance().get("/government-registries", { 'headers': { 'locale': Lang } })
            .then((response) => {
              setRegistriesList(response.data.data);
              setIsLoading(false)
            })
        } else {
          setIsLoading(false)

        }
        break;
    }
  }

  function formBody({ handleSubmit, values }) {
    return (
      <form className="auth-form step_form final-step" onSubmit={handleSubmit}>

        <div className="row">

          <div className="col-lg-5 main-colI row">

            <div className="col-5">
              <p>{t("auth.register.step_3.col_1.workingHours")}</p>
            </div>

            <div className="col-7 row no-gutters">
              <div className="col-6">
                <p>{t("from")}</p>
              </div>
              <div className="col-6 px-3">
                <p>{t("to")}</p>
              </div>
            </div>

            <div className="day-row col-12">
              <div className="row">
                <div className="col-5">
                  <div className="input-style hour-col">
                    {t("auth.register.step_3.col_1.sunday")}
                  </div>
                </div>

                <div className="row from-to col-7">
                  <div className="col-6">
                    <Field name="working_hours.sunday.from">
                      {({ form }) => (

                        <TimePickerComponent value={wh.sunday.from} strictMode={true} step={15} change={(e) => handleChange(e.text, form, "working_hours.sunday.from")} />

                      )}
                    </Field>
                    <div className="mt-2 text-danger">
                      <ErrorMessage name="working_hours.sunday.from"></ErrorMessage>
                    </div>
                  </div>

                  <div className="col-6">
                    <Field name="working_hours.sunday.to">
                      {({ form }) => (

                        <TimePickerComponent value={wh.sunday.to} strictMode={true} step={15} change={(e) => handleChange(e.text, form, "working_hours.sunday.to")} />

                      )}
                    </Field>

                    <div className="mt-2 text-danger">
                      <ErrorMessage name="working_hours.sunday.to"></ErrorMessage>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="day-row col-12">
              <div className="row">
                <div className="col-5">
                  <div className="input-style hour-col">
                    {t("auth.register.step_3.col_1.monday")}
                  </div>
                </div>
                <div className="row from-to col-7">
                  <div className="col-6">
                    <Field name="working_hours.monday.from">
                      {({ form }) => (

                        <TimePickerComponent value={wh.monday.from} strictMode={true} step={15} change={(e) => handleChange(e.text, form, "working_hours.monday.from")} />

                      )}
                    </Field>
                    <div className="mt-2 text-danger">
                      <ErrorMessage name="working_hours.monday.from"></ErrorMessage>
                    </div>
                  </div>

                  <div className="col-6">
                    <Field name="working_hours.monday.to">
                      {({ form }) => (

                        <TimePickerComponent value={wh.monday.to} strictMode={true} step={15} change={(e) => handleChange(e.text, form, "working_hours.monday.to")} />

                      )}
                    </Field>
                    <div className="mt-2 text-danger">
                      <ErrorMessage name="working_hours.monday.to"></ErrorMessage>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="day-row col-12">
              <div className="row">
                <div className="col-5">
                  <div className="input-style hour-col">
                    {t("auth.register.step_3.col_1.tuesday")}
                  </div>
                </div>
                <div className="row from-to col-7">
                  <div className="col-6">
                    <Field name="working_hours.tuesday.from">
                      {({ form }) => (

                        <TimePickerComponent value={wh.tuesday.from} strictMode={true} step={15} change={(e) => handleChange(e.text, form, "working_hours.tuesday.from")} />

                      )}
                    </Field>
                    <div className="mt-2 text-danger">
                      <ErrorMessage name="working_hours.tuesday.from"></ErrorMessage>
                    </div>
                  </div>

                  <div className="col-6">
                    <Field name="working_hours.tuesday.to">
                      {({ form }) => (
                        <TimePickerComponent value={wh.tuesday.to} strictMode={true} step={15} change={(e) => handleChange(e.text, form, "working_hours.tuesday.to")} />

                      )}
                    </Field>
                    <div className="mt-2 text-danger">
                      <ErrorMessage name="working_hours.tuesday.to"></ErrorMessage>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="day-row col-12">
              <div className="row">
                <div className="col-5">
                  <div className="input-style hour-col">
                    {t("auth.register.step_3.col_1.wednesday")}
                  </div>
                </div>
                <div className="row from-to col-7">
                  <div className="col-6">
                    <Field name="working_hours.wednesday.from">
                      {({ form }) => (
                        <TimePickerComponent value={wh.wednesday.from} strictMode={true} step={15} change={(e) => handleChange(e.text, form, "working_hours.wednesday.from")} />

                      )}
                    </Field>
                    <div className="mt-2 text-danger">
                      <ErrorMessage name="working_hours.wednesday.from"></ErrorMessage>
                    </div>
                  </div>

                  <div className="col-6">
                    <Field name="working_hours.wednesday.to">
                      {({ form }) => (
                        <TimePickerComponent value={wh.wednesday.to} strictMode={true} step={15} change={(e) => handleChange(e.text, form, "working_hours.wednesday.to")} />
                      )}
                    </Field>
                    <div className="mt-2 text-danger">
                      <ErrorMessage name="working_hours.wednesday.to"></ErrorMessage>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="day-row col-12">
              <div className="row">
                <div className="col-5">
                  <div className="input-style hour-col">
                    {t("auth.register.step_3.col_1.thursday")}
                  </div>
                </div>
                <div className="row from-to col-7">
                  <div className="col-6">
                    <Field name="working_hours.thursday.from">
                      {({ form }) => (

                        <TimePickerComponent value={wh.thursday.from} strictMode={true} step={15} change={(e) => handleChange(e.text, form, "working_hours.thursday.from")} />

                      )}
                    </Field>
                    <div className="mt-2 text-danger">
                      <ErrorMessage name="working_hours.thursday.from"></ErrorMessage>
                    </div>
                  </div>

                  <div className="col-6">
                    <Field name="working_hours.thursday.to">
                      {({ form }) => (
                        <TimePickerComponent value={wh.thursday.to} strictMode={true} step={15} change={(e) => handleChange(e.text, form, "working_hours.thursday.to")} />
                      )}
                    </Field>
                    <div className="mt-2 text-danger">
                      <ErrorMessage name="working_hours.thursday.to"></ErrorMessage>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="day-row col-12">
              <div className="row">
                <div className="col-5">
                  <div className="input-style hour-col">
                    {t("auth.register.step_3.col_1.friday")}
                  </div>
                </div>
                <div className="row from-to col-7">
                  <div className="col-6">
                    <Field name="working_hours.friday.from">
                      {({ form }) => (
                        <TimePickerComponent name="working_hours.friday.from" value={wh.friday.from} strictMode={true} step={15} change={(e) => handleChange(e.text, form, "working_hours.friday.from")} />
                      )}
                    </Field>
                    <div className="mt-2 text-danger">
                      <ErrorMessage name="working_hours.friday.from"></ErrorMessage>
                    </div>
                  </div>

                  <div className="col-6">
                    <Field name="working_hours.friday.to">
                      {({ form }) => (
                        <TimePickerComponent value={wh.friday.to} strictMode={true} step={15} change={(e) => handleChange(e.text, form, "working_hours.friday.to")} />
                      )}
                    </Field>
                    <div className="mt-2 text-danger">
                      <ErrorMessage name="working_hours.friday.to"></ErrorMessage>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="day-row col-12">
              <div className="row">
                <div className="col-5">
                  <div className="input-style hour-col">
                    {t("auth.register.step_3.col_1.saturday")}
                  </div>
                </div>
                <div className="row from-to col-7">
                  <div className="col-6">
                    <Field name="working_hours.saturday.from">
                      {({ form }) => (
                        <TimePickerComponent value={wh.saturday.from} strictMode={true} step={15} change={(e) => handleChange(e.text, form, "working_hours.saturday.from")} />
                      )}
                    </Field>
                    <div className="mt-2 text-danger">
                      <ErrorMessage name="working_hours.saturday.from"></ErrorMessage>
                    </div>
                  </div>

                  <div className="col-6">
                    <Field name="working_hours.saturday.to">
                      {({ form }) => (
                        <TimePickerComponent value={wh.saturday.to} strictMode={true} step={15} change={(e) => handleChange(e.text, form, "working_hours.saturday.to")} />
                      )}
                    </Field>
                    <div className="mt-2 text-danger">
                      <ErrorMessage name="working_hours.saturday.to"></ErrorMessage>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div className="col-lg-7 mt-5 mt-lg-0 main-colII">

            <div className="row mb-4 no-gutters">

              <div
                className="col-md-6 id-photo PIE"
              >
                <label>{t("auth.register.step_1.logo")}</label>
                <Field onClick={btnClicked} className="field" name="logo">
                  {({ form }) => (
                    <>
                      <FileUpload
                        onSelect={(event) => {
                          let file = event.files[0];
                          let reader = new FileReader();
                          if (file && file.type.match('image.*')) {
                            reader.readAsDataURL(file);
                            setFileName(file.name);
                            form.setFieldValue("logo", file);
                          }
                        }}
                        ref={inputFile}
                        accept="image/*"
                      />
                      <input
                        type="file"
                        className="d-none"
                        ref={inputFile}
                        onChange={(event) => {
                          let file = event.target.files[0];
                          let reader = new FileReader();
                          if (file && file.type.match('image.*')) {
                            reader.readAsDataURL(file);
                            setFileName(file.name);
                            form.setFieldValue("logo", file);
                          }

                        }}
                      />
                    </>
                  )}
                </Field>


                <Field
                  onClick={btnClicked}
                  name="logo"
                  placeholder={fileName || t("upImg")}
                  value={fileName || ""}
                  icon="fas fa-file-upload"
                  className="w-100 input-style cursor-none upload"
                />
                <div className="mt-2 text-danger">
                  <ErrorMessage name="logo" />
                </div>
              </div>

              <div className="col-md-6 PIS">
                <label>{t("auth.register.step_3.col_2.org")}</label>
                <Field name="services">
                  {({ form }) => (
                    <>

                      <select
                        className="w-100 input-style"
                        style={{ marginTop: "1px" }}
                        name="services"
                        ref={serviceCategoriesInput}
                        onChange={(e) => handleChange(e, form, "services")}
                      >
                        <option value="" disabled selected>
                          {t("auth.register.step_3.col_2.org_place")}
                        </option>

                        {servicesList.map((service, i) => (
                          <option key={i} value={service.id}>{service.name}</option>
                        ))}
                      </select>

                      {
                        values.services.length > 0
                        &&
                        <ul className="mt-2 p-0">
                          {
                            values.services.map((item, i) => {
                              return (
                                servicesList.length && servicesList.map((service, i) => (
                                  service.id == parseInt(item)
                                  &&
                                  <li className="tag_style" key={i}><span className="cursor-pointer" onClick={() => handleRemove(form, i, "services")}>x</span> &nbsp;{service.name}</li>
                                ))
                              )
                            })
                          }
                        </ul>
                      }

                    </>
                  )}
                </Field>
                <div className="mt-2 text-danger">
                  <ErrorMessage name="services"></ErrorMessage>
                </div>
              </div>

            </div>

            <div className="row mb-4 no-gutters">
              <div className="col-md-6 PIE">
                <label>{t("auth.register.step_3.col_2.target")}</label>
                <Field
                  name="asylum_types"
                  placeholder={t("auth.register.step_3.col_2.target_place")}
                >
                  {({ form }) => (
                    <>
                      <select
                        className="w-100 input-style"
                        style={{ marginTop: "1px" }}
                        name="asylum_types"
                        ref={asylumTypesInput}
                        onChange={(e) => handleChange(e, form, "asylum_types")}
                      >
                        <option value="" disabled selected>
                          {t("auth.register.step_3.col_2.target_place")}
                        </option>

                        {asylumList.map((asylum, i) => (
                          <option key={i} value={asylum.id}>{asylum.name}</option>
                        ))}
                      </select>
                      {
                        values.asylum_types.length > 0
                        &&
                        <ul className="mt-2 p-0">
                          {
                            values.asylum_types.map((item, i) => {
                              return (
                                asylumList.length && asylumList.map((asylum, i) => (
                                  asylum.id == parseInt(item)
                                  &&
                                  <li className="tag_style" key={i}><span className="cursor-pointer" onClick={() => handleRemove(form, i, "asylum_types")}>x</span> &nbsp;{asylum.name}</li>
                                ))
                              )
                            })
                          }
                        </ul>
                      }
                    </>
                  )}

                </Field>
                <div className="mt-2 text-danger">
                  <ErrorMessage name="asylum_types"></ErrorMessage>
                </div>
              </div>

              <div className="col-md-6 PIS">
                <label>{t("auth.register.step_3.col_2.question")}</label>
                <Field name="local_government_registry">
                  {({ form }) => (
                    <TabView
                      className="local-gov"
                      activeIndex={activeIndex}
                      onTabChange={(e) => handleTabChange(e, form)}
                    >
                      <TabPanel header={t("yes")} />

                      <TabPanel header={t("no")} />

                      <TabPanel header={t("N/A")} />

                    </TabView>
                  )}
                </Field>
                <div className="mt-2 text-danger">
                  <ErrorMessage name="local_government_registry" />
                </div>
              </div>
            </div>

            <div className="row mb-4 no-gutters">
              <div className="col-md-6 PIE">
                <label>{t("auth.register.step_3.col_2.if_I")}</label>
                <Field name="local_government_registry">
                  {({ form }) => (
                    <select
                      disabled
                      ref={localGovRef}
                      className="w-100 input-style"
                      style={{ marginTop: "1px" }}
                      name="local_government_registry_id"
                      onChange={(e) =>
                        handleChange(e, form, "local_government_registry_id")
                      }
                    >
                      <option value="" disabled selected>
                        {t("auth.register.step_1.shortName_place")}
                      </option>

                      {registriesList.map((registry, i) => (
                        <option key={i} value={registry.id} data-myAttri={registry.original_name}>{registry.name}</option>
                      ))}
                    </select>
                  )}
                </Field>
                <div className="mt-2 text-danger">
                  <ErrorMessage name="local_government_registry_id" />
                </div>
              </div>
              <div className="col-md-6 PIS">
                <label>{t("auth.register.step_3.col_2.if_II")}</label>
                <Field
                  className="w-100 input-style"
                  name="local_government_registry_type"
                  placeholder={t("specify")}
                />
                <div className="mt-2 text-danger">
                  <ErrorMessage name="local_government_registry_type" />
                </div>
              </div>
            </div>

            <div className="row mb-4 no-gutters">
              <label>
                {t("auth.register.step_3.col_2.generalDescription")}
              </label>
              <Field
                className="w-100 input-style"
                as="textarea"
                name="description"
                placeholder={t("auth.register.step_3.col_2.generalDescription")}
              />
              <div className="mt-2 text-danger">
                <ErrorMessage name="description"></ErrorMessage>
              </div>
            </div>

          </div>

        </div>

        <div className="row justify-content-end">
          <button
            type="submit"
            className="next-button main-buttonII px-5 mx-5 text-capitalize d-inline-block mx-auto mx-sm-0"
          >
            {t("save")}
          </button>

        </div>

      </form>
    );
  }

  return (
    <div className="register mt-2">

      {
        isLoading
        &&
        <div className="text-center spinner-container">
          <i className="fas fa-circle-notch fa-spin fa-4x"></i>
        </div>
      }
      <Toast position="top-center" ref={toast} />

      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={handleValidation}>
        {formBody}
      </Formik>

    </div>
  );
}

export default AdditionalInfo;
