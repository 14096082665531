import React from "react";
import { useTranslation } from 'react-i18next';
import { Field, ErrorMessage } from "formik";
import { FileUpload } from "primereact/fileupload";
import { useDispatch } from "react-redux";
import { setIsLoading } from '../../../../../store/actions/Loading';
import axiosInstance from "../../../../services/axiosInstance";
import Map from "../../../../../components/Map";
import { updateTemp } from "../../../../../store/actions/temp";


function MainInfo({ formRef, type }) {

    const Lang = localStorage.getItem("i18nextLng");
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // upload setting
    const noFile = null;
    const inputFile = React.useRef(null);
    const [fileName, setFileName] = React.useState(noFile);
    const btnClicked = () => {
        inputFile.current.click();
    };

    const [governsList, setGovernsList] = React.useState([
        {
            id: "",
            name: "",
        },
    ]);

    const [districtsList, setDistrictsList] = React.useState([
        {
            id: "",
            name: "",
        },
    ]);

    React.useEffect(() => {
        setIsLoading(true)

        axiosInstance().get("/governorates", { 'headers': { 'locale': Lang } })
            .then((response) => {
                setGovernsList(response.data.data);
                setIsLoading(false);
                document.querySelectorAll("select")[2].disabled = false;
            })
            .catch((error) => {
                setIsLoading(false);
            });

    }, [Lang]);

    function handleChange(e, form, name) {

        switch (name) {
            case "locations[0].governorate_id":
                form.setFieldValue(name, e.target.value);
                document.querySelectorAll("select")[3].disabled = true;

                // reset deps
                form.setFieldValue("locations[0].district_id", "");
                document.querySelectorAll("select")[3].selectedIndex = 0;
                form.setFieldValue(name, e.target.value);
                axiosInstance().get(`/governorate/${e.target.value}/districts`, { 'headers': { 'locale': Lang } })
                    .then((response) => {
                        document.querySelectorAll("select")[3].disabled = false;
                        setDistrictsList(response.data.data);
                    })
                    .catch((error) => {
                        // setIsDisabled(false);
                    });
                break;

            default:
                form.setFieldValue(name, e.target.value);
                break;
        }
    }

    function handleAdd() {

        if (!("locations" in formRef.current.errors) && formRef.current.dirty) {
            let values = formRef.current.values;
            dispatch(updateTemp(values.locations));

            formRef.current.resetForm({
                values: {
                    ...values, locations: [{
                        governorate_id: "",
                        district_id: "",
                        street: "",
                        building: "",
                        floor: "",
                        lat: "",
                        long: ""
                    }]
                }
            })
            document.querySelectorAll("select")[2].selectedIndex = 0;
            document.querySelectorAll("select")[3].selectedIndex = 0;
        }

    }

    return (
        <>
            <div className="row">

                <div
                    className="col-md-4 id-photo"
                    style={{ paddingInlineEnd: "20px" }}
                >
                    <label>{t("photo")}</label>
                    <Field onClick={btnClicked} className="field" name="featured_image">
                        {({ form }) => (
                            <>
                                <FileUpload
                                    onSelect={(event) => {
                                        let file = event.files[0];
                                        let reader = new FileReader();
                                        if (file && file.type.match('image.*')) {
                                            let url = reader.readAsDataURL(file);
                                            setFileName(file.name);
                                            form.setFieldValue("featured_image", file);
                                        }
                                    }}
                                    ref={inputFile}
                                    accept="image/*"
                                />
                                <input
                                    type="file"
                                    className="d-none"
                                    ref={inputFile}
                                    onChange={(event) => {
                                        let file = event.currentTarget.files[0];
                                        let reader = new FileReader();

                                        if (file && file.type.match('image.*')) {
                                            let url = reader.readAsDataURL(file);
                                            setFileName(file.name);
                                            form.setFieldValue("featured_image", file);
                                        }

                                    }}
                                />
                            </>
                        )}
                    </Field>

                    <Field
                        onClick={btnClicked}
                        name="featured_image"
                        placeholder={fileName || t("upImg")}
                        value={fileName || ""}
                        icon="fas fa-file-upload"
                        className="w-100 input-style cursor-none upload"
                    />
                </div>

                <div className="col-md-4">
                    <label>{type == "service" ? t("TOS") : t("title")}</label>
                    <Field
                        className="w-100 input-style"
                        name="title"
                        placeholder={type == "service" ? t("TOS") : t("title")}
                    />
                    <div className="mt-2 text-danger">
                        <ErrorMessage name="title" />
                    </div>
                </div>

                <div className="col-md-4">
                    <label>{t("auth.register.step_1.phone")}</label>
                    <Field
                        className="w-100 input-style"
                        name="phone"
                        placeholder={t("auth.register.step_1.phone")}
                    />
                    <div className="mt-2 text-danger">
                        <ErrorMessage name="phone" />
                    </div>
                </div>

            </div>

            <div className="row">

                <div className="col-md-4">
                    <label>{t("auth.register.step_1.phone_op")}</label>
                    <Field
                        className="w-100 input-style"
                        name="addition_phone"
                        placeholder={t("auth.register.step_1.phone_op")}
                    />
                    <div className="mt-2 text-danger">
                        <ErrorMessage name="addition_phone" />
                    </div>
                </div>

                <div className="col-md-4">
                    <label>{t("auth.register.step_2.government")}</label>
                    <Field name="locations[0].governorate_id">
                        {({ form }) => (
                            <select
                                disabled
                                className="w-100 input-style"
                                style={{ marginTop: "1px" }}
                                name="locations[0].governorate_id"
                                onChange={(e) =>
                                    handleChange(e, form, "locations[0].governorate_id")
                                }
                            >
                                <option value="" disabled selected>
                                    {t("auth.register.step_2.government")}
                                </option>

                                {governsList.map((govern) => (
                                    <option
                                        value={govern.id}
                                        selected={
                                            govern.id == localStorage.getItem("selectedGovernorate")
                                        }
                                    >
                                        {govern.name}
                                    </option>
                                ))}
                            </select>
                        )}
                    </Field>
                    <div className="mt-2 text-danger">
                        <ErrorMessage name="locations[0].governorate_id" />
                    </div>
                </div>

                <div className="col-md-4">
                    <label>{t("auth.register.step_2.district")}</label>
                    <Field name="locations[0].district_id">
                        {({ form }) => (
                            <select
                                disabled
                                className="w-100 input-style"
                                style={{ marginTop: "1px" }}
                                name="locations[0].district_id"
                                onChange={(e) =>
                                    handleChange(e, form, "locations[0].district_id")
                                }
                            >
                                <option value="" disabled selected>
                                    {t("auth.register.step_2.district")}
                                </option>

                                {districtsList.map((govern) => (
                                    <option value={govern.id}>{govern.name}</option>
                                ))}
                            </select>
                        )}
                    </Field>
                    <div className="mt-2 text-danger">
                        <ErrorMessage name="locations[0].district_id" />
                    </div>
                </div>

            </div>

            <div className="row">

                <div className="col-md-4">
                    <label>{t("auth.register.step_2.street")}</label>
                    <Field
                        className="w-100 input-style"
                        name="locations[0].street"
                        placeholder={t("auth.register.step_2.street")}
                    />
                    <div className="mt-2 text-danger">
                        <ErrorMessage name="locations[0].street" />
                    </div>
                </div>

                <div className="col-md-4">
                    <label>{t("auth.register.step_2.building")}</label>
                    <Field
                        className="w-100 input-style"
                        name="locations[0].building"
                        placeholder={t("auth.register.step_2.building")}
                    />
                    <div className="mt-2 text-danger">
                        <ErrorMessage name="locations[0].building" />
                    </div>
                </div>

                <div className="col-md-4">
                    <label>{t("auth.register.step_2.floor")}</label>
                    <Field
                        className="w-100 input-style"
                        name="locations[0].floor"
                        placeholder={t("auth.register.step_2.floor")}
                    />
                </div>

            </div>

            <div className="row">

                <div className="col-md-4 pt-3">
                    <label>{t("add_services.location")}</label>
                    <Map name={["locations[0].lat", "locations[0].long"]} />
                    <div className="row justify-content-end mt-3">
                        <button type="button" onClick={handleAdd} className="reset_button px-4 main-color text-capitalize d-inline-block">{t("addAnother")}</button>
                    </div>
                    <div className="mt-2 text-danger">
                        <ErrorMessage name="locations[0].lat" />
                    </div>
                </div>

                <div className="col-md-8">
                    <label>
                        {t("auth.register.step_3.col_2.generalDescription")}
                    </label>
                    <Field
                        className="w-100 input-style"
                        as="textarea"
                        name="description"
                        placeholder={t("auth.register.step_3.col_2.generalDescription")}
                    />
                    <div className="mt-2 text-danger">
                        <ErrorMessage name="description" />
                    </div>
                </div>

            </div>
        </>
    )
}

export default MainInfo
