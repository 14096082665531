import { Field } from 'formik';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';


export default function UploadAudio(props) {
    const { label, name, meta, value, ...rest } = props
    const [Audio, setAudio] = useState(value)
    return (
        <div className="Record-box d-flex align-items-center">
            <div className='form-control image upload-photo-formik text-left'>
                <Field
                    className="field"
                    id={name}
                    name={name}
                    {...rest}>
                    {({ field, form }) => (
                        <>
                            <div className="row mt-5">
                                <div className="col-8">
                                    <div className="d-flex">
                                        <span className="title d-block text-capitalize"><Trans i18nKey={label} /></span>
                                        <span className="subtitle d-block"><Trans i18nKey="chooseFile" /></span>
                                    </div>
                                    {/* <button className="btn upload-img-btn mt-4" onClick={btnClicked}>Choose File</button> */}
                                    <div className="upload-btn">
                                        <button className="upload-audio"><Trans i18nKey="chooseFile" /></button>
                                        <input
                                            type="file"
                                            className=""
                                            onChange={(event) => {
                                                let file = event.currentTarget.files[0];
                                                form.setFieldValue(name, file);
                                                const objectURL = URL.createObjectURL(file)
                                                setAudio(objectURL)
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="col-4 mt-5">
                                    {Audio ? <audio controlsList="nodownload" src={Audio} controls></audio> : <h3><Trans i18nKey="noFile" /></h3>}
                                </div>
                            </div>
                        </>

                    )}
                </Field>
                <div className="filed-details mt-5">{meta}</div>
            </div>
        </div>
    )
}
