import React, { useEffect } from 'react';
import EventCard from "../../../../../components/Cards/EventCard";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import axiosInstance from '../../../../services/axiosInstance';
import Pager from '../../../../../components/Pager';

function Events() {

    const { t } = useTranslation();

    // loading state
    const [isLoading, setIsLoading] = React.useState(true);

    const ID = useSelector((state) => state.authReducer.service_provider_id);

    const [eventsList, setEventsList] = React.useState([]);

    const Lang = localStorage.getItem("i18nextLng");

    const [Total, setTotal] = React.useState(0);


    useEffect(() => {
        axiosInstance().get(`/event?serviceProviderId=${ID}`, { 'headers': { 'locale': Lang } })
            .then((res) => {
                setEventsList(res.data.data.collection);
                setTotal(res.data.data.pageInfo.total);
                setIsLoading(false)
            })
            .catch((error) => {
                setIsLoading(false)
            });
    }, []);


    const onBasicPageChange = (event) => {
        setIsLoading(true)

        axiosInstance().get(`/event?serviceProviderId=${ID}&page=${event.page + 1}&perPage=${event.rows}`, { 'headers': { 'locale': Lang } })
            .then(res => {
                setIsLoading(false)
                setEventsList(res.data.data.collection);
                setTotal(res.data.data.pageInfo.total);
            }).catch(err => {
                setIsLoading(false);
                console.log(err);
            }
            )
    }

    return (
        <>
            {
                isLoading
                &&
                <div className="text-center spinner-container">
                    <i className="fas fa-circle-notch fa-spin fa-4x"></i>
                </div>
            }
            <div className="py-4">
                <div className="cards_list service_cards row">

                    {
                        eventsList.length > 0
                            ?
                            eventsList.map((item, i) => {
                                return <div className="col-lg-3 col-md-4 col-sm-6 gallery-img-card mb-4">
                                    <EventCard
                                        event={item}
                                    />
                                </div>
                            })
                            :
                            <p className="lead mx-3 no-cont">{t("noContent")}</p>
                    }

                </div>
            </div>
            {eventsList.length > 0 && <Pager total={Total} onPageChange={onBasicPageChange} />}

        </>
    )
}

export default Events
