import React from 'react';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from './LanguageSwitcher';

function NavbarI() {

    const { t, i18n } = useTranslation();


    return (
        <div className="first-nav">
            <div className="container-fluid px-md-5">
                <div className="row">
                    <div className="colI col-9">
                        {/* <a className="d-none d-lg-inline" href="">{t("nav.a_1")}</a> */}
                        {/* <a className="FAQ" href="">{t("footer.a_3")}</a> */}
                        <a className="d-none d-md-inline" href="/services">{t("services")}</a>
                        <div className="social-links">
                            <a href="/"><i className="fab fa-facebook-f"></i></a>
                            <a href="/"><i className="fab fa-twitter"></i></a>
                            <a href="/"><i className="fab fa-linkedin-in"></i></a>
                            <a href="/"><i className="fab fa-youtube"></i></a>
                            <a href="/"><i className="fab fa-instagram"></i></a>
                            <a href="/"><i className="fab fa-flickr"></i></a>
                        </div>
                    </div>
                    <div className="colII col-3">
                        <LanguageSwitcher />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NavbarI
