import React, { useState } from "react";
import { Field, ErrorMessage } from "formik";
import { useTranslation } from 'react-i18next';
import axiosInstance from "../../containers/services/axiosInstance";

import Map from "../Map";

export default function Location(props) {

    const { arrayName, i, governsList, location, ...rest } = props

    const Lang = localStorage.getItem("i18nextLng");

    const { t } = useTranslation();

    const [districtsList, setDistrictsList] = React.useState([]);

    function handleChange(e, form, i, type, name) {
        switch (type) {
            case "governorate":
                axiosInstance().get(`/governorate/${e.target.value}/districts`, { 'headers': { 'locale': Lang } })
                    .then((response) => {
                        setDistrictsList(response.data.data);
                        document.querySelector(`[name='${arrayName}[${i}].district_id']`).selectedIndex = 0;
                    })
            default:
                form.setFieldValue(name, e.target.value);
        }
    }



    React.useEffect(() => {

        if (location.governorate) {

            axiosInstance().get(`/governorate/${location.governorate.id}/districts`, { 'headers': { 'locale': Lang } })
                .then((response) => {
                    setDistrictsList(response.data.data);
                })
        }

    }, [Lang]);

    return (
        <div className="container-fluid mb-5">

            <div className="col-12 row px-0">

                {/* GOVERNORATE DROPDOWN */}
                <div className="col-sm-5">
                    <label>{t("auth.register.step_2.government")}</label>
                    <Field name={`${arrayName}[${i}].governorate_id`}>
                        {({ form }) => (
                            <select
                                disabled={!governsList.length}
                                className="w-100 input-style"
                                style={{ marginTop: "1px" }}
                                name={`${arrayName}[${i}].governorate_id`}
                                onChange={(e) =>
                                    handleChange(e, form, i, "governorate", `${arrayName}[${i}].governorate_id`)
                                }
                            >
                                <option value="" disabled selected>
                                    {t("auth.register.step_2.government")}
                                </option>

                                {governsList.map((govern, i) => (
                                    <option
                                        key={i}
                                        value={govern.id}
                                        selected={location.governorate && govern.id == location.governorate.id}
                                    >
                                        {govern.name}
                                    </option>
                                ))}
                            </select>
                        )}
                    </Field>
                    <div className="mt-2 text-danger">
                        <ErrorMessage name={`${arrayName}[${i}].governorate_id`} />
                    </div>
                </div>

                {/* DISTRICT DROPDOWN */}
                <div className="col-sm-6">
                    <label>{t("auth.register.step_2.district")}</label>
                    <Field name={`${arrayName}[${i}].district_id`}>
                        {({ form }) => (
                            <select
                                id="dis_input"
                                disabled={!districtsList.length}
                                className="w-100 input-style"
                                style={{ marginTop: "1px" }}
                                name={`${arrayName}[${i}].district_id`}
                                onChange={(e) =>
                                    handleChange(e, form, i, "district_id", `${arrayName}[${i}].district_id`)
                                }
                            >
                                <option value="" disabled selected>
                                    {t("auth.register.step_2.district")}
                                </option>

                                {districtsList.map((item, i) => (
                                    <option key={i} value={item.id} selected={location.district && item.id == location.district.id}>{item.name}</option>
                                ))}
                            </select>
                        )}
                    </Field>
                    <div className="mt-2 text-danger">
                        <ErrorMessage name={`${arrayName}[${i}].district_id`} />
                    </div>
                </div>

                {/* DELETE BUTTON  */}
                <div className="col-sm-1 d-flex align-items-end justify-content-end pb-2">

                    {
                        i > 0 &&
                        <button className="btn btn-danger" type="button" onClick={() => props.onRemove(i)}>&times;</button>
                    }
                </div>

                {/* STREET */}
                <div className="col-sm-4">
                    <label>{t("auth.register.step_2.street")}</label>
                    <Field
                        className="w-100 input-style"
                        name={`${arrayName}[${i}][street]`}
                        placeholder={t("auth.register.step_2.street")}
                    />
                    <div className="mt-2 text-danger">
                        <ErrorMessage name={`${arrayName}[${i}].street`} />
                    </div>

                </div>

                {/* FLOOR */}
                <div className="col-sm-4">
                    <label>{t("auth.register.step_2.floor")}</label>
                    <Field
                        className="w-100 input-style"
                        name={`${arrayName}[${i}][floor]`}
                        placeholder={t("auth.register.step_2.floor")}
                    />
                    <div className="mt-2 text-danger">
                        <ErrorMessage name={`${arrayName}[${i}].floor`} />
                    </div>
                </div>

                {/* BUILDING */}
                <div className="col-sm-4">
                    <label>{t("auth.register.step_2.building")}</label>
                    <Field
                        className="w-100 input-style"
                        name={`${arrayName}[${i}][building]`}
                        placeholder={t("auth.register.step_2.building")}
                    />
                    <div className="mt-2 text-danger">
                        <ErrorMessage name={`${arrayName}[${i}].building`} />
                    </div>
                </div>

                {/* MAP */}
                <div className="col-12">
                    <label>{t("add_services.location")}</label>
                    <Map name={[`${arrayName}[${i}].lat`, `${arrayName}[${i}].long`]} lat={location.lat} long={location.long} />
                </div>
            </div>
        </div>
    )
}
