import React, { useState } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { updatePageIndex, updateRegData } from "../../../store/actions/auth";
import { updateTemp, clearTemp } from "../../../store/actions/temp";
import axios from "axios";
import Map from "../../Map";
import { Toast } from 'primereact/toast';

function Step_two() {

  // language
  const Lang = localStorage.getItem("i18nextLng");

  // make the actions on the state
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const toast = React.useRef(null);

  // loading state
  const [isLoading, setIsLoading] = React.useState(true);

  const TempReducer = useSelector((state) => state.TempReducer);

  let initialValues = {
    locations: [
      {
        governorate_id: "",
        district_id: "",
        street: "",
        building: "",
        floor: "",
        lat: "",
        long: "",
      },
    ],
  };

  const formRef = React.useRef();

  function handleAdd() {

    if (!("locations" in formRef.current.errors) && formRef.current.dirty) {
      let values = formRef.current.values;
      dispatch(updateTemp(values.locations));
      window.location.reload();
    }

  }

  const [governsList, setGovernsList] = React.useState([
    {
      id: "",
      name: "",
    },
  ]);

  const [districtsList, setDistrictsList] = useState([
    {
      id: "",
      name: "",
    },
  ]);

  function handleSubmit(values) {
    dispatch(updateRegData({ locations: [...TempReducer, ...values.locations] }));
    dispatch(clearTemp());
    dispatch(updatePageIndex(3));
    window.location.reload();
  }

  React.useEffect(() => {

    axios("/governorates", { 'headers': { 'locale': Lang } })
      .then((response) => {
        setGovernsList(response.data.data);
        setIsLoading(false)
        document.querySelectorAll("select")[0].disabled = false;
      })
      .catch((error) => {
        setIsLoading(false)
      })

  }, [Lang]);

  // handleValidation
  function handleValidation() {
    const schema = Yup.object().shape({

      locations: Yup.array()
        .of(
          Yup.object().shape({
            governorate_id: Yup.string().required(t("required")),
            district_id: Yup.string().required(t("required")),

            street: Yup.string().required(t("required")),
            building: Yup.string().required(t("required")),
            // lat: Yup.string().required(t("required")),
            // long: Yup.string().required(t("required")),
          })

        )

    })
    return schema;
  }

  function handleChange(e, form, name) {
    form.setFieldValue(name, e.target.value);

    switch (name) {
      case "locations[0].governorate_id":
        // setIsDisabled(true);
        document.querySelectorAll("select")[1].disabled = true;

        // reset deps
        form.setFieldValue("locations[0].district_id", "");
        document.querySelectorAll("select")[1].selectedIndex = 0;

        axios(`/governorate/${e.target.value}/districts`, { 'headers': { 'locale': Lang } })
          .then((response) => {
            document.querySelectorAll("select")[1].disabled = false;
            setDistrictsList(response.data.data);
          })
          .catch((error) => {
            // setIsDisabled(false);
          });
        break;

      case "locations[0].district_id":
        break;

      default:
        break;
    }
  }

  function formBody({ handleSubmit, errors }) {
    return (
      <form className="auth-form step_form" onSubmit={handleSubmit}>
        <div className="title">{t("auth.register.step_2.title")}</div>

        <div className="row mb-0 mb-md-4 no-gutters">
          <div className="col-sm-4 mb-md-0 mb-3 id-photo">
            <label>{t("auth.register.step_2.government")}</label>
            <Field required name="locations[0].governorate_id">
              {({ form }) => (
                <select
                  disabled
                  className="w-100 input-style"
                  style={{ marginTop: "1px" }}
                  name="locations[0].governorate_id"
                  onChange={(e) =>
                    handleChange(e, form, "locations[0].governorate_id")
                  }
                >
                  <option value="" disabled selected>
                    {t("auth.register.step_2.government")}
                  </option>

                  {governsList.map((govern) => (
                    <option
                      value={govern.id}
                      selected={
                        govern.id == localStorage.getItem("selectedGovernorate")
                      }
                    >
                      {govern.name}
                    </option>
                  ))}
                </select>
              )}
            </Field>
            <div className="mt-2 text-danger">
              <ErrorMessage name="locations[0].governorate_id" />
            </div>
          </div>

          <div className="col-sm-4 mb-md-0 mb-3 px-sm-3">
            <label>{t("auth.register.step_2.district")}</label>
            <Field required name="locations[0].district_id">
              {({ form }) => (
                <select
                  disabled
                  className="w-100 input-style"
                  style={{ marginTop: "1px" }}
                  name="locations[0].district_id"
                  onChange={(e) =>
                    handleChange(e, form, "locations[0].district_id")
                  }
                >
                  <option value="" disabled selected>
                    {t("auth.register.step_2.district")}
                  </option>

                  {districtsList.map((govern) => (
                    <option value={govern.id}>{govern.name}</option>
                  ))}
                </select>
              )}
            </Field>
            <div className="mt-2 text-danger">
              <ErrorMessage name="locations[0].district_id" />
            </div>
          </div>

          <div className="col-sm-4 mb-md-0 mb-3">
            <label>{t("auth.register.step_2.street")}</label>
            <Field required
              className="w-100 input-style"
              name="locations[0].street"
              placeholder={t("auth.register.step_2.street")}
            />

          </div>

        </div>

        <div className="row mb-0 mb-md-4 no-gutters">


          <div className="col-sm-4 mb-md-0 mb-3">
            <label>{t("auth.register.step_2.building")}</label>
            <Field required
              className="w-100 input-style"
              name="locations[0]building"
              placeholder={t("auth.register.step_2.building")}
            />

          </div>

          <div className="col-sm-4 mb-md-0 mb-3 px-sm-3">
            <label>{t("auth.register.step_2.floor")}</label>
            <Field
              className="w-100 input-style"
              name="locations[0]floor"
              placeholder={t("auth.register.step_2.floor")}
            />

          </div>
        </div>

        <div className="row mb-0 mb-md-4 no-gutters">
          <div className="col-12">
            <Map name={["locations[0].lat", "locations[0].long"]} />
            {/* {errors.locations && errors.locations[0].lat && <div className="error custom-error-msg w-100">{errors.locations[0].lat}</div>} */}
          </div>
        </div>


        <div className="row justify-content-end my-3">
          <button type="button" onClick={handleAdd} className="reset_button px-4 main-color text-capitalize d-inline-block m-0">{t("addAnother")}</button>
        </div>

        <div className="row form-controls">

          {
            isLoading
            &&
            <div className="p-2 pb-0">
              <i className="fas fa-circle-notch fa-spin fa-2x"></i>
            </div>
          }

          <a href="/login" className="alreadyReg d-flex align-items-center">
            {t("auth.register.already")}
          </a>
          <button
            type="submit"
            className="next-button main-buttonII px-5 text-capitalize d-inline-block"
          >
            {t("next")}
          </button>
        </div>

      </form>
    );
  }

  return (
    <div className="register">
      <Toast position="top-center" ref={toast} />

      <Formik enableReinitialize innerRef={formRef} initialValues={initialValues} onSubmit={handleSubmit} validationSchema={handleValidation}>
        {formBody}
      </Formik>
    </div>
  );
}

export default Step_two;
