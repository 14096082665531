import React from 'react';
import axios from 'axios';
import { useParams } from 'react-router';

import Pager from '../../components/Pager';
import axiosInstance from "../services/axiosInstance";
import ApplyServiceModal from '../../components/Modals/ApplyServiceModal';
import { useTranslation } from 'react-i18next';
import MediaView from '../../components/MediaView';
import { useSelector } from "react-redux";
import ServiceCard from '../../components/Cards/ServiceCard';
import VideoPlaceholder from '../../components/VideoPlaceholder';
import ServiceApplicantCard from '../../components/Cards/ServiceApplicantCard';

function Service() {

    const { id } = useParams();
    const localStorageState = JSON.parse(localStorage.getItem('state'))
    const authReducer = (localStorageState && localStorageState.authReducer) ? localStorageState.authReducer : null;
    const role = useSelector(state => state.authReducer.user.role !== "" ? state.authReducer.user.role : "guest");

    // loading state
    const [isLoading, setIsLoading] = React.useState(true);

    const { t } = useTranslation();

    const [applyModalIsOpen, setApplyModalIsOpen] = React.useState(false);
    const [serviceMediaIndex, setServiceMediaIndex] = React.useState(0);
    const [serviceImages, setServiceImages] = React.useState([]);
    const [serviceVideos, setServiceVideos] = React.useState([]);
    const [serviceMedia, setServiceMedia] = React.useState([]);

    const [applicants, setApplicants] = React.useState([]);
    const [servicesList, setServicesList] = React.useState([]);
    const [service, setService] = React.useState([]);
    const [galleryIsOpen, setGalleryIsOpen] = React.useState(false);

    const [totalApplicants, setTotalApplicants] = React.useState([]);
    const [perPage, setPerPage] = React.useState(10);

    const Lang = localStorage.getItem("i18nextLng");

    const serviceBtn = () => {
        if (localStorageState && authReducer) {
            switch (authReducer.user.role) {
                case 'migrant':
                    return <button type="button" className="btn btn-primary mt-5" onClick={() => setApplyModalIsOpen(true)}>{t("book")}</button>
                case 'service_provider':
                    if (service.service_provider && authReducer.service_provider_id == service.service_provider.id) {
                        return <a href={`/service-provider/dashboard/services/${id}/edit`}><button type="button" className="btn btn-primary mt-5">{t("editservice")}</button></a>
                    }
                case 'admin':
                    return;
                default:
                    return <button type="button" className="btn btn-primary mt-5" onClick={() => setApplyModalIsOpen(true)}>{t("book")}</button>
            }
        } else {
            return <button type="button" className="btn btn-primary mt-5" onClick={() => setApplyModalIsOpen(true)}>{t("book")}</button>
        }
    }

    function markApplicantDone(id) {
        let scopeApplicants = [...applicants]
        scopeApplicants = scopeApplicants.map((applicant) => {
            if (applicant.id == id) {
                applicant.status = 'done';
            }
            return applicant;
        })
        setApplicants(scopeApplicants)
    }

    const onBasicPageChange = (event) => {
        setIsLoading(true);
        setPerPage(event.rows)
        axiosInstance().get(`/service/${id}/applicants?page=${event.page + 1}&perPage=${event.rows}`, { 'headers': { 'locale': Lang } })
            .then((response) => {
                setTotalApplicants(response.data.data.pageInfo.total);
                setApplicants(response.data.data.collection);
                setIsLoading(false)

                // setPageInfo(resp.data.data.pageInfo)
            })
            .catch((err) => {
                // Handle Error Here
                setIsLoading(false);

            });
    }

    React.useEffect(() => {

        setIsLoading(true)

        axiosInstance().get(`/service/${id}`, { 'headers': { 'locale': Lang } })
            .then((response) => {
                setService(response.data.data)
                setServicesList(response.data.data.related_services);

                if (response.data.data.applicants) {
                    setApplicants(response.data.data.applicants.collection);
                    setTotalApplicants(response.data.data.applicants.pageInfo.total)
                }

                setServiceImages(response.data.data.images);
                setServiceVideos(response.data.data.videos);
                setServiceMedia({
                    ...response.data.data.images.map((img) => img.url).concat(...response.data.data.videos.map((video, i) =>
                        <iframe key={i} title="service-media" className="videoDi d-flex flex-center items-center" src={video.url}></iframe>
                    ))
                });
                setIsLoading(false)
            })
            .catch((error) => {
                setIsLoading(false)
            })

    }, [Lang]);

    function handleDownload() {

        if (role == "service_provider") {
            axios.defaults.headers.common['Authorization'] = `Bearer ${JSON.parse(localStorage.state).authReducer !== undefined && JSON.parse(localStorage.state).authReducer.access_token
                }`
            axios.defaults.headers.common['locale'] = Lang;
        }

        axios({
            url: `/service/${id}/applicants/download`,
            method: 'GET',
            responseType: 'blob',
        })
            .then(data => {
                const url = window.URL.createObjectURL(new Blob([data.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `report.xlsx`);
                document.body.appendChild(link);
                link.click();
            })
            .catch((error) => {
                console.log(error);
            })

    }

    return (
        <>
            {/* videos and images popup */}
            <MediaView handleChange={(index) => setServiceMediaIndex(index)} index={serviceMediaIndex} handleClose={(isOpen) => setGalleryIsOpen(isOpen)} media={serviceMedia} isOpen={galleryIsOpen}></MediaView>

            {/* Apply Modal */}
            {
                <ApplyServiceModal show={applyModalIsOpen} timeline={service.timeline || ""} disclaimer={service.disclaimer} onHide={() => { setApplyModalIsOpen(false) }}></ApplyServiceModal>
            }

            <div className="position-relative">
                {
                    isLoading
                    &&
                    <div className="text-center spinner-container">
                        <i className="fas fa-circle-notch fa-spin fa-4x"></i>
                    </div>
                }

                <div className="min-H container-fluid custom-container my-5 px-5">
                    <div></div>
                    <div className="row">
                        <div className="col-md-3">
                            {/* service image */}
                            <img alt="service-image" className="img-fluid serv_img w-100" src={service.featured_image || "/img/example.png"} />

                            <div className="details_date">

                                {
                                    service.from
                                    &&
                                    <span className="mt-2 mb-2">{service.from.split(' ')[0]} - {service.to.split(' ')[0]}</span>
                                }

                                {
                                    service.to
                                    &&
                                    <span className="mx-3">{service.from.split(' ')[1]} - {service.to.split(' ')[1]}</span>
                                }

                            </div>

                            {
                                serviceBtn()
                            }

                            {/* service provider */}
                            <div className="mt-4">
                                <a style={{ "color": "#000" }} href={`/service-provider/${service.service_provider && service.service_provider.id}`}>
                                    <img alt="provider-image" style={{ "width": "50px" }} src={service.service_provider && service.service_provider.logo || "/img/example.png"} /><span className="d-inline-block mx-2">{service.service_provider && service.service_provider.user.name}</span>
                                </a>
                            </div>

                        </div>
                        {/* service details */}
                        <div className="col-md-9">
                            <p className="section-title mt-5 mt-md-0">{service.title}</p>
                            {/* service categories */}

                            <div className="noMarginParent">
                                {service && service.service_categories &&
                                    service.service_categories.map((serviceCategory, i) =>
                                        <span className="badge badge-pill badge-primary service-category-small py-2 px-3 mx-2 my-2" key={i}>{serviceCategory.name}</span>
                                    )
                                }
                            </div>

                            <div className="mt-3 noMarginParent">
                                {service && service.asylum_types &&
                                    service.asylum_types.map((asylumType, i) =>
                                        <span className="badge badge-pill badge-primary service-category-small py-2 px-3 mx-2 my-2" key={i}>{asylumType.name}</span>
                                    )
                                }
                            </div>

                            {service
                                &&
                                service.other_service
                                &&
                                <>
                                    <label className="font-weight-bold d-block mt-4 mb-2">{t("otherS")}</label>
                                    <p>{service.other_service}</p>
                                </>
                            }
                            <label className="font-weight-bold d-block mt-4 mb-2">{t("desc")}</label>

                            <p className="description">{service.description}</p>

                            <label className="font-weight-bold d-block mt-4 mb-2">{t("contactT")}</label>
                            <p className="text-primary"><i className="map-marker"></i>
                                {
                                    service.phones && service.phones.length > 0
                                    &&
                                    service.phones.map((e, i) => (
                                        <React.Fragment key={i}>
                                            <>
                                                {e}
                                                <br />
                                            </>
                                        </React.Fragment>
                                    ))
                                }
                            </p> {service.hotline ? ' - ' + service.hotline : ''}

                            <label className="font-weight-bold d-block mt-4 mb-2">{t("locationT")}</label>

                            {
                                service.locations && service.locations.map((location, i) =>
                                    <p key={i} className="text-primary"><i className="pi pi-map-marker"></i> {`${location.building} ${location.street} ${location.floor || ""} - ${location.district.name} ${location.governorate.name}`}</p>
                                )
                            }

                            <label className="font-weight-bold d-block mt-4 mb-2">{t("add_services.timeline")}</label>
                            <p className="text-primary">{service.timeline}</p>

                            {/* media */}
                            <>
                                <p className="sub-title mt-5">{t("videos_photos")}</p>
                                <div className="row">
                                    {
                                        serviceImages && serviceImages.length > 0 && serviceImages.map((img, i) =>
                                            <div className="col-md-3 col-6 mb-3" key={i}>
                                                <img alt="service-image" className="img-fluid w-100 img-h" style={{ "cursor": "pointer" }} src={img.url} onClick={() => { setServiceMediaIndex(i); setGalleryIsOpen(true); }} />
                                            </div>

                                        )
                                    }
                                    {
                                        serviceVideos && serviceVideos.length > 0 && serviceVideos.map((video, i) =>
                                            <div className="col-md-3 col-6 mb-3" key={i} onClick={() => { setGalleryIsOpen(true); setServiceMediaIndex(i + serviceImages.length) }}>
                                                <VideoPlaceholder src={video.url}></VideoPlaceholder>
                                            </div>

                                        )
                                    }
                                </div>
                            </>
                        </div>
                    </div>


                    {
                        servicesList.length > 0 ?
                            <>
                                <p className="h3 my-4 text-capitalize sub-title" style={{ "color": "#393939" }}>{t("service_page.related")}</p>
                                <div className="cards_list service_cards mb-5 row">

                                    {

                                        servicesList.map((item, i) => {
                                            return <div className="col-lg-3 col-md-4 col-sm-6 gallery-img-card mb-4" key={i}>
                                                <ServiceCard
                                                    service={item}
                                                />
                                            </div>
                                        })
                                    }

                                </div>
                            </>
                            : ''
                    }

                    {
                        applicants && applicants.length ?
                            <>
                                <p className="h3 my-4 text-capitalize sub-title" style={{ "color": "#393939" }}>{t("service_page.applicants")}</p>
                                <div className="cards_list service_cards mb-5 row">

                                    {
                                        applicants.length ?
                                            applicants.map((item, i) => {
                                                return <div className="col-lg-3 col-md-4 col-sm-6 mb-4" key={i}>
                                                    <ServiceApplicantCard
                                                        item={item}
                                                        onDone={markApplicantDone}
                                                    ></ServiceApplicantCard>
                                                </div>
                                            })
                                            :
                                            t('noContent')
                                    }
                                </div>
                                <Pager total={totalApplicants} onPageChange={onBasicPageChange} className={`${applicants.length ? '' : 'd-none'}`} />
                            </>
                            : ''
                    }
                    {
                        applicants.length > 0 && service.service_provider && authReducer.service_provider_id == service.service_provider.id
                        &&
                        <button className='mt-4 bgMainColor border-0 p-2 text-capitalize d-inline-block' onClick={handleDownload}>
                            {t("download")}
                        </button>
                    }

                </div>
            </div>
        </>
    )
}

export default Service
