import React, { useState } from 'react'
import { Paginator } from 'primereact/paginator';

export default function Pager(props) {
    const [basicFirst, setBasicFirst] = useState(props.page || 0);
    const [basicRows, setBasicRows] = useState(10);
    const onBasicPageChange = (event) => {
        setBasicFirst(event.first);
        setBasicRows(event.rows);
        props.onPageChange(event) //{first: 10, rows: 10, page: 1, pageCount: 2}
        
    }
    return (
        <Paginator first={typeof props.basicFirst != 'undefined' ? props.basicFirst : basicFirst} rows={basicRows} totalRecords={props.total} rowsPerPageOptions={[10, 15, 20, 25, 30]} onPageChange={onBasicPageChange}></Paginator>
    )
}
