
import React, { useState, useEffect } from 'react';
import { Formik, Field, ErrorMessage, Form, FieldArray } from "formik";
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';
import { Toast } from 'primereact/toast';
import axiosInstance from '../../../services/axiosInstance';
import { NavLink } from 'react-router-dom';
import { useParams } from 'react-router';
import { FileUpload } from 'primereact/fileupload';
import FormikControl from '../../../../components/formCompoment/FormikControl';

function Resources_control({ isNew }) {

    const { t } = useTranslation();
    const { id } = useParams();
    const [initialValues, setInitialValues] = useState({

        featured_image: "/img/example.png",

        title: "",
        description: "",

        files: [],

    });

    // upload setting
    const noFile = null;
    const inputFile = React.useRef(null);
    const formRef = React.useRef(null);
    const [fileName, setFileName] = React.useState(noFile);
    const btnClicked = () => {
        inputFile.current.click();
    };

    const toast = React.useRef(null);

    // language
    const Lang = localStorage.getItem("i18nextLng");

    // loading state
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        if (!isNew) {

            axiosInstance()
                .get(`/resource/${id}`)
                .then((response) => {

                    if (!response.data.data.featured_image) {
                        response.data.data.featured_image = "/img/example.png"
                    }

                    setInitialValues(response.data.data);

                    setIsLoading(false)
                })
                .catch((err) => {
                    console.log(err);
                    setIsLoading(false)
                    // toast.current.show({ severity: 'warn', summary: t("warn"), detail: Object.values(err.response.data.errors && err.response.data.errors) })
                });

        } else {
            setIsLoading(false)
        }


    }, [Lang]);

    // langs options
    const [langsList, setLangsList] = useState([
        {
            id: "en",
            name: "English",
        },
        {
            id: "ar",
            name: "العربية",
        }
    ]);

    const [contentLang, setContentLang] = React.useState("en");

    function handleChange(e, name) {
        setContentLang(e.target.value)
    }

    function handleSubmit(values) {

        setIsLoading(true)

        let newVals = { ...values };

        for (const [key, val] of Object.entries(newVals)) {
            if (key == "files" && val == [] || !val) {
                delete newVals[key];
            }
            if (key == "links" && val == [] || !val) {
                delete newVals[key];
            }
            if (key == "featured_image" && typeof val == "string") {
                delete newVals[key];
            }
        }

        // create formdata
        let formData = new FormData();

        for (const [key, val] of Object.entries(newVals)) {
            if (key == "files") {
                val.map((item, i) => {
                    if (item.id) {
                        formData.append(`files[${i}][id]`, item.id);
                        formData.append(`files[${i}][title]`, item.title);
                        typeof item.file == "object"
                            &&
                            formData.append(`files[${i}][file]`, item.file);

                    } else {
                        item.file && formData.append(`files[${i}][file]`, item.file);
                        item.title && formData.append(`files[${i}][title]`, item.title);
                    }
                })
            } else if (key == "links") {
                val.map((item, i) => {
                    item
                        &&
                        formData.append(`links[${i}]`, item);
                })
            } else {
                formData.append(key, val);
            }
        }

        !isNew
            ?
            axiosInstance()
                .post(`/resource/${id}`, formData)
                .then((response) => {
                    setIsLoading(false)
                    toast.current.show({ severity: 'success', summary: t("success"), detail: t("successMsg"), life: 2000 });
                    setTimeout(() => {
                        window.location.href = `/admin/resources/${id}`
                    }, 1500);


                })
                .catch((err) => {
                    console.log(err);
                    setIsLoading(false)
                    toast.current.show({ severity: 'warn', summary: t("warn"), detail: Object.values(err.response.data.errors && err.response.data.errors) })
                })
            :
            axiosInstance()
                .post(`/resource`, formData, { 'headers': { 'locale': contentLang } })
                .then((response) => {
                    setIsLoading(false)
                    toast.current.show({ severity: 'success', summary: t("success"), detail: t("successMsg"), life: 2000 });
                    setTimeout(() => {
                        window.location.href = `/admin/resources/${response.data.data.id}`
                    }, 1500);


                })
                .catch((err) => {
                    console.log(err);
                    setIsLoading(false)
                    toast.current.show({ severity: 'warn', summary: t("warn"), detail: Object.values(err.response.data.errors && err.response.data.errors) })
                });
    }

    function FormBody({ handleSubmit, values, errors }) {

        return (

            <Form className="auth-form step_form" onSubmit={handleSubmit}>

                <div className="row justify-content-between no-gutters mb-5">

                    <h3 className="alreadyReg d-flex align-items-center">{isNew ? t("addR") : t("editR")}</h3>
                    <div className="buttons align-items-center d-flex">

                        {
                            isNew
                                ?
                                <div className="d-inline-block mx-4">
                                    <select
                                        className="w-100 input-style"
                                        style={{ marginTop: "1px" }}
                                        onChange={(e) =>
                                            handleChange(e, "language", "language")
                                        }
                                    >

                                        {langsList.map((type) => (
                                            <option value={type.id}>{type.name}</option>
                                        ))}
                                    </select>
                                </div>
                                :
                                ""
                        }


                        <button type="submit" className="submit-button my-auto text-capitalize d-inline-block">{t("submit")}</button>
                        {
                            !isNew
                                ?
                                <button type="button" className="submit-button my-auto mx-3 custom text-capitalize d-inline-block"><NavLink className="white" to={`/admin/resources/${id}`}>{t("cancel")}</NavLink></button>
                                :
                                <button type="button" className="submit-button my-auto mx-3 custom text-capitalize d-inline-block"><NavLink className="white" to={`/admin/resources`}>{t("cancel")}</NavLink></button>
                        }
                    </div>

                </div>

                <div className="row">
                    <div className="col-3 row" style={{ "alignContent": "baseline" }}>

                        <div className="col-12 p-2 id-photo" style={{ paddingInlineEnd: "20px" }}>

                            {/* FEATURED IMAGE */}
                            <img alt="featured-image" className="img-fluid mt-3 w-100 serv_img" style={{ "maxHeight": "300px" }} src={`${values.featured_image && typeof values.featured_image !== "string" ? URL.createObjectURL(values.featured_image) : values.featured_image}`} />

                            <Field onClick={btnClicked} className="field" name="featured_image">
                                {({ form }) => (
                                    <>
                                        <FileUpload
                                            onSelect={(event) => {
                                                let file = event.files[0];
                                                let reader = new FileReader();
                                                if (file && file.type.match('image.*')) {
                                                    let url = reader.readAsDataURL(file);
                                                    setFileName(file.name);
                                                    form.setFieldValue("featured_image", file);
                                                }
                                            }}
                                            ref={inputFile}
                                            accept="image/*"
                                            autocomplete={false}
                                        />
                                        <input
                                            type="file"
                                            className="d-none"
                                            ref={inputFile}
                                            onChange={(event) => {
                                                let file = event.currentTarget.files[0];
                                                let reader = new FileReader();

                                                if (file && file.type.match('image.*')) {
                                                    let url = reader.readAsDataURL(file);
                                                    setFileName(file.name);
                                                    form.setFieldValue("featured_image", file);
                                                }

                                            }}
                                        />
                                    </>
                                )}
                            </Field>

                            <Field
                                onClick={btnClicked}
                                name="featured_image"
                                placeholder={fileName || t("upImg")}
                                value={fileName || ""}
                                icon="fas fa-file-upload"
                                className="w-100 input-style cursor-none upload mb-3"
                            />
                        </div>

                        <FieldArray
                            name="files"
                            render={arrayHelpers => (
                                <div className="row px-2 no-gutters">

                                    <label className='w-100'>
                                        {t("admin.files")}
                                    </label>
                                    {values.files
                                        &&
                                        values.files.length > 0
                                        &&

                                        values.files.map((item, index) => (
                                            <div className="col-12 custom-in" key={index}>
                                                <FormikControl
                                                    control='document'
                                                    name={`files[${index}].file`}
                                                    className="page-title"
                                                    index={index}
                                                    exc={true}
                                                />
                                                <>
                                                    <div className="mt-2 text-danger">
                                                        <ErrorMessage name={`files[${index}].title`} />
                                                    </div>
                                                    <div className="mt-2 text-danger">
                                                        <ErrorMessage name={`files[${index}].file`} />
                                                    </div>
                                                </>
                                            </div>
                                        ))
                                    }

                                    <button className="submit-button d-inline-block mt-4" type="button" onClick={() => { arrayHelpers.push({ file: "", title: "" }) }}>{t("admin.addfile")}</button>
                                </div>
                            )}
                        />

                    </div>

                    <div className="col-9">
                        <div className="row">

                            <div className="col-12 mb-4">
                                <label>{t("title")}</label>
                                <Field
                                    className="w-100 input-style"
                                    name="title"
                                    placeholder={t("title")}
                                />
                                <div className="mt-2 text-danger">
                                    <ErrorMessage name="title" />
                                </div>
                            </div>

                            <div className="col-12 mb-3">
                                <label>
                                    {t("auth.register.step_3.col_2.generalDescription")}
                                </label>
                                <Field
                                    rows={11}
                                    required
                                    className="w-100 input-style"
                                    as="textarea"
                                    name="description"
                                    placeholder={t("auth.register.step_3.col_2.generalDescription")}
                                />
                                <div className="mt-2 text-danger">
                                    <ErrorMessage name="description" />
                                </div>
                            </div>

                            <div className="col-12">
                                <label className='mb-2 pb-1'>
                                    {t("admin.links")}
                                </label>
                                <FieldArray
                                    name="links"
                                    render={arrayHelpers => (
                                        <div className='custom-in'>
                                            {values.links && values.links.length > 0 &&
                                                values.links.map((friend, index) => (
                                                    <div key={index}>
                                                        <Field name={`links.${index}`} />
                                                        <button
                                                            className="btn mx-1 my-2 p-0"
                                                            type="button"
                                                            onClick={() => arrayHelpers.remove(index)}
                                                        >
                                                            <i className='fas fa-trash text-danger' />
                                                        </button>

                                                        <div className="mt-2 text-danger">
                                                            <ErrorMessage name={`links[${index}]`} />
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                            <button className="btn mainColor p-0" type="button" onClick={() => arrayHelpers.push('')}>
                                                {/* show this when user has removed all links from the list */}
                                                {'+ ' + t('admin.addlink')}
                                            </button>

                                        </div>
                                    )}
                                />
                            </div>

                        </div>

                    </div>
                </div>

            </Form >
        )
    }

    // handleValidation
    function handleValidation() {
        const schema = Yup.object().shape({

            title: Yup.string().required(t("req")),
            description: Yup.string().required(t("req")),

            files: Yup.array().of(
                Yup.object().shape({
                    title: Yup.string().required(t('req')),
                    file: Yup.string().test("file", t("req"), function (str) {
                        return str == undefined ? this.parent.url : true
                    })
                })
            ),


        })
        return schema;
    }

    return (
        <div className="position-relative">
            {
                isLoading
                &&
                <div className="text-center spinner-container">
                    <i className="fas fa-circle-notch fa-spin fa-4x"></i>
                </div>
            }

            <div className="min-H pt-5 SP-Dash px-4">

                < Toast position="top-center" ref={toast} />

                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    onSubmit={handleSubmit}
                    innerRef={formRef}
                    // validation schema
                    validationSchema={handleValidation}
                >

                    {FormBody}

                </Formik>

            </div>
        </div>
    )
}

export default Resources_control
