import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import axiosInstance from '../../services/axiosInstance';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import Pager from "../../../components/Pager"
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import { useSelector } from 'react-redux';

function Referrals() {

    const { t, i18n } = useTranslation();

    const toast = React.useRef(null);

    const Lang = localStorage.getItem("i18nextLng");

    // loading state
    const [isLoading, setIsLoading] = React.useState(true);

    const [receivedList, setReceivedList] = useState([]);
    const [sentList, setSentList] = useState([]);


    const [statusOptions, setStatusOptions] = useState(
        [
            { name: "referral.recieved", code: "recieved" },
            { name: "referral.pending", code: "pending" },
            { name: "referral.in_process", code: "in_process" },
            { name: "referral.completed", code: "completed" },
            { name: "referral.rejected", code: "rejected" }
        ]);

    // note popup
    const [displayBasic, setDisplayBasic] = useState(false);
    const [displayBasic2, setDisplayBasic2] = useState(false);

    const [tempID, setTempID] = useState("");

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,
        'displayBasic2': setDisplayBasic2
    }

    function onShow(data, type) {

        dialogFuncMap[type](true)

        setTempID(data.id);

        setTimeout(() => {
            if (document.getElementById("noteText")) {
                document.getElementById("noteText").value = data.note
            }
        }, 100);

    }

    const role = useSelector(state => state.authReducer.user.role !== "" ? state.authReducer.user.role : "guest");




    function handleDownloadRef(type) {

        if (role == "service_provider") {
            axios.defaults.headers.common['Authorization'] = `Bearer ${JSON.parse(localStorage.state).authReducer !== undefined && JSON.parse(localStorage.state).authReducer.access_token
                }`
            axios.defaults.headers.common['locale'] = Lang;
        }

        axios({
            url: `/referral/${type}/download`,
            method: 'GET',
            responseType: 'blob',
        }, { 'headers': { 'locale': Lang } })
            .then(data => {
                const url = window.URL.createObjectURL(new Blob([data.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `report.xlsx`);
                document.body.appendChild(link);
                link.click();
            })
            .catch((error) => {
                console.log(error);
            })

    }

    function onHide(type) {

        dialogFuncMap[type](false)

    }

    function handleSubmit(e) {
        e.preventDefault();

        let note = document.getElementById("noteText").value;

        if (note !== "") {
            axiosInstance().post(`/referral/${tempID}`, { note: note })
                .then((res) => {
                    toast.current.show({ severity: 'success', summary: t("success"), detail: t("successMsg"), life: 1500 });
                    receivedList.map((item) => {
                        if (item.id == tempID) {
                            item.note = note;
                        }
                    })
                    setReceivedList(receivedList);
                })
                .catch((err) => {
                    console.error(err);
                    toast.current.show({ severity: 'warn', summary: t("warn"), detail: t("tryLater") })
                })
        }

    }

    // pager code
    const [Total, setTotal] = React.useState(0);
    const [Total2, setTotal2] = React.useState(0);

    const onBasicPageChange = (event) => {
        axiosInstance().get(`/referral/recieved?page=${event.page + 1}&perPage=${event.rows}`, { 'headers': { 'locale': Lang } })
            .then(res => {
                setReceivedList(res.data.data.collection);
                setTotal(res.data.data.pageInfo.total);
            }).catch(err =>
                console.log(err)
            )
    }

    const onBasicPageChange2 = (event) => {
        axiosInstance().get(`/referral/sent?page=${event.page + 1}&perPage=${event.rows}`)
            .then(res => {
                setSentList(res.data.data.collection);
                setTotal2(res.data.data.pageInfo.total);
            }).catch(err =>
                console.log(err)
            )
    }

    useEffect(() => {

        axiosInstance().get("/referral/recieved", { 'headers': { 'locale': Lang } })
            .then((res) => {
                setReceivedList(res.data.data.collection);
                setTotal(res.data.data.pageInfo.total);
            })
            .catch((error) => {
                console.log(error);
            })

        axiosInstance().get("/referral/sent", { 'headers': { 'locale': Lang } })
            .then((res) => {
                setSentList(res.data.data.collection);
                setTotal2(res.data.data.pageInfo.total);
                setIsLoading(false)
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false)
            })

    }, [Lang]);

    // handle status change
    function handleChange(id, selected) {

        axiosInstance().post(`/referral/${id}`, { status: selected })
            .catch((err) => {
                console.error(err);
                toast.current.show({ severity: 'warn', summary: t("warn"), detail: t("tryLater") })
            })

    }

    // status custom template 
    function onIndexTemplateR(data, props) {

        return (
            <div className="status-col child-capital d-flex justify-content-between">
                <select
                    placeholder="sddddd"
                    className="w-100 input-style status-ref"
                    style={{ marginTop: "1px" }}
                    onChange={(e) =>
                        handleChange(data.id, e.target.value)
                    }
                >

                    {statusOptions.map((status) => (
                        <option
                            selected={data.status == status.code}
                            value={status.code}
                        >
                            {t(`${status.name}`)}
                        </option>
                    ))}

                </select>

                <button className="mx-2 reset-button main-color" onClick={() => onShow(data, "displayBasic")}>{t("referral.note")}</button>
            </div>
        )
    }

    function onIndexTemplateS(data, props) {

        return (
            <div className="status-col child-capital d-flex justify-content-between">
                <span>{t(`referral.${data.status}`)}</span>
                <button className="mx-2 reset-button main-color" onClick={() => onShow(data, "displayBasic2")}>{t("referral.note")}</button>
            </div>
        )
    }

    function BeneCodeTemp(data, props) {
        return (
            <a href={`referral/${data.id}`}>
                <span>{data.beneficiary_code}</span>
            </a>
        )
    }


    return (
        <div className="referrals">
            <Toast position="top-center" ref={toast} />
            {
                isLoading
                &&
                <div className="text-center spinner-container">
                    <i className="fas fa-circle-notch fa-spin fa-4x"></i>
                </div>
            }
            <div className="min-H container-fluid my-5 SP-Dash px-5">

                <div className="ref-head">
                    <h2 className="reset-bold mb-5 text-capitalize">{t("referral.title")}</h2>
                    <NavLink className="main-buttonII px-5 add-new" to="/referrals/new">{t("new")}</NavLink>
                </div>

                <div className="rec-table">
                    <h4 className="reset-bold mb-3 text-capitalize">{t("referral.rec-title")}</h4>
                    <div className="card">
                        <DataTable emptyMessage={t("referral.emptyMsg")} value={receivedList} responsiveLayout="scroll">

                            <Column field="date" header={t("referral.head_1")} />
                            <Column field="from_service_provider" header={t("referral.head_2")} />
                            <Column field="to_service_provider" header={t("referral.head_3")} />
                            <Column field="id" header={t("referral.head_4")} />
                            <Column className='beneCode' field="beneficiary_code" header={t("referral.head_5")} body={BeneCodeTemp} />

                            <Column field="status" header={t("referral.head_6")} body={onIndexTemplateR} />

                        </DataTable>

                        {
                            receivedList.length > 0
                            &&
                            <button className='mt-4 reset_button px-4 main-color text-capitalize d-inline-block download-btn' onClick={() => handleDownloadRef("recieved")}>
                                {t("download")}
                            </button>
                        }


                    </div>

                    {/* note modal */}
                    <Modal
                        className="p-4"
                        show={displayBasic}
                        onHide={() => onHide("displayBasic")}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <form className="note-form p-4">
                            <h4 className="mb-3">{t("referral.note")}</h4>
                            <textarea className="input-style" id="noteText" />
                            <button className="reset-button main-buttonII mx-auto" onClick={handleSubmit}>{t("send")}</button>
                        </form>
                    </Modal>

                    {receivedList.length > 0 && <Pager total={Total} onPageChange={onBasicPageChange} />}

                </div>

                <div className="sent-table">
                    <h4 className="reset-bold mb-3 text-capitalize">{t("referral.sent-title")}</h4>
                    <div className="card">
                        <DataTable emptyMessage={t("referral.emptyMsg")} value={sentList} responsiveLayout="scroll">

                            <Column field="date" header={t("referral.head_1")} />
                            <Column field="from_service_provider" header={t("referral.head_2")} />
                            <Column field="to_service_provider" header={t("referral.head_3")} />
                            <Column field="id" header={t("referral.head_4")} />
                            <Column className='beneCode' field="beneficiary_code" header={t("referral.head_5")} body={BeneCodeTemp} />

                            <Column field="status" header={t("referral.head_6")} body={onIndexTemplateS} />

                        </DataTable>
                        {
                            sentList.length > 0
                            &&
                            <button className='mt-4 reset_button px-4 main-color text-capitalize d-inline-block download-btn' onClick={() => handleDownloadRef("sent")}>
                                {t("download")}
                            </button>
                        }
                    </div>
                    <Modal
                        className="p-4"
                        show={displayBasic2}
                        onHide={() => onHide("displayBasic2")}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <form className="note-form p-4">
                            <h4 className="mb-3">{t("referral.note")}</h4>
                            <textarea disabled className="input-style" id="noteText" />
                        </form>
                    </Modal>

                    {sentList.length > 0 && <Pager total={Total2} onPageChange={onBasicPageChange2} />}

                </div>
            </div>
        </div>
    );
}

export default Referrals
