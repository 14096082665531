
import React, { useState, useEffect } from 'react';
import { Formik, Field, ErrorMessage, Form, FieldArray } from "formik";
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';
import { Toast } from 'primereact/toast';
import axiosInstance from '../../../services/axiosInstance';
import { NavLink } from 'react-router-dom';
import { useParams } from 'react-router';
import { FileUpload } from 'primereact/fileupload';
import FormikControl from '../../../../components/formCompoment/FormikControl';

function News_control({ isNew }) {

    const { t, i18n } = useTranslation();
    const { id } = useParams();
    const [initialValues, setInitialValues] = useState({

        featured_image: "/img/example.png",

        title: "",
        description: "",

        images: [],
        videos: []

    });

    // upload setting
    const noFile = null;
    const inputFile = React.useRef(null);
    const [fileName, setFileName] = React.useState(noFile);
    const btnClicked = () => {
        inputFile.current.click();
    };

    const toast = React.useRef(null);

    // language
    const Lang = localStorage.getItem("i18nextLng");

    // loading state
    const [isLoading, setIsLoading] = useState(true);

    // langs options
    const [langsList, setLangsList] = useState([
        {
            id: "en",
            name: "English",
        },
        {
            id: "ar",
            name: "العربية",
        }
    ]);

    useEffect(() => {

        if (!isNew) {

            axiosInstance()
                .get(`/news/${id}`)
                .then((response) => {
                    setInitialValues(response.data.data);
                    setIsLoading(false)
                })
                .catch((err) => {
                    console.log(err);
                    setIsLoading(false)
                    // toast.current.show({ severity: 'warn', summary: t("warn"), detail: Object.values(err.response.data.errors && err.response.data.errors) })
                });

        } else {
            setIsLoading(false)
        }


    }, [Lang]);


    const [contentLang, setContentLang] = React.useState("en");

    function handleChange(e, name) {
        setContentLang(e.target.value)
    }

    function handleSubmit(values) {

        setIsLoading(true)

        let newVals = { ...values };

        for (const [key, val] of Object.entries(newVals)) {
            if (key == "images" && val == []) {
                delete newVals[key];
            }
            if (key == "videos" && val == []) {
                delete newVals[key];
            }
            if (key == "links" && val == [] || !val) {
                delete newVals[key];
            }
            if (key == "featured_image" && typeof val == "string") {
                delete newVals[key];
            }
        }

        // create formdata
        let formData = new FormData();

        for (const [key, val] of Object.entries(newVals)) {
            if (key == "images") {
                val.map((item, i) => {
                    if (item.id) {
                        item.id && formData.append(`images[${i}][id]`, item.id);
                    } else {
                        formData.append(`images[${i}][image]`, item.image);
                    }
                })
            } else if (key == "videos") {
                val.map((item, i) => {

                    if (item.id) {
                        item.id && formData.append(`videos[${i}][id]`, item.id);
                    } else {
                        formData.append(`videos[${i}][video]`, item.video);
                    }
                })
            } else if (key == "links") {
                val.map((item, i) => {
                    item
                        &&
                        formData.append(`links[${i}]`, item);
                })
            } else {
                formData.append(key, val);
            }
        }

        !isNew
            ?
            axiosInstance()
                .post(`/news/${id}`, formData)
                .then((response) => {
                    setIsLoading(false)
                    toast.current.show({ severity: 'success', summary: t("success"), detail: t("successMsg"), life: 2000 });
                    setTimeout(() => {
                        window.location.href = `/admin/news/${id}`
                    }, 1500);

                })
                .catch((err) => {
                    console.log(err);
                    setIsLoading(false)
                    toast.current.show({ severity: 'warn', summary: t("warn"), detail: Object.values(err.response.data.errors && err.response.data.errors) })
                })
            :
            axiosInstance()
                .post(`/news`, formData, { 'headers': { 'locale': contentLang } })
                .then((response) => {
                    setIsLoading(false)
                    toast.current.show({ severity: 'success', summary: t("success"), detail: t("successMsg"), life: 2000 });
                    setTimeout(() => {
                        window.location.href = `/admin/news/${response.data.data.id}`
                    }, 1500);

                })
                .catch((err) => {
                    console.log(err);
                    setIsLoading(false)
                    toast.current.show({ severity: 'warn', summary: t("warn"), detail: Object.values(err.response.data.errors && err.response.data.errors) })
                });
    }

    function FormBody({ handleSubmit, values }) {

        return (

            <Form className="auth-form step_form" onSubmit={handleSubmit}>

                <div className="row justify-content-between no-gutters mb-5">

                    <h3 className="alreadyReg d-flex align-items-center">{isNew ? t("addN") : t("editN")}</h3>
                    <div className="buttons align-items-center d-flex">

                        {
                            isNew
                                ?
                                <div className="d-inline-block mx-4">
                                    <select
                                        className="w-100 input-style"
                                        style={{ marginTop: "1px" }}
                                        onChange={(e) =>
                                            handleChange(e, "language", "language")
                                        }
                                    >

                                        {langsList.map((type) => (
                                            <option value={type.id}>{type.name}</option>
                                        ))}
                                    </select>
                                </div>
                                :
                                ""
                        }

                        <button type="submit" className="submit-button my-auto text-capitalize d-inline-block">{t("submit")}</button>
                        {
                            !isNew
                                ?
                                <button type="button" className="submit-button my-auto mx-3 custom text-capitalize d-inline-block"><NavLink className="white" to={`/admin/news/${id}`}>{t("cancel")}</NavLink></button>
                                :
                                <button type="button" className="submit-button my-auto mx-3 custom text-capitalize d-inline-block"><NavLink className="white" to={`/admin/news`}>{t("cancel")}</NavLink></button>
                        }
                    </div>

                </div>

                <div className="row">
                    <div className="col-3 row" style={{ "alignContent": "baseline" }}>

                        <div className="col-12 p-2 id-photo" style={{ paddingInlineEnd: "20px" }}>

                            {/* FEATURED IMAGE */}
                            <img alt="featured-image" className="img-fluid mt-3 w-100 serv_img" style={{ "maxHeight": "300px" }} src={`${values.featured_image && typeof values.featured_image !== "string" ? URL.createObjectURL(values.featured_image) : values.featured_image}`} />

                            <Field onClick={btnClicked} className="field" name="featured_image">
                                {({ form }) => (
                                    <>
                                        <FileUpload
                                            onSelect={(event) => {
                                                let file = event.files[0];
                                                let reader = new FileReader();
                                                if (file && file.type.match('image.*')) {
                                                    let url = reader.readAsDataURL(file);
                                                    setFileName(file.name);
                                                    form.setFieldValue("featured_image", file);
                                                }
                                            }}
                                            ref={inputFile}
                                            accept="image/*"
                                            autocomplete={false}
                                        />
                                        <input
                                            type="file"
                                            className="d-none"
                                            ref={inputFile}
                                            onChange={(event) => {
                                                let file = event.currentTarget.files[0];
                                                let reader = new FileReader();

                                                if (file && file.type.match('image.*')) {
                                                    let url = reader.readAsDataURL(file);
                                                    setFileName(file.name);
                                                    form.setFieldValue("featured_image", file);
                                                }

                                            }}
                                        />
                                    </>
                                )}
                            </Field>

                            <Field
                                onClick={btnClicked}
                                name="featured_image"
                                placeholder={fileName || t("upImg")}
                                value={fileName || ""}
                                icon="fas fa-file-upload"
                                className="w-100 input-style cursor-none upload"
                            />

                            <div className="mt-2 text-danger">
                                <ErrorMessage name={"featured_image"} />
                            </div>
                        </div>

                        <div className="col-6 p-2">
                            <label className="form-label">{t("images")}</label>

                            <FormikControl
                                control='photo'
                                name={`images[${values.images.length}].image`}
                                value={"/img/example.png"}
                                isArray={true}
                                className="header-images"
                            />
                        </div>

                        <div className="col-6 p-2">
                            <label className="form-label">{t("videos")}</label>
                            <FormikControl
                                control='video'
                                name={`videos[${values.videos.length}].video`}
                                type="video"
                                className="upload-video"
                            />
                        </div>

                        {/* MEDIA PREVIEW */}

                        <div className="row pt-3 mt-2 media">

                            {values.images.length > 0

                                &&

                                values.images.map((item, index) => (
                                    <img alt="media-image" className="col-4 img-fluid mt-3" src={`${item.image ? URL.createObjectURL(item.image) : item.url}`} key={index} />
                                ))
                            }

                            {values.videos.length > 0

                                &&

                                values.videos.map((item, index) => (
                                    <video className="col-4 img-fluid mt-3" src={`${item.video ? URL.createObjectURL(item.video) : item.url}`} key={index} />
                                ))
                            }

                        </div>

                    </div>

                    <div className="col-9">
                        <div className="row">
                            <div className="col-12 mb-4">
                                <label>{t("title")}</label>
                                <Field
                                    className="w-100 input-style"
                                    name="title"
                                    placeholder={t("title")}
                                />
                                <div className="mt-2 text-danger">
                                    <ErrorMessage name="title" />
                                </div>
                            </div>

                            <div className="col-12">
                                <label>
                                    {t("auth.register.step_3.col_2.generalDescription")}
                                </label>
                                <Field
                                    rows={9}
                                    required
                                    className="w-100 input-style"
                                    as="textarea"
                                    name="description"
                                    placeholder={t("auth.register.step_3.col_2.generalDescription")}
                                />
                                <div className="mt-2 text-danger">
                                    <ErrorMessage name="description" />
                                </div>
                            </div>

                            <div className="col-12">
                                <label className='mb-2 pb-1'>
                                    {t("admin.links")}
                                </label>
                                <FieldArray
                                    name="links"
                                    render={arrayHelpers => (
                                        <div className='custom-in'>
                                            {values.links && values.links.length > 0 &&
                                                values.links.map((friend, index) => (
                                                    <div key={index}>
                                                        <Field name={`links.${index}`} />
                                                        <button
                                                            className="btn mx-1 my-2 p-0"
                                                            type="button"
                                                            onClick={() => arrayHelpers.remove(index)}
                                                        >
                                                            <i className='fas fa-trash text-danger' />
                                                        </button>

                                                        <div className="mt-2 text-danger">
                                                            <ErrorMessage name={`links[${index}]`} />
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                            <button className="btn mainColor p-0" type="button" onClick={() => arrayHelpers.push('')}>
                                                {/* show this when user has removed all links from the list */}
                                                {'+ ' + t('admin.addlink')}
                                            </button>

                                        </div>
                                    )}
                                />
                            </div>
                        </div>

                    </div>
                </div>

            </Form >
        )
    }

    // handleValidation
    function handleValidation() {
        const schema = Yup.object().shape({

            title: Yup.string().required(t("req")),
            description: Yup.string().required(t("req")),
            featured_image: Yup.string().test("file", t("req"), function (str) {
                return str == "/img/example.png" ? false : true
            })

        })
        return schema;
    }

    return (
        <div className="position-relative">
            {
                isLoading
                &&
                <div className="text-center spinner-container">
                    <i className="fas fa-circle-notch fa-spin fa-4x"></i>
                </div>
            }

            <div className="min-H pt-5 SP-Dash px-4">

                < Toast position="top-center" ref={toast} />

                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    onSubmit={handleSubmit}

                    // validation schema
                    validationSchema={handleValidation}
                >

                    {FormBody}

                </Formik>

            </div>
        </div>
    )
}

export default News_control
