import React from 'react';
import { useParams } from 'react-router';
import Pager from '../../components/Pager';
import axiosInstance from "../services/axiosInstance";
import ApplyEventModal from '../../components/Modals/ApplyEventModal';
import { useTranslation } from 'react-i18next';
import MediaView from '../../components/MediaView';
import EventCard from '../../components/Cards/EventCard';
import VideoPlaceholder from '../../components/VideoPlaceholder';
import EventApplicantCard from '../../components/Cards/EventApplicantCard';

function G_Event() {

    const { id } = useParams();
    const localStorageState = JSON.parse(localStorage.getItem('state'))
    const authReducer = (localStorageState && localStorageState.authReducer) ? localStorageState.authReducer : null;

    // loading state
    const [isLoading, setIsLoading] = React.useState(true);

    const { t } = useTranslation();

    const [applyModalIsOpen, setApplyModalIsOpen] = React.useState(false);
    const [eventMediaIndex, setEventMediaIndex] = React.useState(0);
    const [eventImages, setEventImages] = React.useState([]);
    const [eventVideos, setEventVideos] = React.useState([]);
    const [eventMedia, setEventMedia] = React.useState([]);

    const [applicants, setApplicants] = React.useState([]);
    const [eventsList, setEventsList] = React.useState([]);
    const [event, setEvent] = React.useState([]);
    const [galleryIsOpen, setGalleryIsOpen] = React.useState(false);

    const [totalApplicants, setTotalApplicants] = React.useState([]);
    const [perPage, setPerPage] = React.useState(10);

    const Lang = localStorage.getItem("i18nextLng");


    React.useEffect(() => {

        setIsLoading(true)

        axiosInstance().get(`/event/${id}`, { 'headers': { 'locale': Lang } })
            .then((response) => {
                setEvent(response.data.data);

                response.data.data.related_events
                    &&
                    (setEventsList(response.data.data.related_events))

                if (response.data.data.applicants) {
                    setApplicants(response.data.data.applicants.collection);
                    setTotalApplicants(response.data.data.applicants.pageInfo.total)
                }

                setEventImages(response.data.data.images);
                setEventVideos(response.data.data.videos);
                setEventMedia({
                    ...response.data.data.images.map(img => img.url).concat(...response.data.data.videos.map((video, i) =>
                        <iframe key={i} title="event_media" className="videoDi d-flex flex-center items-center" src={video.url}></iframe>
                    ))
                });
                setIsLoading(false)
            })
            .catch((error) => {
                setIsLoading(false)
            })

    }, [Lang])

    const eventBtn = () => {
        if (localStorageState && authReducer) {
            switch (authReducer.user.role) {
                case 'migrant':
                    return event.bookable ? <button type="button" className="btn btn-primary mt-5" onClick={() => setApplyModalIsOpen(true)}>{t("book")}</button> : ''
                case 'service_provider':
                    if (event.service_provider && authReducer.service_provider_id == event.service_provider.id) {
                        return <a href={`/service-provider/dashboard/events/${id}/edit`}><button type="button" className="btn btn-primary mt-4">{t("editevent")}</button></a>
                    }
                case 'admin':
                    return;
                default:
                    return event.bookable ? <button type="button" className="btn btn-primary mt-5" onClick={() => setApplyModalIsOpen(true)}>{t("book")}</button> : ''
            }
        } else {
            return event.bookable ? <button type="button" className="btn btn-primary mt-5" onClick={() => setApplyModalIsOpen(true)}>{t("book")}</button> : ''
        }
    }

    function markApplicantDone(id) {
        let scopeApplicants = [...applicants]
        scopeApplicants = scopeApplicants.map((applicant) => {
            if (applicant.id == id) {
                applicant.status = 'done';
            }
            return applicant;
        })
        setApplicants(scopeApplicants)
    }

    const onBasicPageChange = (event) => {
        setIsLoading(true);
        setPerPage(event.rows)
        axiosInstance().get(`/event/${id}/applicants?page=${event.page + 1}&perPage=${event.rows}`, { 'headers': { 'locale': Lang } })
            .then((response) => {
                setTotalApplicants(response.data.data.pageInfo.total);
                setApplicants(response.data.data.collection);
                setIsLoading(false)
            })
            .catch((err) => {
                // Handle Error Here
                setIsLoading(false);

            });
    }

    return (
        <>
            {/* videos and images popup */}
            <MediaView handleChange={(index) => setEventMediaIndex(index)} index={eventMediaIndex} handleClose={(isOpen) => setGalleryIsOpen(isOpen)} media={eventMedia} isOpen={galleryIsOpen}></MediaView>

            {/* Apply Modal */}
            {
                authReducer &&
                <ApplyEventModal show={applyModalIsOpen} disclaimer={event.disclaimer} onHide={() => { setApplyModalIsOpen(false) }}></ApplyEventModal>
            }

            <div className="position-relative">
                {
                    isLoading
                    &&
                    <div className="text-center spinner-container">
                        <i className="fas fa-circle-notch fa-spin fa-4x"></i>
                    </div>
                }

                <div className="min-H container-fluid custom-container my-5 px-5">
                    <div className="row">
                        <div className="col-md-3">

                            {/* event image */}
                            <img alt="event-image" className="img-fluid serv_img w-100" src={event.featured_image || "/img/example.png"} />

                            <div className="details_date">
                                {
                                    event.from
                                    &&
                                    <span className="mt-2 mb-2">{event.from.split(' ')[0]} - {event.to.split(' ')[0]}</span>
                                }

                                {
                                    event.to
                                    &&
                                    <span className="mx-3">{event.from.split(' ')[1]} - {event.to.split(' ')[1]}</span>
                                }

                            </div>

                            {
                                eventBtn()
                            }

                            {/* event provider */}
                            <div className="mt-4">
                                <a style={{ "color": "#000" }} href={`/service-provider/${event.service_provider && event.service_provider.id}`}>
                                    <img alt="provider-image" style={{ "width": "50px" }} src={event.service_provider && event.service_provider.logo || "/img/example.png"} /><span className="d-inline-block mx-2">{event.service_provider && event.service_provider.user.name}</span>
                                </a>
                            </div>

                        </div>
                        {/* event details */}
                        <div className="col-md-9">
                            <p className="section-title mt-4 mt-md-0">{event.title}</p>
                            {/* event categories */}

                            <div className="noMarginParent">
                                {event && event.service_categories &&
                                    event.service_categories.map((eventCategory, i) =>
                                        <span className="badge badge-pill badge-primary service-category-small py-2 px-3 mx-2 my-2" key={i}>{eventCategory.name}</span>
                                    )
                                }
                            </div>

                            <div className="mt-2 noMarginParent">
                                {event && event.asylum_types &&
                                    event.asylum_types.map((asylumType, i) =>
                                        <span className="badge badge-pill badge-primary service-category-small py-2 px-3 mx-2 my-2" key={i}>{asylumType.name}</span>
                                    )
                                }
                            </div>

                            {event
                                &&
                                event.other_event
                                &&
                                <>
                                    <label className="font-weight-bold d-block mt-4 mb-2">{t("otherS")}</label>
                                    <p>{event.other_event}</p>
                                </>
                            }
                            <label className="font-weight-bold d-block mt-4 mb-2">{t("desc")}</label>

                            <p className="description">{event.description}</p>

                            <label className="font-weight-bold d-block mt-4 mb-2">{t("contactT")}</label>
                            <p className="text-primary"><i className="map-marker"></i>

                                {
                                    event.phones && event.phones.length > 0
                                    &&
                                    event.phones.map((e, i) => (
                                        <React.Fragment key={i}>
                                            <>
                                                {e}
                                                <br />
                                            </>
                                        </React.Fragment>
                                    ))
                                }

                            </p> {event.hotline ? ' - ' + event.hotline : ''}

                            <label className="font-weight-bold d-block mt-4 mb-2">{t("locationT")}</label>

                            {
                                event.locations && event.locations.map((location, i) =>
                                    <p key={i} className="text-primary"><i className="pi pi-map-marker"></i> {`${location.building} ${location.street} ${location.floor || ""} - ${location.district.name} ${location.governorate.name}`}</p>
                                )
                            }


                            {/* media */}
                            <>
                                <p className="sub-title mt-5">{t("videos_photos")}</p>
                                <div className="row">
                                    {
                                        eventImages.length > 0 && eventImages.map((img, i) =>
                                            <div className="col-md-3 col-6 mb-3" key={i}>
                                                <img alt="event-image" className="img-fluid w-100 img-h" style={{ "cursor": "pointer" }} src={img.url} onClick={() => { setEventMediaIndex(i); setGalleryIsOpen(true); }} />
                                            </div>

                                        )
                                    }
                                    {
                                        eventVideos.length > 0 && eventVideos.map((video, i) =>
                                            <div className="col-md-3 col-6 mb-3" key={i} onClick={() => { setGalleryIsOpen(true); setEventMediaIndex(i + eventImages.length) }}>
                                                <VideoPlaceholder src={video.url}></VideoPlaceholder>
                                            </div>

                                        )
                                    }
                                </div>
                            </>
                        </div>
                    </div>


                    {
                        eventsList.length > 0 ?
                            <>
                                <p className="h3 my-4 text-capitalize sub-title" style={{ "color": "#393939" }}>{t("event_page.related")}</p>
                                <div className="cards_list service_cards mb-5 row">

                                    {

                                        eventsList.map((item, i) => {
                                            return <div className="col-lg-3 col-md-4 col-sm-6 gallery-img-card mb-4" key={i}>
                                                <EventCard
                                                    event={item}
                                                />
                                            </div>
                                        })
                                    }

                                </div>
                            </>
                            : ''
                    }


                    {
                        applicants && applicants.length
                            ?
                            <>
                                <p className="h3 my-4 text-capitalize sub-title" style={{ "color": "#393939" }}>{t("event_page.attendance")}</p>
                                <div className="cards_list service_cards mb-5 row">

                                    {

                                        applicants.length ?
                                            applicants.map((item, i) => {
                                                return <div className="col-lg-3 col-md-4 col-sm-6 mb-4" key={i}>
                                                    <EventApplicantCard
                                                        item={item}
                                                        onDone={markApplicantDone}
                                                    ></EventApplicantCard>
                                                </div>
                                            })
                                            :
                                            t('noContent')
                                    }
                                </div>
                                <Pager total={totalApplicants} onPageChange={onBasicPageChange} className={`${applicants.length ? '' : 'd-none'}`} />
                            </>
                            : ""

                    }

                </div >
            </div>
        </>
    )
}

export default G_Event
