import * as Yup from "yup";
import React, { useState } from "react";
import { useParams } from "react-router";
import { Toast } from 'primereact/toast';
import { useTranslation } from 'react-i18next';
import { FileUpload } from "primereact/fileupload";
import { Formik, Field, ErrorMessage } from "formik";
import { useDispatch } from "react-redux";
import axiosInstance from "../../../../../services/axiosInstance";
import Location from "../../../../../../components/formCompoment/Location";
import FormikControl from "../../../../../../components/formCompoment/FormikControl";
import { clearTemp } from "../../../../../../store/actions/temp";
import { DateTimePickerComponent } from "@syncfusion/ej2-react-calendars";
import moment from "moment";

function EditE() {

    const Lang = localStorage.getItem("i18nextLng");
    const { t } = useTranslation();
    const { id } = useParams();

    const formRef = React.useRef();

    const dispatch = useDispatch();
    const toast = React.useRef(null);
    const serviceCategoriesInput = React.useRef(null);
    const asylumTypesInput = React.useRef(null);

    // loading state
    const [isLoading, setIsLoading] = React.useState(true);

    const [error, setError] = React.useState(false);

    // upload setting
    const noFile = null;
    const inputFile = React.useRef(null);
    const [fileName, setFileName] = React.useState(noFile);
    const btnClicked = () => {
        inputFile.current.click();
    };

    const [initialValues, setInitialValues] = React.useState({
        featured_image: "",
        title: "",
        phone: "",
        addition_phone: "",
        disclaimer: "",
        locations: [],
        service_categories: [],
        description: "",
        asylum_types: [],
        from: "",
        to: "",
        bookable: 0,
        lat: 0,
        long: 0,

        images: [
        ],

        videos: [
        ],
    });

    const [governsList, setGovernsList] = React.useState([
        {
            id: "",
            name: "",
        },
    ]);


    const [eventsList, setServicesList] = React.useState([
        {
            id: "",
            name: "",
        },
    ]);


    const [asylumList, setAsylumList] = useState([
        {
            id: "",
            name: "",
        },
    ]);

    let list = JSON.parse(localStorage.getItem("event_target")) || [];
    let listII = JSON.parse(localStorage.getItem("event_services")) || [];

    // handleValidation
    function handleValidation() {
        const schema = Yup.object().shape({

            title: Yup.string().required(t("req")),
            phone: Yup.string().matches(/^[0-9]+$/, t("mustNum")).min(11, t("minNum")).max(11, t("minNum")).required(t("req")),
            addition_phone: Yup.string().matches(/^[0-9]+$/, t("mustNum")).min(11, t("minNum")).max(11, t("minNum")),
            service_categories: Yup.array().min(1, t("req")),
            description: Yup.string().required(t("req")),
            asylum_types: Yup.array().min(1, t("req")),

            locations: Yup.array()
                .of(
                    Yup.object().shape({
                        governorate_id: Yup.string().required(t("required")),
                        district_id: Yup.string().required(t("required")),
                        street: Yup.string().required(t("required")),
                        building: Yup.string().required(t("required")),
                        // lat: Yup.string().required(t("required")),
                        // long: Yup.string().required(t("required")),
                    })

                )

        })
        return schema;
    }

    function handleChange(e, form, name) {

        switch (name) {
            case "service_categories":
                listII = [...listII, e.target.value];
                listII = [...new Set(listII)];
                localStorage.setItem("event_services", JSON.stringify(listII))
                formRef.current.setFieldValue(name, listII)
                break;

            case "asylum_types":
                list = [...list, e.target.value];
                list = [...new Set(list)];
                localStorage.setItem("event_target", JSON.stringify(list))
                formRef.current.setFieldValue(name, list)
                break;

            case "from":
            case "to":
                form.setFieldValue(name, `${moment(e.value).format('L')} ${moment(e.value).format('LT')}`)
                break;

            default:
                form.setFieldValue(name, e.target.value);
                break;
        }
    }

    React.useEffect(() => {

        axiosInstance().get("/governorates", { 'headers': { 'locale': Lang } })
            .then((response) => {
                setGovernsList(response.data.data);
                document.querySelectorAll("select")[1].disabled = false;
            })
            .catch((error) => {
                console.error(error);
            });

        axiosInstance().get("/attached-service-categories", { 'headers': { 'locale': Lang } })
            .then((response) => {
                setServicesList(response.data.data);
                serviceCategoriesInput.disabled = false;
            })
            .catch((error) => {
            });

        axiosInstance().get(`/attached-asylum-types`, { 'headers': { 'locale': Lang } })
            .then((response) => {
                setAsylumList(response.data.data);
                asylumTypesInput.disabled = false;
            })
            .catch((error) => {
                console.error(error);
            });

        axiosInstance().get(`/event/${id}`, { 'headers': { 'locale': Lang } })
            .then((response) => {

                let event = response.data.data;

                //  load service categories
                let IDS = [];

                event.service_categories.map(item => {
                    IDS.push(`${item.id}`)
                })

                localStorage.setItem("event_services", JSON.stringify(IDS));


                //  load asylum target
                IDS = [];

                event.asylum_types.map(item => {
                    IDS.push(`${item.id}`)
                })

                localStorage.setItem("event_target", JSON.stringify(IDS));




                let serviceCategories = event.service_categories.map((item) => item.id);
                serviceCategories = [...new Set(serviceCategories)]

                let asylumTypes = event.asylum_types.map((item) => item.id);
                asylumTypes = [...new Set(asylumTypes)];





                setInitialValues({
                    featured_image_url: event.featured_image || "/img/example.png",
                    title: event.title,
                    phone: event.phones[0],
                    addition_phone: event.phones[1],
                    from: event.from,
                    to: event.to,
                    bookable: event.bookable,
                    locations: event.locations.map((location) => {
                        return {
                            id: location.id,
                            street: location.street,
                            building: location.building,
                            floor: location.floor,
                            governorate_id: location.governorate.id,
                            district_id: location.district.id,
                            governorate: location.governorate,
                            district: location.district,
                            lat: location.lat,
                            long: location.long,
                        }
                    }),
                    service_categories: serviceCategories,
                    description: event.description,
                    asylum_types: asylumTypes,
                    images: event.images,
                    videos: event.videos,
                });

                document.getElementById("CheckID").checked = event.bookable

                event.locations.map((loc, i) => {
                    document.querySelectorAll("select#dis_input")[i].value = loc.district.id
                })

                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                console.error(error);
            });

        dispatch(clearTemp());

    }, [Lang]);


    function handleRemove(form, i, name) {

        if (name == "asylum_types") {

            list.splice(i, 1);
            localStorage.setItem("event_target", JSON.stringify(list))
            form.setFieldValue(name, [...list]);

            if (list.length == 0) {
                asylumTypesInput.current.value = 0;
            }

        } else {

            listII.splice(i, 1);
            localStorage.setItem("event_services", JSON.stringify(listII))
            form.setFieldValue(name, [...listII])

            if (listII.length == 0) {
                serviceCategoriesInput.current.value = 0;
            }
        }

    }


    function removeLocation(i) {
        let values = { ...initialValues };
        values.locations.splice(i, 1);

        setInitialValues(values)
    }

    function addLocation() {
        if (!("locations" in formRef.current.errors)) {
            let values = { ...initialValues };
            values.locations.push({});

            setInitialValues(values)
            formRef.current.setFieldTouched('locations', true)
        }
    }

    function formBody({ handleSubmit, values }) {
        return (
            <form onSubmit={handleSubmit}>

                <div className="row no-gutters">
                    <h3 className="addition-title">{t(`editevent`)}</h3>

                    <div className="upper-control">
                        {/* SAVE BUTTON */}
                        <button
                            type="submit"
                            className="addition-submit next-button py-3 main-buttonII px-5 text-capitalize d-inline-block"
                        >
                            {t("save")}
                        </button>

                        {/* CANCEL BUTTON */}
                        <a href={`/events/event/${id}`}>
                            <button
                                type="button"
                                className="next-button py-3 mx-1 main-buttonII px-5 text-capitalize d-inline-block"
                            >
                                {t("cancel")}
                            </button>
                        </a>
                    </div>

                    <div className="row mt-4">
                        <div className="col-md-4 row mx-auto" style={{ "alignContent": "baseline" }}>

                            <div className="col-12 id-photo mb-4">

                                {/* FEATURED IMAGE */}
                                <img alt="featured-image" className="img-fluid mt-3 w-100 serv_img" style={{ "maxHeight": "300px" }} src={`${values.featured_image ? URL.createObjectURL(values.featured_image) : values.featured_image_url}`} />

                                <Field onClick={btnClicked} className="field" name="featured_image">
                                    {({ form }) => (
                                        <>
                                            <FileUpload
                                                onSelect={(event) => {
                                                    let file = event.files[0];
                                                    let reader = new FileReader();
                                                    if (file && file.type.match('image.*')) {
                                                        let url = reader.readAsDataURL(file);
                                                        setFileName(file.name);
                                                        form.setFieldValue("featured_image", file);
                                                    }
                                                }}
                                                ref={inputFile}
                                                accept="image/*"
                                                autocomplete={false}
                                            />
                                            <input
                                                type="file"
                                                className="d-none"
                                                ref={inputFile}
                                                onChange={(event) => {
                                                    let file = event.currentTarget.files[0];
                                                    let reader = new FileReader();

                                                    if (file && file.type.match('image.*')) {
                                                        let url = reader.readAsDataURL(file);
                                                        setFileName(file.name);
                                                        form.setFieldValue("featured_image", file);
                                                    }

                                                }}
                                            />
                                        </>
                                    )}
                                </Field>

                                <Field
                                    onClick={btnClicked}
                                    name="featured_image"
                                    placeholder={fileName || t("upImg")}
                                    value={fileName || ""}
                                    icon="fas fa-file-upload"
                                    className="w-100 input-style cursor-none upload"
                                />
                            </div>

                            <div className="col-6">
                                <label className="form-label">{t("images")}</label>

                                <FormikControl
                                    control='photo'
                                    name={`images[${values.images.length}].image`}
                                    value={"/img/example.png"}
                                    isArray={true}
                                    className="header-images"
                                />
                            </div>

                            <div className="col-6">
                                <label className="form-label">{t("videos")}</label>
                                <FormikControl
                                    control='video'
                                    name={`videos[${values.videos.length}].video`}
                                    type="video"
                                    className="upload-video"
                                />
                            </div>

                            {/* MEDIA PREVIEW */}
                            <div className="row pt-3 mt-2">

                                {values.images.length > 0

                                    &&

                                    values.images.map((item, index) => (
                                        <img alt="media-image" className="col-4 img-fluid mt-3" src={`${item.image ? URL.createObjectURL(item.image) : item.url}`} key={index} />
                                    ))
                                }

                                {values.videos.length > 0

                                    &&

                                    values.videos.map((item, index) => (
                                        <video className="col-4 img-fluid mt-3" src={`${item.video ? URL.createObjectURL(item.video) : item.url}`} key={index} />
                                    ))
                                }

                            </div>

                        </div>

                        <div className="col-md-8 mt-5 mt-md-0">
                            <div className="row px-2">
                                <div className="col-md-4">
                                    <label>{t("title")}</label>
                                    <Field
                                        className="w-100 input-style"
                                        name="title"
                                        placeholder={t("title")}
                                    />
                                    <div className="mt-2 text-danger">
                                        <ErrorMessage name="title" />
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <label>{t("auth.register.step_1.phone")}</label>
                                    <Field
                                        className="w-100 input-style"
                                        name="phone"
                                        placeholder={t("auth.register.step_1.phone")}
                                    />
                                    <div className="mt-2 text-danger">
                                        <ErrorMessage name="phone" />
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <label>{t("auth.register.step_1.phone_op")}</label>
                                    <Field
                                        className="w-100 input-style"
                                        name="addition_phone"
                                        placeholder={t("auth.register.step_1.phone_op")}
                                    />
                                    <div className="mt-2 text-danger">
                                        <ErrorMessage name="addition_phone" />
                                    </div>
                                </div>
                            </div>

                            <div className="row px-2">
                                <div className="col-12">
                                    <label>
                                        {t("auth.register.step_3.col_2.generalDescription")}
                                    </label>
                                    <Field
                                        required
                                        className="w-100 input-style"
                                        as="textarea"
                                        name="description"
                                        placeholder={t("auth.register.step_3.col_2.generalDescription")}
                                    />
                                    <div className="mt-2 text-danger">
                                        <ErrorMessage name="description" />
                                    </div>
                                </div>
                            </div>

                            <div className="row px-2 pt-2">
                                <div className="col-md-6">
                                    <label>{t("auth.register.step_3.col_2.org")}</label>
                                    <Field name="service_categories">
                                        {({ form }) => (
                                            <>
                                                <select
                                                    className="w-100 input-style"
                                                    style={{ marginTop: "1px" }}
                                                    name="service_categories"
                                                    ref={serviceCategoriesInput}
                                                    onChange={(e) => handleChange(e, form, "service_categories")}
                                                >
                                                    <option value="" disabled selected>
                                                        {t("auth.register.step_3.col_2.org_place")}
                                                    </option>

                                                    {eventsList.map((event) => (
                                                        <option value={event.id} selected={values.service_categories.includes(event.id)}>{event.name}</option>
                                                    ))}
                                                </select>

                                                {
                                                    values.service_categories.length > 0
                                                    &&
                                                    <ul className="mt-2 p-0">
                                                        {
                                                            values.service_categories.map((item, i) => {
                                                                return (
                                                                    eventsList.length && eventsList.map((event) => (
                                                                        event.id == parseInt(item)
                                                                        &&
                                                                        <li className="tag_style"><span className="cursor-pointer" onClick={() => handleRemove(form, i, 'service_categories')}>&times;</span> &nbsp;{event.name}</li>
                                                                    ))
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                }
                                            </>
                                        )}
                                    </Field>
                                    <div className="mt-2 text-danger">
                                        <ErrorMessage name="service_categories" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label>{t("auth.register.step_3.col_2.target")}</label>
                                    <Field
                                        name="asylum_types"
                                        placeholder={t("auth.register.step_3.col_2.target_place")}
                                    >
                                        {({ form }) => (
                                            <>
                                                <select
                                                    className="w-100 input-style"
                                                    ref={asylumTypesInput}
                                                    style={{ marginTop: "1px" }}
                                                    name="asylum_types"
                                                    onChange={(e) => handleChange(e, form, "asylum_types")}
                                                >
                                                    <option value="" disabled selected>
                                                        {t("auth.register.step_3.col_2.target_place")}
                                                    </option>

                                                    {asylumList.map((asylum) => (
                                                        <option value={asylum.id} selected={values.asylum_types.includes(asylum.id)}>{asylum.name}</option>
                                                    ))}
                                                </select>
                                                {
                                                    values.asylum_types.length > 0
                                                    &&
                                                    <ul className="mt-2 p-0">
                                                        {
                                                            values.asylum_types.map((item, i) => {
                                                                return (
                                                                    asylumList.length && asylumList.map((asylum) => (
                                                                        asylum.id == parseInt(item)
                                                                        &&
                                                                        <li className="tag_style"><span className="cursor-pointer" onClick={() => handleRemove(form, i, "asylum_types")}>&times;</span> &nbsp;{asylum.name}</li>
                                                                    ))
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                }
                                            </>
                                        )}
                                    </Field>
                                    <div className="mt-2 text-danger">
                                        <ErrorMessage name="asylum_types" />
                                    </div>
                                </div>
                            </div>

                            {/* date */}
                            <div className="row px-2">
                                <div className="col-md-6">
                                    <label>{t("startDate")}</label>

                                    <Field name="from">
                                        {({ form }) => (
                                            <DateTimePickerComponent strictMode={true} step={15} value={initialValues.from} onChange={(e) => handleChange(e, form, "from")} />
                                        )}
                                    </Field>
                                    <div className="mt-2 text-danger">
                                        <ErrorMessage name="from"></ErrorMessage>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label>{t("endDate")}</label>

                                    <Field name="to">
                                        {({ form }) => (
                                            <DateTimePickerComponent strictMode={true} step={15} value={initialValues.to} onChange={(e) => handleChange(e, form, "to")} />
                                        )}
                                    </Field>
                                    <div className="mt-2 text-danger">
                                        <ErrorMessage name="to"></ErrorMessage>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <label className="m-2">{t('bookable')}</label>
                                    <Field>
                                        {({ form }) => (
                                            <input type="checkbox"
                                                id="CheckID"
                                                name="secret"
                                                onChange={() => {
                                                    form.setFieldValue('bookable', document.getElementById("CheckID").checked ? 1 : 0)
                                                }
                                                }
                                            />
                                        )}
                                    </Field>
                                </div>
                            </div>

                            {/* LOCATIONS */}
                            <div className="row mt-5">
                                <div className="col-12">
                                    <p className="sub-title">{t("location.locations")}</p>
                                </div>
                                {
                                    initialValues.locations.map((location, i) =>
                                        <Location
                                            key={i}
                                            i={i}
                                            arrayName='locations'
                                            governsList={governsList}
                                            location={location}
                                            onRemove={removeLocation}
                                        ></Location>
                                    )
                                }

                            </div>
                            <button className="btn btn-primary add-more" onClick={addLocation} type="button">{t("location.add")}</button>

                        </div>
                    </div>

                </div>

            </form>
        )
    }


    function handleSubmit(values) {

        setIsLoading(true);

        const formData = new FormData();

        for (const [key, val] of Object.entries(values)) {

            if (val !== null || val !== "") {

                switch (key) {

                    case "images":
                        val.map((obj, i) => {
                            if (obj.id) {
                                formData.append(`images[${i}][id]`, obj.id);
                            }
                            if (obj.image) {
                                formData.append(`images[${i}][image]`, obj.image);
                            }
                        })
                        break;

                    case "videos":
                        val.map((obj, i) => {
                            if (obj.id) {
                                formData.append(`videos[${i}][id]`, obj.id);
                            }
                            if (obj.video) {
                                formData.append(`videos[${i}][video]`, obj.video);
                            }
                        })
                        break;

                    case "service_categories":
                        val.map((option, i) => {
                            formData.append(`service_categories[${i}]`, option);
                        })
                        break;

                    case "asylum_types":
                        val.map((option, i) => {
                            formData.append(`asylum_types[${i}]`, option);
                        })
                        break;

                    case "locations":
                        val.map((v, i) => {
                            for (const [key2, val2] of Object.entries(v)) {
                                if (val2) {
                                    formData.append(`locations[${i}][${key2}]`, val2)
                                }
                            }
                        });
                        break;
                    case "from":
                    case "to":
                        !val.includes("Invalid")
                            &&
                            formData.append(key, val)
                        break;

                    case "phone":
                        formData.append('phones[0]', val);
                        break;

                    case "addition_phone":
                        val
                            ?
                            formData.append('phones[1]', val)
                            :
                            formData.delete('phones[1]')

                        break;

                    default:
                        formData.append(key, val);
                        break;

                }
            }

        }


        axiosInstance().post(`/event/${id}`, formData, { 'headers': { 'locale': Lang } })
            .then(res => {
                setError(false);
                setIsLoading(false);
                toast.current.show({ severity: 'success', summary: t("success"), detail: t("successMsg"), life: 2000 });
                dispatch(clearTemp());
            })
            .catch(err => {
                setIsLoading(false);
                setError(true);
                if (err.response !== null & err.response !== undefined) {
                    err.response.data.errors
                        ?
                        toast.current.show({ severity: 'warn', summary: t("warn"), detail: Object.values(err.response.data.errors && err.response.data.errors) })
                        :
                        toast.current.show({ severity: 'warn', summary: t("warn"), detail: t("tryLater") })
                } else {
                    toast.current.show({ severity: 'warn', summary: t("warn"), detail: t("tryLater") })
                }
            })
    }

    return (
        <div className="position-relative">
            {
                isLoading
                &&
                <div className="text-center spinner-container">
                    <i className="fas fa-circle-notch fa-spin fa-4x"></i>
                </div>
            }
            <div className="addition-page min-H">

                {
                    error
                        ?
                        < Toast position="top-center" ref={toast} />
                        :
                        < Toast position="top-center" ref={toast} onRemove={() => window.location.href = "/service-provider/dashboard/events"} />
                }

                <div className="container-fluid px-md-5 custom-container my-5">

                    <Formik validationSchema={handleValidation} innerRef={formRef} initialValues={initialValues} enableReinitialize={true} onSubmit={handleSubmit}>
                        {formBody}
                    </Formik>

                </div>

            </div>
        </div>
    )

}

export default EditE
