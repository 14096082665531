import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

export default function CarouselSection(props) {

    return (

        <Carousel>

            {
                props.items.length > 0
                &&
                props.items.map((section, i) => (
                    <Carousel.Item key={i}>
                        <img
                            className="d-block w-100 h-100"
                            src={section.img}
                            alt="First slide"
                        />
                        <Carousel.Caption>
                            <div>
                                <h1 className="carousel-title">{section.title}</h1>
                                <p className="carousel-desc">{section.desc}</p>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                ))
            }


        </Carousel>

    );
}