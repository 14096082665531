import React, { useState } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { FileUpload } from "primereact/fileupload";
import { useSelector, useDispatch } from "react-redux";
import { auth } from "../../../store/actions/auth";
import { clearTemp, updateTags } from "../../../store/actions/temp";
import { TabView, TabPanel } from "primereact/tabview";
import axios from "axios";
import { Toast } from 'primereact/toast';
import { TimePickerComponent } from '@syncfusion/ej2-react-calendars';

function Final_step() {

  const toast = React.useRef(null);
  const formRef = React.useRef(null);

  // language
  const Lang = localStorage.getItem("i18nextLng");

  const [activeIndex, setActiveIndex] = React.useState(null);

  const authReducer = useSelector((state) => state.authReducer.user);

  // make the actions on the state
  const dispatch = useDispatch();

  const { t } = useTranslation();

  // loading state
  const [isLoading, setIsLoading] = React.useState(true);

  // upload setting
  const noFile = null;
  const inputFile = React.useRef(null);
  const [fileName, setFileName] = React.useState(noFile);
  const btnClicked = () => {
    inputFile.current.click();
  };

  const [workingHours, setWorkingHours] = React.useState([]);

  const [servicesList, setServicesList] = React.useState([
    {
      id: "",
      name: "",
    },
  ]);

  const [asylumList, setAsylumList] = useState([
    {
      id: "",
      name: "",
    },
  ]);

  const [registriesList, setRegistriesList] = useState([
    {
      id: "",
      name: "",
    },
  ]);

  let initialValues = {
    working_hours: {
      sunday: {
        from: "",
        to: "",
      },
      monday: {
        from: "",
        to: "",
      },
      tuesday: {
        from: "",
        to: "",
      },
      wednesday: {
        from: "",
        to: "",
      },
      thursday: {
        from: "",
        to: "",
      },
      friday: {
        from: "",
        to: "",
      },
      saturday: {
        from: "",
        to: "",
      },
    },

    logo: "",
    services: [],
    local_government_registry: "",
    local_government_registry_id: "",
    asylum_types: [],
    local_government_registry_type: "",
    description: "",
  };

  let list = useSelector((state) => state.TempReducer || []);
  let listII = JSON.parse(localStorage.getItem("target")) || [];

  // handleValidation
  function handleValidation() {
    const schema = Yup.object().shape({

      logo: Yup.string().required(t("req")),
      description: Yup.string().required(t("req")),
      services: Yup.array().min(1, t("req")),
      asylum_types: Yup.array().min(1, t("req")),
      local_government_registry: Yup.string().required(t("req")),
      local_government_registry_type: Yup
        .string()
        .when("original_name", {
          is: "other",
          then: Yup.string().required(t("req"))
        }),

      local_government_registry_id: Yup.string().when("local_government_registry", {
        is: "yes",
        then: Yup.string().required(t("req")),
      }),

      working_hours: Yup.object().shape({
        sunday: Yup.object().shape({

          from: Yup.string()
            .when("to", {
              is: (value) => value && value.length > 0,
              then: Yup.string().required(t("req"))
            })
            .test("from", t("wrongIn"), function (str) {
              if (str == "" || str == undefined) {
                return true
              } else {
                return str.indexOf(' ') == 5 || str.indexOf(' ') == 4 && str.includes('AM') || str.includes('PM')
              }

            }),

          to: Yup.string()
            .when("from", {
              is: (value) => value && value.length > 0,
              then: Yup.string().required(t("req"))
            })
            .test("to", t("wrongIn"), function (str) {
              if (str == "" || str == undefined) {
                return true
              } else {
                return str.indexOf(' ') == 5 || str.indexOf(' ') == 4 && str.includes('AM') || str.includes('PM')
              }
            }),

        }, ["from", "to"]),
        monday: Yup.object().shape({

          from: Yup.string()
            .when("to", {
              is: (value) => value && value.length > 0,
              then: Yup.string().required(t("req"))
            })
            .test("from", t("wrongIn"), function (str) {
              if (str == "" || str == undefined) {
                return true
              } else {
                return str.indexOf(' ') == 5 || str.indexOf(' ') == 4 && str.includes('AM') || str.includes('PM')
              }
            }),
          to: Yup.string()
            .when("from", {
              is: (value) => value && value.length > 0,
              then: Yup.string().required(t("req"))
            })
            .test("to", t("wrongIn"), function (str) {
              if (str == "" || str == undefined) {
                return true
              } else {
                return str.indexOf(' ') == 5 || str.indexOf(' ') == 4 && str.includes('AM') || str.includes('PM')
              }
            }),

        }, ["from", "to"]),
        tuesday: Yup.object().shape({

          from: Yup.string()
            .when("to", {
              is: (value) => value && value.length > 0,
              then: Yup.string().required(t("req"))
            })
            .test("from", t("wrongIn"), function (str) {
              if (str == "" || str == undefined) {
                return true
              } else {
                return str.indexOf(' ') == 5 || str.indexOf(' ') == 4 && str.includes('AM') || str.includes('PM')
              }
            }),
          to: Yup.string()
            .when("from", {
              is: (value) => value && value.length > 0,
              then: Yup.string().required(t("req"))
            })
            .test("to", t("wrongIn"), function (str) {
              if (str == "" || str == undefined) {
                return true
              } else {
                return str.indexOf(' ') == 5 || str.indexOf(' ') == 4 && str.includes('AM') || str.includes('PM')
              }
            }),

        }, ["from", "to"]),
        wednesday: Yup.object().shape({
          from: Yup.string()
            .when("to", {
              is: (value) => value && value.length > 0,
              then: Yup.string().required(t("req"))
            })
            .test("from", t("wrongIn"), function (str) {
              if (str == "" || str == undefined) {
                return true
              } else {
                return str.indexOf(' ') == 5 || str.indexOf(' ') == 4 && str.includes('AM') || str.includes('PM')
              }
            }),
          to: Yup.string().when("from", {
            is: (value) => value && value.length > 0,
            then: Yup.string().required(t("req"))
          })
            .test("to", t("wrongIn"), function (str) {
              if (str == "" || str == undefined) {
                return true
              } else {
                return str.indexOf(' ') == 5 || str.indexOf(' ') == 4 && str.includes('AM') || str.includes('PM')
              }
            }),
        }, ["from", "to"]),
        thursday: Yup.object().shape({
          from: Yup.string()
            .when("to", {
              is: (value) => value && value.length > 0,
              then: Yup.string().required(t("req"))
            })
            .test("from", t("wrongIn"), function (str) {
              if (str == "" || str == undefined) {
                return true
              } else {
                return str.indexOf(' ') == 5 || str.indexOf(' ') == 4 && str.includes('AM') || str.includes('PM')
              }
            }),
          to: Yup.string().when("from", {
            is: (value) => value && value.length > 0,
            then: Yup.string().required(t("req"))
          })
            .test("to", t("wrongIn"), function (str) {
              if (str == "" || str == undefined) {
                return true
              } else {
                return str.indexOf(' ') == 5 || str.indexOf(' ') == 4 && str.includes('AM') || str.includes('PM')
              }
            }),
        }, ["from", "to"]),
        friday: Yup.object().shape({
          from: Yup.string()
            .when("to", {
              is: (value) => value && value.length > 0,
              then: Yup.string().required(t("req"))
            })
            .test("from", t("wrongIn"), function (str) {
              if (str == "" || str == undefined) {
                return true
              } else {
                return str.indexOf(' ') == 5 || str.indexOf(' ') == 4 && str.includes('AM') || str.includes('PM')
              }
            }),
          to: Yup.string().when("from", {
            is: (value) => value && value.length > 0,
            then: Yup.string().required(t("req"))
          })
            .test("to", t("wrongIn"), function (str) {
              if (str == "" || str == undefined) {
                return true
              } else {
                return str.indexOf(' ') == 5 || str.indexOf(' ') == 4 && str.includes('AM') || str.includes('PM')
              }
            }),
        }, ["from", "to"]),
        saturday: Yup.object().shape({
          from: Yup.string()
            .when("to", {
              is: (value) => value && value.length > 0,
              then: Yup.string().required(t("req"))
            })
            .test("from", t("wrongIn"), function (str) {
              if (str == "" || str == undefined) {
                return true
              } else {
                return str.indexOf(' ') == 5 || str.indexOf(' ') == 4 && str.includes('AM') || str.includes('PM')
              }
            }),
          to: Yup.string().when("from", {
            is: (value) => value && value.length > 0,
            then: Yup.string().required(t("req"))
          })
            .test("to", t("wrongIn"), function (str) {
              if (str == "" || str == undefined) {
                return true
              } else {
                return str.indexOf(' ') == 5 || str.indexOf(' ') == 4 && str.includes('AM') || str.includes('PM')
              }
            }),
        }, ["from", "to"])
      }, ['to'])

    })
    return schema;
  }

  React.useEffect(() => {

    axios("/service-categories", { 'headers': { 'locale': Lang } })
      .then((response) => {
        setServicesList(response.data.data);
        document.querySelectorAll("select")[0].disabled = false;
      })

    axios(`/asylum-types`, { 'headers': { 'locale': Lang } })
      .then((response) => {
        setAsylumList(response.data.data);
        setIsLoading(false)
        document.querySelectorAll("select")[1].disabled = false;
      })
      .catch((error) => {
        setIsLoading(false)
      });


    for (let i = 1; i <= 24; i++) {
      if (i == 24) {
        workingHours.push(`0:00`);
      } else {
        workingHours.push(`${i}:00`);
      }
    }
    setWorkingHours(workingHours);

    dispatch(clearTemp());
    localStorage.removeItem("target")


  }, [Lang]);

  function handleSubmit(values) {
    setIsLoading(true)

    // create formdata
    let formData = new FormData();

    for (const [key, val] of Object.entries(values)) {
      if (key == "working_hours") {
        for (const [key2, val2] of Object.entries(val)) {
          for (const [key3, val3] of Object.entries(val2)) {
            formData.append(`working_hours[${key2}][${key3}]`, val3);
          }
        }
      } else if (key == "asylum_types") {
        listII.map((v, i) => {
          formData.append(`asylum_types[${i}]`, v);
        });
      } else if (key == "services") {
        list.map((v, i) => {
          formData.append(`services[${i}]`, v);
        });
      } else {
        formData.append(key, val);
      }
    }

    for (const [key, val] of Object.entries(authReducer)) {
      if (key == "locations") {
        val.map((v, i) => {
          for (const [key2, val2] of Object.entries(v)) {
            formData.append(`locations[${i}][${key2}]`, val2);
          }
        });
      } else if (key == "focal_points") {
        val.map((v, i) => {
          for (const [key2, val2] of Object.entries(v)) {
            formData.append(`focal_points[${i}][${key2}]`, val2);
          }
        });
      } else {
        formData.append(key, val);
      }
    }

    axios
      .post("/register/service-provider", formData)
      .then((response) => {
        // axios.defaults.headers.common["Authorization"] = `Bearer ${response.data.data.access_token}`
        setIsLoading(false)
        dispatch(auth(response.data.data));
        window.location.href = "/";
        dispatch(clearTemp());
        // window.location.href = response.data.data.user.email_verified ? "/" : '/welcome'
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false)
        if (err.response !== null & err.response !== undefined) {
          err.response.data.errors
            ?
            toast.current.show({ severity: 'warn', summary: t("warn"), detail: Object.values(err.response.data.errors) })
            :
            toast.current.show({ severity: 'warn', summary: t("warn"), detail: t("tryLater") })
        } else {
          toast.current.show({ severity: 'warn', summary: t("warn"), detail: t("tryLater") })
        }
      });
  }

  function handleChange(e, form, name) {
    if (name == "services") {

      list = [...list, e.target.value];
      list = [...new Set(list)];
      dispatch(updateTags(list));
      form.setFieldValue(name, list)

    } else if (name == "asylum_types") {
      listII = [...listII, e.target.value];
      listII = [...new Set(listII)];
      localStorage.setItem("target", JSON.stringify(listII))
      form.setFieldValue(name, listII)

    } else if (name == "local_government_registry_id") {
      form.setFieldValue(name, e.target.value)
      form.setFieldValue("original_name", e.target.selectedOptions[0].getAttribute("data-myAttri"))

    } else if (name.startsWith("working")) {
      form.setFieldValue(name, e)
    } else {

      form.setFieldValue(name, e.target.value)

    }

  }

  const serviceCategoriesInput = React.useRef(null);
  const asylumTypesInput = React.useRef(null);

  function handleRemove(form, i, name) {


    if (name == "services") {

      list.splice(i, 1);
      dispatch(updateTags(list));
      form.setFieldValue(name, [...list])

      if (list.length == 0) {
        serviceCategoriesInput.current.value = 0;
      }

    } else {
      listII.splice(i, 1);
      localStorage.setItem("target", JSON.stringify(listII));
      form.setFieldValue(name, [...listII]);

      if (listII.length == 0) {
        asylumTypesInput.current.value = 0;
      }

    }

  }


  function handleTabChange(e, form) {
    setActiveIndex(e.index);

    switch (e.index) {
      case 1:
        form.setFieldValue("local_government_registry", "no");
        break;

      case 2:
        form.setFieldValue("local_government_registry", "na");
        break;

      default:
        form.setFieldValue("local_government_registry", "yes");
        axios("/government-registries", { 'headers': { 'locale': Lang } })
          .then((response) => {
            setRegistriesList(response.data.data);
            document.querySelectorAll("select")[2].disabled = false;
            setIsLoading(false)
          })
          .catch((error) => {
            setIsLoading(false)
          });
        break;
    }
  }

  function formBody({ handleSubmit, values }) {
    return (
      <form className="auth-form step_form" onSubmit={handleSubmit}>
        <div className="title">{t("auth.register.step_3.title")}</div>

        <div className="row">
          <div className="col-lg-5 main-colI row mx-auto">

            <div className="col-5">
              <p>{t("auth.register.step_3.col_1.workingHours")}</p>
            </div>
            <div className="col-7 row no-gutters">
              <div className="col-6">
                <p>{t("from")}</p>
              </div>
              <div className="col-6 px-3">
                <p>{t("to")}</p>
              </div>
            </div>


            <div className="day-row w-100">
              <div className="row">
                <div className="col-5">
                  <div className="input-style hour-col">
                    {t("auth.register.step_3.col_1.sunday")}
                  </div>
                </div>

                <div className="row from-to col-7 no-gutters">
                  <div className="col-6">
                    <Field name="working_hours.sunday.from">
                      {({ form }) => (

                        <TimePickerComponent strictMode={true} step={15} change={(e) => handleChange(e.text, form, "working_hours.sunday.from")} />

                      )}
                    </Field>
                    <div className="mt-2 text-danger">
                      <ErrorMessage name="working_hours.sunday.from"></ErrorMessage>
                    </div>
                  </div>

                  <div className="col-6">
                    <Field name="working_hours.sunday.to">
                      {({ form }) => (

                        <TimePickerComponent strictMode={true} step={15} change={(e) => handleChange(e.text, form, "working_hours.sunday.to")} />

                      )}
                    </Field>

                    <div className="mt-2 text-danger">
                      <ErrorMessage name="working_hours.sunday.to"></ErrorMessage>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="day-row w-100">
              <div className="row">
                <div className="col-5">
                  <div className="input-style hour-col">
                    {t("auth.register.step_3.col_1.monday")}
                  </div>
                </div>
                <div className="row from-to col-7 no-gutters">
                  <div className="col-6">
                    <Field name="working_hours.monday.from">
                      {({ form }) => (

                        <TimePickerComponent strictMode={true} step={15} change={(e) => handleChange(e.text, form, "working_hours.monday.from")} />

                      )}
                    </Field>
                    <div className="mt-2 text-danger">
                      <ErrorMessage name="working_hours.monday.from"></ErrorMessage>
                    </div>
                  </div>

                  <div className="col-6">
                    <Field name="working_hours.monday.to">
                      {({ form }) => (

                        <TimePickerComponent strictMode={true} step={15} change={(e) => handleChange(e.text, form, "working_hours.monday.to")} />

                      )}
                    </Field>
                    <div className="mt-2 text-danger">
                      <ErrorMessage name="working_hours.monday.to"></ErrorMessage>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="day-row w-100">
              <div className="row">
                <div className="col-5">
                  <div className="input-style hour-col">
                    {t("auth.register.step_3.col_1.tuesday")}
                  </div>
                </div>
                <div className="row from-to col-7 no-gutters">
                  <div className="col-6">
                    <Field name="working_hours.tuesday.from">
                      {({ form }) => (

                        <TimePickerComponent strictMode={true} step={15} change={(e) => handleChange(e.text, form, "working_hours.tuesday.from")} />

                      )}
                    </Field>
                    <div className="mt-2 text-danger">
                      <ErrorMessage name="working_hours.tuesday.from"></ErrorMessage>
                    </div>
                  </div>

                  <div className="col-6">
                    <Field name="working_hours.tuesday.to">
                      {({ form }) => (
                        <TimePickerComponent strictMode={true} step={15} change={(e) => handleChange(e.text, form, "working_hours.tuesday.to")} />

                      )}
                    </Field>
                    <div className="mt-2 text-danger">
                      <ErrorMessage name="working_hours.tuesday.to"></ErrorMessage>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="day-row w-100">
              <div className="row">
                <div className="col-5">
                  <div className="input-style hour-col">
                    {t("auth.register.step_3.col_1.wednesday")}
                  </div>
                </div>
                <div className="row from-to col-7 no-gutters">
                  <div className="col-6">
                    <Field name="working_hours.wednesday.from">
                      {({ form }) => (
                        <TimePickerComponent strictMode={true} step={15} change={(e) => handleChange(e.text, form, "working_hours.wednesday.from")} />

                      )}
                    </Field>
                    <div className="mt-2 text-danger">
                      <ErrorMessage name="working_hours.wednesday.from"></ErrorMessage>
                    </div>
                  </div>

                  <div className="col-6">
                    <Field name="working_hours.wednesday.to">
                      {({ form }) => (
                        <TimePickerComponent strictMode={true} step={15} change={(e) => handleChange(e.text, form, "working_hours.wednesday.to")} />
                      )}
                    </Field>
                    <div className="mt-2 text-danger">
                      <ErrorMessage name="working_hours.wednesday.to"></ErrorMessage>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="day-row w-100">
              <div className="row">
                <div className="col-5">
                  <div className="input-style hour-col">
                    {t("auth.register.step_3.col_1.thursday")}
                  </div>
                </div>
                <div className="row from-to col-7 no-gutters">
                  <div className="col-6">
                    <Field name="working_hours.thursday.from">
                      {({ form }) => (

                        <TimePickerComponent strictMode={true} step={15} change={(e) => handleChange(e.text, form, "working_hours.thursday.from")} />

                      )}
                    </Field>
                    <div className="mt-2 text-danger">
                      <ErrorMessage name="working_hours.thursday.from"></ErrorMessage>
                    </div>
                  </div>

                  <div className="col-6">
                    <Field name="working_hours.thursday.to">
                      {({ form }) => (
                        <TimePickerComponent strictMode={true} step={15} change={(e) => handleChange(e.text, form, "working_hours.thursday.to")} />
                      )}
                    </Field>
                    <div className="mt-2 text-danger">
                      <ErrorMessage name="working_hours.thursday.to"></ErrorMessage>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="day-row w-100">
              <div className="row">
                <div className="col-5">
                  <div className="input-style hour-col">
                    {t("auth.register.step_3.col_1.friday")}
                  </div>
                </div>
                <div className="row from-to col-7 no-gutters">
                  <div className="col-6">
                    <Field name="working_hours.friday.from">
                      {({ form }) => (
                        <TimePickerComponent strictMode={true} step={15} change={(e) => handleChange(e.text, form, "working_hours.friday.from")} />
                      )}
                    </Field>
                    <div className="mt-2 text-danger">
                      <ErrorMessage name="working_hours.friday.from"></ErrorMessage>
                    </div>
                  </div>

                  <div className="col-6">
                    <Field name="working_hours.friday.to">
                      {({ form }) => (
                        <TimePickerComponent strictMode={true} step={15} change={(e) => handleChange(e.text, form, "working_hours.friday.to")} />
                      )}
                    </Field>
                    <div className="mt-2 text-danger">
                      <ErrorMessage name="working_hours.friday.to"></ErrorMessage>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="day-row w-100">
              <div className="row">
                <div className="col-5">
                  <div className="input-style hour-col">
                    {t("auth.register.step_3.col_1.saturday")}
                  </div>
                </div>
                <div className="row from-to col-7 no-gutters">
                  <div className="col-6">
                    <Field name="working_hours.saturday.from">
                      {({ form }) => (
                        <TimePickerComponent strictMode={true} step={15} change={(e) => handleChange(e.text, form, "working_hours.saturday.from")} />
                      )}
                    </Field>
                    <div className="mt-2 text-danger">
                      <ErrorMessage name="working_hours.saturday.from"></ErrorMessage>
                    </div>
                  </div>

                  <div className="col-6">
                    <Field name="working_hours.saturday.to">
                      {({ form }) => (
                        <TimePickerComponent strictMode={true} step={15} change={(e) => handleChange(e.text, form, "working_hours.saturday.to")} />

                      )}
                    </Field>
                    <div className="mt-2 text-danger">
                      <ErrorMessage name="working_hours.saturday.to"></ErrorMessage>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-7 main-colII">
            <div className="row mb-0 mb-md-3">
              <div
                className="col-md-6 mb-md-0 mb-3 id-photo PIE">
                <label>{t("auth.register.step_1.logo")}</label>
                <Field onClick={btnClicked} className="field" name="logo">
                  {({ form }) => (
                    <>
                      <FileUpload
                        onSelect={(event) => {
                          let file = event.files[0];
                          let reader = new FileReader();
                          if (file && file.type.match('image.*')) {
                            reader.readAsDataURL(file);
                            setFileName(file.name);
                            form.setFieldValue("logo", file);
                          }
                        }}
                        ref={inputFile}
                        accept="image/*"
                      />
                      <input
                        type="file"
                        className="d-none"
                        ref={inputFile}
                        onChange={(event) => {
                          let file = event.target.files[0];
                          let reader = new FileReader();
                          if (file && file.type.match('image.*')) {
                            reader.readAsDataURL(file);
                            setFileName(file.name);
                            form.setFieldValue("logo", file);
                          }

                        }}
                      />
                    </>
                  )}
                </Field>


                <Field
                  onClick={btnClicked}
                  name="logo"
                  placeholder={fileName || t("upImg")}
                  value={fileName || ""}
                  icon="fas fa-file-upload"
                  className="w-100 input-style cursor-none upload"
                />
                <div className="mt-2 text-danger">
                  <ErrorMessage name="logo" />
                </div>
              </div>

              <div className="col-md-6 mb-md-0 mb-3 PIS">
                <label>{t("auth.register.step_3.col_2.org")}</label>
                <Field name="services">
                  {({ form }) => (
                    <>

                      <select
                        disabled
                        className="w-100 input-style"
                        style={{ marginTop: "1px" }}
                        name="services"
                        ref={serviceCategoriesInput}
                        onChange={(e) => handleChange(e, form, "services")}
                      >
                        <option value="" disabled selected>
                          {t("auth.register.step_3.col_2.org_place")}
                        </option>

                        {servicesList.map((service, i) => (
                          <option key={i} value={service.id}>{service.name}</option>
                        ))}
                      </select>

                      {
                        values.services.length > 0
                        &&
                        <ul className="mt-2 p-0">
                          {
                            values.services.map((item, i) => {
                              return (
                                servicesList.length && servicesList.map((service) => (
                                  service.id == parseInt(item)
                                  &&
                                  <li key={i} className="tag_style"><span className="cursor-pointer" onClick={() => handleRemove(form, i, "services")}>x</span> &nbsp;{service.name}</li>
                                ))
                              )
                            })
                          }
                        </ul>
                      }

                    </>
                  )}
                </Field>
                <div className="mt-2 text-danger">
                  <ErrorMessage name="services"></ErrorMessage>
                </div>
              </div>
            </div>

            <div className="row mb-0 mb-md-3">
              <div className="col-md-6 mb-md-0 mb-3 PIE">
                <label>{t("auth.register.step_3.col_2.target")}</label>
                <Field
                  name="asylum_types"
                  placeholder={t("auth.register.step_3.col_2.target_place")}
                >
                  {({ form }) => (
                    <>
                      <select
                        disabled
                        className="w-100 input-style"
                        style={{ marginTop: "1px" }}
                        name="asylum_types"
                        ref={asylumTypesInput}
                        onChange={(e) => handleChange(e, form, "asylum_types")}
                      >
                        <option value="" disabled selected>
                          {t("auth.register.step_3.col_2.target_place")}
                        </option>

                        {asylumList.map((asylum, i) => (
                          <option key={i} value={asylum.id}>{asylum.name}</option>
                        ))}
                      </select>
                      {
                        values.asylum_types.length > 0
                        &&
                        <ul className="mt-2 p-0">
                          {
                            values.asylum_types.map((item, i) => {
                              return (
                                asylumList.length && asylumList.map((asylum) => (
                                  asylum.id == parseInt(item)
                                  &&
                                  <li key={i} className="tag_style"><span className="cursor-pointer" onClick={() => handleRemove(form, i, "asylum_types")}>x</span> &nbsp;{asylum.name}</li>
                                ))
                              )
                            })
                          }
                        </ul>
                      }
                    </>
                  )}

                </Field>
                <div className="mt-2 text-danger">
                  <ErrorMessage name="asylum_types"></ErrorMessage>
                </div>
              </div>

              <div className="col-md-6 mb-md-0 mb-3 PIS">
                <label>{t("auth.register.step_3.col_2.question")}</label>
                <Field name="local_government_registry">
                  {({ form }) => (
                    <TabView
                      activeIndex={activeIndex}
                      onTabChange={(e) => handleTabChange(e, form)}
                    >
                      <TabPanel header={t("yes")} />

                      <TabPanel header={t("no")} />

                      <TabPanel header={t("N/A")} />

                    </TabView>
                  )}
                </Field>
                <div className="mt-2 text-danger">
                  <ErrorMessage name="local_government_registry" />
                </div>
              </div>
            </div>

            <div className="row mb-0 mb-md-3">
              <div className="col-md-6 mb-md-0 mb-3 PIE">
                <label>{t("auth.register.step_3.col_2.if_I")}</label>
                <Field name="local_government_registry">
                  {({ form }) => (
                    <select
                      disabled
                      className="w-100 input-style"
                      style={{ marginTop: "1px" }}
                      name="local_government_registry_id"
                      onChange={(e) =>
                        handleChange(e, form, "local_government_registry_id")
                      }
                    >
                      <option value="" disabled selected>
                        {t("auth.register.step_1.shortName_place")}
                      </option>

                      {registriesList.map((registry, i) => (
                        <option key={i} value={registry.id} data-myAttri={registry.original_name}>{registry.name}</option>
                      ))}
                    </select>
                  )}
                </Field>
                <div className="mt-2 text-danger">
                  <ErrorMessage name="local_government_registry_id" />
                </div>
              </div>
              <div className="col-md-6 mb-md-0 mb-3 PIS">
                <label>{t("auth.register.step_3.col_2.if_II")}</label>
                <Field
                  className="w-100 input-style"
                  name="local_government_registry_type"
                  placeholder={t("specify")}
                />
                <div className="mt-2 text-danger">
                  <ErrorMessage name="local_government_registry_type" />
                </div>
              </div>
            </div>

            <div className="mb-0 mb-md-3">
              <label>
                {t("auth.register.step_3.col_2.generalDescription")}
              </label>
              <Field
                className="w-100 input-style"
                as="textarea"
                name="description"
                placeholder={t("auth.register.step_3.col_2.generalDescription")}
              />
              <div className="mt-2 text-danger">
                <ErrorMessage name="description"></ErrorMessage>
              </div>
            </div>
          </div>
        </div>

        <div className="row form-controls">

          {
            isLoading
            &&
            <div className="p-2 pb-0">
              <i className="fas fa-circle-notch fa-spin fa-2x"></i>
            </div>
          }

          <a href="/login" className="alreadyReg d-flex align-items-center">
            {t("auth.register.already")}
          </a>
          <button
            type="submit"
            className="next-button main-buttonII px-5 text-capitalize d-inline-block"
          >
            {t("next")}
          </button>

        </div>

      </form>
    )
  }

  return (
    <div className="register">

      <Toast position="top-center" ref={toast} />

      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={handleValidation}>
        {formBody}
      </Formik>

    </div>
  )

}

export default Final_step;
