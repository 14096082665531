import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Toast } from 'primereact/toast';
import axiosInstance from '../../../services/axiosInstance';
import Pager from '../../../../components/Pager';

function Requests() {

    const { t } = useTranslation();

    const toast = React.useRef(null);

    const Lang = localStorage.getItem("i18nextLng");

    // loading state
    const [isLoading, setIsLoading] = React.useState(true);
    const [reqList, setReqList] = useState([]);
    const [update, setIsUpdate] = useState(false);

    useEffect(() => {

        axiosInstance().get("/service-provider?approved=0", { 'headers': { 'locale': Lang } })
            .then((res) => {
                setTotal(res.data.data.pageInfo.total);
                setReqList(res.data.data.collection)
                setIsLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false)
            })

    }, [Lang, update]);


    // handle submit
    function handleClick(e, id) {

        setIsLoading(true);

        axiosInstance().post(`/service-provider/${e ? "approve" : "reject"}/${id}`)
            .then((res) => {
                // setTotal(res.data.data.pageInfo.total);
                // setReqList(res.data.data.collection)
                setIsUpdate(!update)
                setIsLoading(false);;
                toast.current.show({ severity: 'success', summary: t("success"), detail: t("successMsg"), life: 2000 });
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
                toast.current.show({ severity: 'warn', summary: t("warn"), detail: Object.values(err.response.data.errors && err.response.data.errors) })
            })
    }

    // pager code
    const [Total, setTotal] = React.useState(0);

    const onBasicPageChange = (event) => {

        setIsLoading(true)

        axiosInstance().get(`/service-provider?approved=0&page=${event.page + 1}&perPage=${event.rows}`, { 'headers': { 'locale': Lang } })
            .then(res => {
                // set list
                setReqList(res.data.data.collection)
                setTotal(res.data.data.pageInfo.total);
                setIsLoading(false)

            }).catch(err => {
                console.log(err);
                setIsLoading(false)
            }
            )
    }


    return (

        <div className="requests h-100">
            <div className="position-relative h-100">
                <Toast position="top-center" ref={toast} />
                {
                    isLoading
                    &&
                    <div className="text-center spinner-container">
                        <i className="fas fa-circle-notch fa-spin fa-4x"></i>
                    </div>
                }

                <div className="min-H pt-5 SP-Dash px-4">

                    <div className="labels d-flex justify-content-between w-100 align-items-center mb-5">
                        <span className="h2 reset-bold text-capitalize">{t("admin.requests")}</span>
                        <span className="reset-bold text-capitalize small-text">{t("admin.hint")}</span>
                    </div>

                    <div className="cards_list service_cards row">

                        {
                            reqList.length > 0
                                ?
                                reqList.map((item, i) => {
                                    return <div className="col-6" key={i}>
                                        <div className="border mb-4 req-box">

                                            <div className="row p-4 h-100">

                                                <div className="col-12 row">
                                                    <div className="col-3">
                                                        <img alt="logo" className="w-100 img-responsive rounded-circle" src={item.logo || "/img/example.png"} />
                                                    </div>
                                                    <div className="col-9 align-items-center d-flex">
                                                        <a href={`/admin/service-provider/requests/${item.id}`}>{item.user.name}</a>
                                                    </div>
                                                </div>
                                                <div className="col-12 mt-3 justify-content-end d-flex">
                                                    <button type="button" onClick={() => handleClick(1, item.id)} className="submit-button my-auto mx-3 custom text-capitalize d-inline-block px-2 py-1">{t("admin.approve")}</button>
                                                    <button type="button" onClick={() => handleClick(0, item.id)} className="reset-button text-capitalize d-inline-block">{t("admin.reject")}</button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                })
                                :
                                <p className="lead mx-3 no-cont">{t("noContent")}</p>
                        }

                    </div>
                </div>

                {reqList.length > 0 && <Pager total={Total} onPageChange={onBasicPageChange} />}

            </div>
        </div>
    );
}

export default Requests
