import React, { useState } from "react";
import { Field, ErrorMessage } from "formik";
import { useTranslation } from 'react-i18next';
import axiosInstance from "../../containers/services/axiosInstance";

export default function FocalPoint(props) {
    const { arrayName, i, focalPoint, ...rest } = props

    const Lang = localStorage.getItem("i18nextLng");

    const { t } = useTranslation();

    function handleChange(e, form, i, type, name) {
        form.setFieldValue(name, e.target.value);
    }

    return (
        <div className="container-fluid">

            <div className="col-12 row p-0 mb-5">

                {/* NAME */}
                <div className="col-5">
                    <label>{t("auth.register.step_1.fullName")}</label>
                    <Field
                        className="w-100 input-style"
                        name={`${arrayName}[${i}][name]`}
                        placeholder={t("auth.register.step_1.fullName")}
                    />
                    <div className="mt-2 text-danger">
                        <ErrorMessage name={`${arrayName}[${i}].name`} />
                    </div>

                </div>

                {/* EMAIL */}
                <div className="col-5">
                    <label>{t("auth.register.step_1.emailAddress")}</label>
                    <Field
                        className="w-100 input-style"
                        name={`${arrayName}[${i}][email]`}
                        placeholder={t("auth.register.step_1.emailAddress")}
                    />
                    <div className="mt-2 text-danger">
                        <ErrorMessage name={`${arrayName}[${i}].email`} />
                    </div>

                </div>

                {/* DELETE BUTTON  */}
                {
                    i > 0 &&
                    <div className="col-2 d-flex align-items-end justify-content-end pb-2">
                        <button className="btn btn-danger" type="button" onClick={() => props.onRemove(i)}>&times;</button>
                    </div>
                }

                {/* PHONE */}
                <div className="col-5">
                    <label>{t("auth.register.step_1.phone")}</label>
                    <Field
                        className="w-100 input-style"
                        name={`${arrayName}[${i}][phone]`}
                        placeholder={t("auth.register.step_1.phone")}
                    />
                    <div className="mt-2 text-danger">
                        <ErrorMessage name={`${arrayName}[${i}].phone`} />
                    </div>

                </div>

                {/* POSITION */}
                <div className="col-5">
                    <label>{t("auth.register.step_4.position")}</label>
                    <Field
                        className="w-100 input-style"
                        name={`${arrayName}[${i}][position]`}
                        placeholder={t("auth.register.step_4.position")}
                    />
                    <div className="mt-2 text-danger">
                        <ErrorMessage name={`${arrayName}[${i}].position`} />
                    </div>
                </div>

            </div>
        </div>
    )
}
