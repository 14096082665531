import React from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next'

export default function ErrorPage() {

    const { t, i18n } = useTranslation();
    const { id } = useParams();

    return (
        <div className="errorPage">
            <div className="min-H container-fluid p-4 mx-auto text-center">

                {
                    id == "404"
                        ?
                        <>
                            <h2 className="h2-responsive">404</h2>

                            <p>
                                {t("errMSG3")}<br>
                                </br>
                                <a href="/">{t("goHome")}</a>
                            </p>
                        </>
                        :
                        id == "500"
                            ?
                            <>
                                <h2 className="h2-responsive">500</h2>
                                <p>
                                    {t("errMSG1")}<br>
                                    </br>
                                    <a href="/">{t("goHome")}</a>
                                </p>
                            </>
                            :
                            id == "403"
                            &&
                            <>
                                <h2 className="h2-responsive">403</h2>
                                <p>
                                    {t("errMSG1")}<br>
                                    </br>
                                    <a href="/">{t("goHome")}</a>
                                </p>
                            </>
                }

            </div>
        </div>
    )
}
