import React from 'react';
import { useParams } from 'react-router';
import axiosInstance from "../../../services/axiosInstance";
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

function Resources_details({ isAdmin }) {

    const { id } = useParams();

    // loading state
    const [isLoading, setIsLoading] = React.useState(true);

    const { t } = useTranslation();

    const [data, setData] = React.useState([]);

    const Lang = localStorage.getItem("i18nextLng");


    React.useEffect(() => {

        setIsLoading(true)

        axiosInstance().get(`/resource/${id}`, { 'headers': { 'locale': Lang } })
            .then((response) => {
                setData(response.data.data);
                setIsLoading(false)
            })
            .catch((error) => {
                setIsLoading(false)
            })

    }, [Lang])

    return (
        <>

            <div className="position-relative">
                {
                    isLoading
                    &&
                    <div className="text-center spinner-container">
                        <i className="fas fa-circle-notch fa-spin fa-4x"></i>
                    </div>
                }

                <div className={isAdmin ? "min-H pt-5 SP-Dash px-4" : "min-H container-fluid my-5 SP-Dash px-5"}>

                    <div className="d-flex justify-content-between w-100 align-items-center mb-3">
                        <span className="reset-bold text-capitalize"><NavLink className="reset_a" to={isAdmin ? "/admin/resources" : "/resources"}><i className="fas fa-arrow-left mx-3" />{t("admin.resources")}</NavLink></span>
                        {isAdmin && <button className="submit-button m-0 custom"><NavLink className="white" to={`/admin/resources/${id}/edit`}>{t("edit")}</NavLink></button>}
                    </div>

                    <div className="row">

                        <div className="col-md-3">

                            {/* resources image */}
                            <img alt="resources-image" className="img-fluid serv_img w-100" src={data.featured_image || "/img/example.png"} />

                            <div className="details_date">
                                {
                                    data.from
                                    &&
                                    <span className="mt-2 mb-2">{data.from.split(' ')[0]} - {data.to.split(' ')[0]}</span>
                                }

                                {
                                    data.to
                                    &&
                                    <span className="mx-3">{data.from.split(' ')[1]} - {data.to.split(' ')[1]}</span>
                                }

                            </div>

                        </div>
                        {/* resources details */}
                        <div className="col-md-9">

                            <div className="texts">
                                <p className="section-title mt-4 mt-md-0">{data.title}</p>
                                <p className="description">{data.description}</p>
                            </div>


                            {/* links */}
                            <div className="row no-gutters mb-5">

                                {data.links && data.links.length > 0 &&
                                    <>
                                        <h4 className='mb-3 pb-1 sub-title'>
                                            {t("admin.links")}
                                        </h4>
                                        {data.links.map(item => (
                                            <div className="col-12">
                                                <a href={item} className='text-break' target="_blank">{item}</a>
                                            </div>
                                        ))}
                                    </>
                                }
                            </div>

                            {/* files */}
                            <div className="row no-gutters">

                                {data.files && data.files.length > 0 &&
                                    <>
                                        <h4 className='mb-3 pb-1 sub-title'>
                                            {t("admin.files")}
                                        </h4>
                                        {data.files.map(item => (
                                            <div className="col-12" key={item.id}>
                                                <div className="file-box border px-3 py-2 mb-3 d-flex justify-content-between align-items-center">
                                                    <p className='m-0 text-break'>{item.title}</p>
                                                    <a target="_blank" href={item.url} download className="submit-button px-3 py-2 my-2 mx-3 white text-capitalize d-inline-block">{t("download")}</a>
                                                </div>
                                            </div>
                                        ))
                                        }
                                    </>

                                }
                            </div>

                        </div>

                    </div>

                </div >
            </div>
        </>
    )
}

export default Resources_details
