import React from 'react';

import Pager from '../../components/Pager';
import { useTranslation } from 'react-i18next';
import Filters from '../../components/Filters';
import axiosInstance from "../services/axiosInstance";

import ServiceProviderCard from '../../components/Cards/ServiceProviderCard';

function Service_providers_page() {

    const { t } = useTranslation();
    const Lang = localStorage.getItem("i18nextLng");

    // state
    const [page, setPage] = React.useState(1);
    const [basicFirst, setBasicFirst] = React.useState(0);
    const [perPage, setPerPage] = React.useState(10);
    const [isLoading, setIsLoading] = React.useState(true);
    const [fitlerValues, setFitlerValues] = React.useState([]);
    const [serviceProviderList, setServiceProviderList] = React.useState([]);
    const [totalServiceProviderList, setTotalServiceProviderList] = React.useState([]);

    const onBasicPageChange = (event) => {
        let eventPage = event.page + 1;

        setPage(eventPage);
        setBasicFirst(event.first);
        setPerPage(event.rows)
        loadServiceProviderList({ perPage: event.rows, page: eventPage })
    }

    function loadServiceProviderList(params = {}) {
        params.perPage = params.perPage || perPage;
        params.page = params.page || page;
        params = { ...params, ...fitlerValues }

        setIsLoading(true)
        axiosInstance().get(`/service-provider`, { params, 'headers': { 'locale': Lang } })
            .then((response) => populateServiceProviderList(response))

    }

    function populateServiceProviderList(response) {
        setIsLoading(false)
        setServiceProviderList(response.data.data.collection)
        setTotalServiceProviderList(response.data.data.pageInfo.total);
    }

    function filtersApplied(values) {
        setPage(1)
        setBasicFirst(0)
        setFitlerValues(values);
        loadServiceProviderList({ ...values, ...{ page: 1 } })
    }

    React.useEffect(() => {

        loadServiceProviderList()

    }, [Lang])


    return (
        <>
            <div className="position-relative">

                {/* loader */}
                {
                    isLoading
                    &&
                    <div className="text-center spinner-container">
                        <i className="fas fa-circle-notch fa-spin fa-4x"></i>
                    </div>
                }

                {/* filters */}
                <Filters hideServiceProviders={true} onSubmit={filtersApplied} except={['serviceProviders']}></Filters>

                <div className="min-H container-fluid my-5 SP-Dash px-5">

                    <h2 className="reset-bold mb-5 text-capitalize main-title-res">{t("service_providers")}</h2>
                    <div className="cards_list service_cards row">

                        {
                            serviceProviderList.length > 0
                                ?
                                serviceProviderList.map((item, i) => {
                                    return <div className="col-lg-3 col-md-4 col-sm-6 gallery-img-card mb-4" key={i}>
                                        <ServiceProviderCard
                                            serviceProvider={item}
                                        />
                                    </div>
                                })
                                :
                                <p className="lead mx-3 no-cont">{t("noContent")}</p>
                        }

                    </div>
                </div >
            </div>

            {/* pager */}
            {
                serviceProviderList.length ?
                    <Pager basicFirst={basicFirst} total={totalServiceProviderList} onPageChange={onBasicPageChange} />
                    : ''
            }
        </>
    )
}

export default Service_providers_page
