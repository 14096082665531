import axios from "axios";
import { Toast } from "primereact/toast";
import { useSelector } from "react-redux";

export default function (history = null) {

  const baseURL = "https://iom-p-we-webapp-cai-001.azurewebsites.net/index.php/api/";
  const domainURL = "https://compass.iom.int/";

  localStorage.setItem("baseURL", domainURL);

  let headers = {};

  if (localStorage.getItem("state") === null) {
    // window.location = "/login";
  } else {
    if (JSON.parse(localStorage.state).authReducer.access_token !== "") {
      headers["Authorization"] = `Bearer ${
        JSON.parse(localStorage.state).authReducer.access_token
      }`;
      // if (JSON.parse(localStorage.state).authReducer.user.email_verified !== true) {
      //   window.location = "/email/verify";
      // }
    }
    else {
      window.location = "/login";
    }
  }

  const axiosInstance = axios.create({
    baseURL: baseURL,
    headers,
  });

  axiosInstance.interceptors.response.use(
    (response) =>
      new Promise((resolve, reject) => {
        resolve(response);
      }),
    (error) => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // console.log(Object.values(error.response.data.errors));
        // console.log(error.response.status);
        // console.log(error.response.headers);
      }
      
      // console.log(error);
      if (!error.response) {
        //   const showError = () => {
        //     toast.current.show({severity:'error', summary: 'Error Message', detail:'Message Content', life: 3000});
        // }
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }

      if (error.response.status === 403) {

        window.location = "/error/403";

      } else if (error.response.status === 401) {
        // Unauthorized user
        // window.localStorage.clear();
        // if (history) {
        //   history.push("/login");
        // } else {
        //   window.location = "/login";
        // }

      } else if (error.response.status === 500) {

        window.location = "/error/500";

      } else if (error.response.status === 404) {

        if (window.location.href.indexOf("referrals") > -1) {
          // window.location = "/error/404";
        } else {
          window.location = "/error/404";
        }

      } else if (error.response.status === 410) {

        window.localStorage.clear();
        window.location = "/login";

      }
      else {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
    }
  );

  return axiosInstance;
}
