import * as actionTypes from "./actionTypes";

export const updatePageIndex = (n) => {
  return {
    type: actionTypes.REGISTER_PAGE,
    payload: n,
  };
};

export const addRegData = (n) => {
  return {
    type: actionTypes.ADD_REGISTER_DATA,
    payload: n,
  };
};

export const updateRegData = (n) => {
  return {
    type: actionTypes.UPDATE_REGISTER_DATA,
    payload: n,
  };
};

export const auth = (n) => {
  return {
    type: actionTypes.AUTH,
    payload: n,
  };
};

export const updateAuth = (n) => {
  return {
    type: actionTypes.UPAUTH,
    payload: n,
  };
};

export const updateAuthM = (n) => {
  return {
    type: actionTypes.UPAUTHM,
    payload: n,
  };
};

export const updateApp = (n) => {
  return {
    type: actionTypes.UPAPP,
    payload: n,
  };
};

export const logout = () => {
  return {
    type: actionTypes.LOGOUT,
  };
};