import React from 'react';

const Lang = localStorage.getItem("i18nextLng");

const EventCard = ({ event }) => {

    return (

        <a href={`/events/event/${event.id}`} className="d-block h-100">

            <div className="card service-card h-100">
                <div className="img-cont">
                    <img alt="card-image" className="cover card-img-top card-animate" src={event.featured_image || "/img/example.png"} />
                </div>
                <div className="pb-4">
                    <div className="card-body">
                        {/* <i className="fas fa-calendar-alt mx-1"></i> */}
                        {/* <p className="d-inline-block">{service.date}</p> */}
                        <h5 className="card-title">{event.title}</h5>
                    </div>

                    <p className={`d-inline-flex p-3 w-100 mb-0 position-absolute card-attrs ${Lang == 'ar' ? 'flex-row-reverse' : ''} justify-content-between`} style={{ "bottom": "0" }}><span className="d-inline-block" >{event.service_categories[0]['name']}</span><span className="d-inline-block" style={{ "textAlign": "start" }}>{event.service_provider.nickname}</span></p>
                </div>
            </div>
        </a>
    )
}

export default EventCard
