import React from 'react';
import * as Yup from "yup";
import { Toast } from 'primereact/toast';
import { useTranslation } from 'react-i18next';
import { Formik, Field, ErrorMessage } from "formik";
import axiosInstance from "../../services/axiosInstance";
import { updateAuthM } from '../../../store/actions/auth';
import { useDispatch } from 'react-redux';

function BasicInfo({ serviceProvider }) {

    const dispatch = useDispatch();
    const toast = React.useRef(null);
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = React.useState(false);
    const [initialValues, setInitialValues] = React.useState({
        name: serviceProvider.user.name,
        nickname: serviceProvider.nickname,
        email: serviceProvider.user.email,
        password: "",
        password_confirmation: "",
        phone: serviceProvider.user.phone,
        website: serviceProvider.website,
        facebook: serviceProvider.facebook,
        linkedin: serviceProvider.linkedin,
        instagram: serviceProvider.instagram,
        twitter: serviceProvider.twitter,
    })


    function handleSubmit(values) {

        setIsLoading(true)
        if (!values.password) {
            delete values.password;
        }

        axiosInstance().post("/service-provider/profile/first", values)
            .then(response => {
                values.password = ""
                values.password_confirmation = ""
                setInitialValues(values)
                dispatch(updateAuthM(response.data.data.user));
                toast.current.show({ severity: 'success', summary: t("success"), detail: t("successMsg"), life: 2000 });

                setTimeout(() => {
                    window.location.reload();
                }, 1000);

                setIsLoading(false)
            })
            .catch(err => {
                setIsLoading(false);
                toast.current.show({ severity: 'warn', summary: t("warn"), detail: Object.values(err.response.data.errors && err.response.data.errors) })
            });

    }

    // handleValidation
    function handleValidation() {
        return Yup.object().shape({
            name: Yup.string().required(t("req")),
            nickname: Yup.string().required(t("req")),
            email: Yup.string().required(t("req")),
            phone: Yup.string().matches(/^[0-9]+$/, t("mustNum")).min(11, t("minNum")).max(11, t("minNum")).required(t("req")),
            password: Yup.string().min(8, t("atLeast")),
            password_confirmation: Yup.string().oneOf([Yup.ref('password'), null], t("samePass")),
        })
    }


    function formBody({ handleSubmit }) {

        return (

            <form className="auth-form step_form mt-2" onSubmit={handleSubmit}>
                <div className="row mb-0 mb-md-4 no-gutters">

                    <div className="col-sm-4 mb-md-0 mb-3">
                        <label>{t("auth.register.step_1.fullName")}</label>
                        <Field
                            className="w-100 input-style"
                            name="name"
                            placeholder={t("auth.register.step_1.fullName")}
                        />
                        <div className="mt-2 text-danger">
                            <ErrorMessage name="name"></ErrorMessage>
                        </div>
                    </div>

                    <div className="col-sm-4 mb-md-0 mb-3 px-sm-3">
                        <label>{t("auth.register.step_1.shortName")}</label>
                        <Field
                            className="w-100 input-style"
                            name="nickname"
                            placeholder={t("auth.register.step_1.shortName_place")}
                        />
                        <div className="mt-2 text-danger">
                            <ErrorMessage name="nickname"></ErrorMessage>
                        </div>
                    </div>

                    <div className="col-sm-4 mb-md-0 mb-3">
                        <label>{t("auth.register.step_1.emailAddress")}</label>
                        <Field
                            className="w-100 input-style"
                            type="email"
                            name="email"
                            placeholder={t("auth.register.step_1.emailAddress")}
                        />
                        <div className="mt-2 text-danger">
                            <ErrorMessage name="email"></ErrorMessage>
                        </div>
                    </div>

                </div>

                <div className="row mb-0 mb-md-4 no-gutters">

                    <div className="col-sm-4 mb-md-0 mb-3">
                        <label>{t("auth.register.step_1.password")}</label>
                        <Field
                            className="w-100 input-style"
                            type="password"
                            name="password"
                            placeholder={t("auth.register.step_1.password")}
                        />
                        <div className="mt-2 text-danger">
                            <ErrorMessage name="password" />
                        </div>
                    </div>

                    <div className="col-sm-4 mb-md-0 mb-3 px-sm-3">
                        <label>{t("auth.register.step_1.confirmPass")}</label>
                        <Field
                            className="w-100 input-style"
                            type="password"
                            name="password_confirmation"
                            placeholder={t("auth.register.step_1.confirmPass")}
                        />
                        <div className="mt-2 text-danger">
                            <ErrorMessage name="password_confirmation" />
                        </div>
                    </div>

                    <div className="col-sm-4 mb-md-0 mb-3">
                        <label>{t("auth.register.step_1.phone")}</label>
                        <Field
                            className="w-100 input-style"
                            name="phone"
                            placeholder={t("auth.register.step_1.phone")}
                        />
                        <div className="mt-2 text-danger">
                            <ErrorMessage name="phone" />
                        </div>
                    </div>

                </div>

                <div className="row mb-0 mb-md-4 no-gutters">

                    <div className="col-sm-4 mb-md-0 mb-3">
                        <label>{t("auth.register.step_1.website")}</label>
                        <Field
                            className="w-100 input-style"
                            name="website"
                            placeholder={t("auth.register.step_1.website")}
                        />
                    </div>

                    <div className="col-sm-4 mb-md-0 mb-3 px-sm-3">
                        <label>{t("auth.register.step_1.facebook")}</label>
                        <Field
                            className="w-100 input-style"
                            name="facebook"
                            placeholder={t("auth.register.step_1.facebook")}
                        />
                    </div>

                    <div className="col-sm-4 mb-md-0 mb-3">
                        <label>{t("auth.register.step_1.linkedin")}</label>
                        <Field
                            className="w-100 input-style"
                            name="linkedin"
                            placeholder={t("auth.register.step_1.linkedin")}
                        />
                    </div>

                </div>

                <div className="row mb-0 mb-md-4 no-gutters">
                    <div className="col-sm-4 mb-md-0 mb-3">
                        <label>{t("auth.register.step_1.instagram")}</label>
                        <Field
                            className="w-100 input-style"
                            name="instagram"
                            placeholder={t("auth.register.step_1.instagram")}
                        />
                    </div>

                    <div className="col-sm-4 mb-md-0 mb-3 px-sm-3">
                        <label>{t("auth.register.step_1.twitter")}</label>
                        <Field
                            className="w-100 input-style"
                            name="twitter"
                            placeholder={t("auth.register.step_1.twitter")}
                        />
                    </div>

                </div>

                <div className="row justify-content-end px-5">
                    <button type="submit" className="next-button main-buttonII px-5 text-capitalize d-inline-block mx-auto mx-sm-0">{t("save")}</button>
                </div>

            </form >
        )
    }

    return (
        <div className="register">
            < Toast position="top-center" ref={toast} />
            {
                isLoading
                &&
                <div className="text-center spinner-container">
                    <i className="fas fa-circle-notch fa-spin fa-4x"></i>
                </div>
            }
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={handleValidation}
            >

                {formBody}

            </Formik>
        </div >
    )
}

export default BasicInfo;
