import React from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import Pager from '../../components/Pager';
import Filters from '../../components/Filters';
import ServiceCard from '../../components/Cards/ServiceCard';


function Services() {

    // loading state
    const [isLoading, setIsLoading] = React.useState(true);
    const [perPage, setPerPage] = React.useState(10);

    const { t } = useTranslation();

    const [servicesList, setServicesList] = React.useState([]);

    const [total, setTotal] = React.useState(0);
    const [page, setPage] = React.useState(1);
    const [basicFirst, setBasicFirst] = React.useState(0);
    const [filterValues, setFilterValues] = React.useState({});

    const Lang = localStorage.getItem("i18nextLng");

    const onBasicPageChange = (event) => {
        let eventPage = event.page + 1;
        setPerPage(event.rows);
        setPage(eventPage);
        setBasicFirst(event.first);

        setIsLoading(true);
        axios(`/service?page=${eventPage}&perPage=${event.rows}`, { 'params': filterValues, 'headers': { 'locale': Lang } })
            .then((response) => {
                setTotal(response.data.data.pageInfo.total);
                setServicesList(response.data.data.collection);
                setIsLoading(false)
            })
            .catch((err) => {
                // Handle Error Here
                setIsLoading(false);

            });
    }

    const search = (searchValues) => {
        setIsLoading(true)
        setBasicFirst(0)
        setFilterValues(searchValues);
        axios(`/service?perPage=${perPage}`, { 'params': searchValues, 'headers': { 'locale': Lang } })
            .then((response) => {
                setTotal(response.data.data.pageInfo.total);
                setPage(1);
                setServicesList(response.data.data.collection);
                setIsLoading(false)

                // setPageInfo(resp.data.data.pageInfo)
            })
            .catch((err) => {
                // Handle Error Here
                setIsLoading(false);

            });
    }

    React.useEffect(() => {

        setIsLoading(true)

        axios(`/service?perPage=10`, { 'headers': { 'locale': Lang } })
            .then((response) => {
                setTotal(response.data.data.pageInfo.total);
                setServicesList(response.data.data.collection);
                setIsLoading(false)
            })
            .catch((error) => {
                setIsLoading(false)
            })

    }, [Lang])


    return (
        <>
            <div className="position-relative">
                {
                    isLoading
                    &&
                    <div className="text-center spinner-container">
                        <i className="fas fa-circle-notch fa-spin fa-4x"></i>
                    </div>
                }

                <Filters except={['serviceProviders']} onSubmit={search}></Filters>
                <div className="min-H container-fluid my-5 SP-Dash px-5">

                    <h2 className="reset-bold mb-5 text-capitalize main-title-res">{t("services")}</h2>
                    <div className="cards_list service_cards row">

                        {
                            servicesList.length > 0
                                ?
                                servicesList.map((item, i) => {
                                    return <div className="col-lg-3 col-md-4 col-sm-6 gallery-img-card mb-4" key={i}>
                                        <ServiceCard
                                            service={item}
                                        />
                                    </div>
                                })
                                :
                                <p className="lead mx-3 no-cont">{t("noContent")}</p>
                        }

                    </div>
                </div >
            </div>
            <Pager total={total} basicFirst={basicFirst} onPageChange={onBasicPageChange} className={`${servicesList.length ? '' : 'd-none'}`} />
        </>
    )
}

export default Services
