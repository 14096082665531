import React from 'react'
import BasicInfo from "./BasicInfo";
import Locations from "./Locations";
import FocalPoints from "./FocalPoints";
import { useTranslation } from 'react-i18next';
import { TabView, TabPanel } from 'primereact/tabview';
import axiosInstance from "../../services/axiosInstance";
import AdditionalInfo from "./AdditionalInfo";

function Account() {

    const { t } = useTranslation();
    const Lang = localStorage.getItem("i18nextLng");
    const [activeIndex, setActiveIndex] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(false);
    const [serviceProvider, setServiceProvider] = React.useState({});

    const tabs = [
        {
            "header": t('auth.register.step_1.title'),
            "renderable": <BasicInfo serviceProvider={serviceProvider}></BasicInfo>,
        },
        {
            "header": t('auth.register.step_2.title'),
            "renderable": <Locations serviceProvider={serviceProvider}></Locations>,
        },
        {
            "header": t('auth.register.step_4.title'),
            "renderable": <FocalPoints></FocalPoints>,
        },
        {
            "header": t('auth.register.step_3.title'),
            "renderable": <AdditionalInfo serviceProvider={serviceProvider}></AdditionalInfo>,
        },
    ]

    React.useEffect(() => {

        setIsLoading(true)

        axiosInstance().get(`/service-provider/profile`, { 'headers': { 'locale': Lang } })
            .then((response) => {
                setServiceProvider(response.data.data)
                setIsLoading(false)
            })
    }, [Lang])

    return (
        <div className='p-relative' id="sp-account-details">
            {
                isLoading
                &&
                <div className="text-center spinner-container">
                    <i className="fas fa-circle-notch fa-spin fa-4x"></i>
                </div>
            }
            <div className="min-H container-fluid SP-Dash">

                {
                    Object.keys(serviceProvider).length
                        ?
                        <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                            {
                                tabs.map((tab, i) => (
                                    <TabPanel key={i} header={tab.header} >
                                        {tab.renderable}
                                    </TabPanel>
                                ))
                            }
                        </TabView>
                        :
                        ""
                }

            </div>
        </div>
    )
}

export default Account
