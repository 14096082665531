import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'primereact/dropdown';

export default function LanguageSwitcher() {

    const { t, i18n } = useTranslation();

    const Lang = localStorage.getItem("i18nextLng");
    const [selectedLang, setSelectedLang] = React.useState(Lang || "en");


    function changeLanguage(e) {
        setSelectedLang(e.value.code);
        i18n.changeLanguage(e.value.code);
        // document.lang = i18n.dir(e.value.code);
       document.documentElement.lang = e.value.code
       document.documentElement.dir = e.value.code == "en" ? "ltr" : "rtl"
    };

    const Langs = [
        { name: 'English', code: 'en' },
        { name: 'العربية', code: 'ar' }
    ];


    return (
        <div>
            <Dropdown value={selectedLang} options={Langs} onChange={changeLanguage} optionLabel="name" placeholder={selectedLang == "en" ? "English" : "العريبة"} />
            <i className="fas fa-chevron-down"></i>
        </div>
    )
}
