import * as actionTypes from "../actions/actionTypes";

const TempReducer = (state = [], action) => {
  switch (action.type) {
    
    case actionTypes.UPDATE_TEMP:
      state = [...state, ...action.payload]
      return state;
      
      case actionTypes.CLEAR_TEMP:
        state = []
        return state;
        
      case actionTypes.UPDATE_TAGS:
        state = [...action.payload]
        return state;

    default:
      return state;
  }
};

export default TempReducer;
