import React, { Suspense } from "react";
import "../../assets/Sass/style.scss";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
} from "react-router-dom";
import Home from "../Home";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import Authentication from "../Authentication";
import axios from "axios";
import Loading from "../../components/Spinner";
import Dashboard from "../Service_Provider/Dashboard";
import Account from "../Service_Provider/Account";
import { useDispatch, useSelector } from "react-redux";
import { setIsLoading } from "../../store/actions/Loading";
import "primereact/resources/primereact.min.css";
import ServiceProviders from "../Service_providers_page";
import Services from "../Services_page";
import Service from "../Service_page";
import ReferralForm from "../Referral/ReferralForm";
import Referrals from "../Referral/Referrals";
import ReferralFormView from "../Referral/ReferralForm/View";
import AdditionE from "../Service_Provider/Dashboard/components/Addition/Event";
import AdditionS from "../Service_Provider/Dashboard/components/Addition/Service";
import ServiceProviderPage from "../Service_provider_page";
import ErrorPage from "../ErrorPage";
import G_Events from "../Events_page";
import G_Event from "../Event_page";
import EditE from "../Service_Provider/Dashboard/components/Edit/Event";
import EditS from "../Service_Provider/Dashboard/components/Edit/Service";
import MigrantAcc from "../MigrantAcc";
import News from "../Admin/containers/News";
import Resources from "../Admin/containers/Resources";
import News_details from "../Admin/components/News_details";
import News_control from "../Admin/components/News_control";
import Resources_details from "../Admin/components/Resources_details";
import Resources_control from "../Admin/components/Resources_control";
import Sidebar from "../../components/Sidebar";
import Requests from "../Admin/containers/Requests";
import Statistics from "../Admin/containers/Statistics";
import Pending from "../../containers/Pending";
import CookieConsent from "react-cookie-consent";
import { useTranslation } from 'react-i18next';

function App() {

  const { t, i18n } = useTranslation();

  // set base url  
  axios.defaults.baseURL = "https://https://iom-p-we-webapp-cai-001.azurewebsites.net/index.php/api/";

  const Lang = localStorage.getItem("i18nextLng");

  document.documentElement.lang = Lang || "en";
  document.documentElement.dir = Lang == "ar" ? "rtl" : "ltr"

  const LoadingReducer = useSelector((state) => state.LoadingReducer);
  const dispatch = useDispatch();

  const role = useSelector(state => state.authReducer.user.role !== "" ? state.authReducer.user.role : "guest");

  React.useEffect(() => {

    window.scrollTo(0, 0)
    if (LoadingReducer) {
      dispatch(setIsLoading(false));
    }

  }, []);

  return (
    <Suspense fallback={<Loading />}>

      <Router>

        <Route path="/" component={Navbar} />

        <Switch>

          {
            LoadingReducer
            &&
            <div className="text-center spinner-container">
              <i className="fas fa-circle-notch fa-spin fa-4x"></i>
            </div>
          }

          <Route exact path="/" component={Home} />


          {/* Authentication pages */}
          <Route path="/login"
            render={() => <Authentication type="login" />}
          />

          <Route
            path="/register"
            render={() => <Authentication type="register" />}
          />

          {/*-- global pages --*/}

          {/* services routes */}
          <Route
            exact
            path="/services"
            render={() => <Services />}
          />

          <Route
            path="/services/service/:id"
            render={() => <Service />}
          />


          {/* events routes */}
          <Route
            exact
            path="/events"
            render={() => <G_Events />}
          />

          <Route
            path="/events/event/:id"
            render={() => <G_Event />}
          />

          <Route
            exact
            path="/service-provider/:id"
            render={() => <ServiceProviderPage isAdmin={false} />}
          />

          <Route
            path="/service-providers"
            render={() => <ServiceProviders />}
          />

          <Route
            exact
            path="/news"
            render={() => <News isAdmin={false} />}
          />

          <Route
            exact
            path="/news/:id"
            render={() => <News_details isAdmin={false} />}
          />

          <Route
            exact
            path="/resources"
            render={() => <Resources isAdmin={false} />}
          />

          <Route
            exact
            path="/resources/:id"
            render={() => <Resources_details isAdmin={false} />}
          />

          <Route
            path="/error/:id"
            render={() => <ErrorPage />}
          />

          <Route
            path="/pending"
            render={() => <Pending />}
          />


          {/*--- service provider routes ---*/}
          {role === "service_provider"
            &&
            <>
              {/*-- Referrals routes --*/}

              <Route
                exact
                path="/referrals"
                render={() => <Referrals />}
              />
              <Route
                path="/referrals/new"
                render={() => <ReferralForm />}
              />
              <Route
                exact
                path="/referral/:id"
                render={({ match }) => <ReferralFormView id={match.params.id} />}
              />

              {/*-- dashboard --*/}

              <Route exact path="/service-provider/dashboard" component={Dashboard} />
              <Route exact path="/service-provider/dashboard/:type" component={Dashboard} />


              {/*- dash services -*/}
              <Route
                path="/service-provider/dashboard/services/addition"
                render={() => <AdditionS />}
              />
              <Route
                path="/service-provider/dashboard/services/:id/edit"
                render={() => <EditS />}
              />

              {/*- dash events -*/}
              <Route
                path="/service-provider/dashboard/events/addition"
                render={() => <AdditionE />}
              />
              <Route
                path="/service-provider/dashboard/events/:id/edit"
                render={() => <EditE />}
              />

              <Route path="/service-provider/account/edit" component={Account} />

            </>
          }

          {/* migrant routes */}
          {
            role === "migrant"
            &&
            <Route
              path="/migrant/account"
              render={() => <MigrantAcc />}
            />
          }

          {/* admin routes */}
          {
            role === "admin"
            &&

            <div className="min-H container-fluid SP-Dash admin-cont">

              <div className="row">
                <div className="col-2 admin-SB">
                  <Sidebar />
                </div>
                <div className="col-10 px-0">

                  <Switch>

                    <Route
                      exact
                      path="/admin/statistics"
                      render={() => <Statistics />}
                    />

                    <Route
                      exact
                      path="/admin/news"
                      render={() => <News isAdmin={true} />}
                    />

                    <Route
                      exact
                      path="/admin/news/add"
                      render={() => <News_control isNew={true} />}
                    />

                    <Route
                      exact
                      path="/admin/news/:id"
                      render={() => <News_details isAdmin={true} />}
                    />

                    <Route
                      exact
                      path="/admin/news/:id/edit"
                      render={() => <News_control isNew={false} />}
                    />

                    {/* resources */}
                    <Route
                      exact
                      path="/admin/resources"
                      render={() => <Resources isAdmin={true} />}
                    />

                    <Route
                      exact
                      path="/admin/resources/add"
                      render={() => <Resources_control isNew={true} />}
                    />

                    <Route
                      exact
                      path="/admin/resources/:id"
                      render={() => <Resources_details isAdmin={true} />}
                    />

                    <Route
                      exact
                      path="/admin/resources/:id/edit"
                      render={() => <Resources_control isNew={false} />}
                    />

                    {/* SP */}

                    <Route
                      exact
                      path="/admin/service-providers"
                      render={() => <ServiceProviders />}
                    />

                    <Route
                      exact
                      path="/admin/service-provider/:id"
                      render={() => <ServiceProviderPage isAdmin={false} />}
                    />

                    <Route
                      exact
                      path="/admin/service-providers/requests"
                      render={() => <Requests />}
                    />

                    <Route
                      exact
                      path="/admin/service-provider/requests/:id"
                      render={() => <ServiceProviderPage isAdmin={true} />}
                    />


                  </Switch>

                </div>
              </div>

            </div>

          }

          {
            role === "guest"
            &&
            <div className="min-H container-fluid SP-Dash admin-cont" />
          }

        </Switch>

        <Route path="/" component={Footer} />

      </Router>

      <CookieConsent
        buttonText={t("IU")}
        containerClasses="justify-content-center"
        contentClasses="m-0 cookiesContent"
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
      >
        {t("consentText")}
      </CookieConsent>
    </Suspense >
  );
}

export default App;




