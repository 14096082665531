export const AUTH = "AUTH";
export const REGISTER_PAGE = "REGISTER_PAGE";
export const ADD_REGISTER_DATA = "ADD_REGISTER_DATA";
export const UPDATE_REGISTER_DATA = "UPDATE_REGISTER_DATA";
export const UPAUTH = "UPAUTH";
export const UPAUTHM = "UPAUTHM";
export const UPAPP = "UPAPP";

export const UPDATE_TEMP = "UPDATE_TEMP";
export const CLEAR_TEMP = "CLEAR_TEMP";
export const UPDATE_TAGS = "UPDATE_TAGS";

export const LOGOUT = "LOGOUT";
export const IS_LOADING = "IS_LOADING";

// SERVICE
export const GET_SERVICE = "GET_SERVICE";