import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { Formik, Field, ErrorMessage } from "formik";
import { useSelector, useDispatch } from "react-redux";
import axiosInstance from "../../../../../services/axiosInstance";
import FormikControl from "../../../../../../components/formCompoment/FormikControl";
import { Toast } from 'primereact/toast';
import { clearTemp } from "../../../../../../store/actions/temp";
import * as Yup from "yup";
import MainInfo from "../MainInfo";
import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import moment from "moment";

function AdditionE() {

    const Lang = localStorage.getItem("i18nextLng");
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const toast = React.useRef(null);

    // loading state
    const [isLoading, setIsLoading] = React.useState(true);

    const [error, setError] = React.useState(false);

    const [contentLang, setContentLang] = React.useState("en");

    // form ref
    const formRef = React.useRef();

    let initialValues = {

        featured_image: "",
        title: "",
        phone: "",
        addition_phone: "",
        service_categories: [],
        other_service: "",
        description: "",
        asylum_types: [],
        from: "",
        to: "",
        bookable: 0,

        locations: [
            {
                governorate_id: "",
                district_id: "",
                street: "",
                building: "",
                floor: "",
                lat: "",
                long: ""
            }
        ],

        images: [
        ],

        videos: [
        ],

    };

    // langs options
    const [langsList, setLangsList] = useState([
        {
            id: "en",
            name: "English",
        },
        {
            id: "ar",
            name: "العربية",
        }
    ]);

    const [servicesList, setServicesList] = React.useState([
        {
            id: "",
            name: "",
        },
    ]);


    const [asylumList, setAsylumList] = useState([
        {
            id: "",
            name: "",
        },
    ]);

    let list = JSON.parse(localStorage.getItem("event_target")) || [];
    let listII = JSON.parse(localStorage.getItem("event_services")) || [];
    const TempReducer = useSelector((state) => state.TempReducer);

    function handleChange(e, name) {

        switch (name) {

            case "service_categories":
                listII = [...listII, e.target.value];
                listII = [...new Set(listII)];
                localStorage.setItem("event_services", JSON.stringify(listII))
                formRef.current.setFieldValue(name, listII)
                break;

            case "asylum_types":
                list = [...list, e.target.value];
                list = [...new Set(list)];
                localStorage.setItem("event_target", JSON.stringify(list))
                formRef.current.setFieldValue(name, list)
                break;

            case "language":
                setContentLang(e.target.value)
                break;

            case "from":
            case "to":
                formRef.current.setFieldValue(name, `${moment(e.value).format('L')} ${moment(e.value).format('LT')}`)
                break;

            default:
                formRef.current.setFieldValue(name, e.target.value);
                break;
        }
    }

    React.useEffect(() => {
        setIsLoading(true)

        axiosInstance().get("/attached-service-categories", { 'headers': { 'locale': Lang } })
            .then((response) => {
                setServicesList(response.data.data);
                document.querySelectorAll("select")[4].disabled = false;
            })
            .catch((error) => {
                console.error(error);
            });

        axiosInstance().get(`/attached-asylum-types`, { 'headers': { 'locale': Lang } })
            .then((response) => {
                setAsylumList(response.data.data);
                setIsLoading(false);
                document.querySelectorAll("select")[5].disabled = false;
            })
            .catch((error) => {
                setIsLoading(false);
            });

        dispatch(clearTemp());
        localStorage.removeItem("event_target")
        localStorage.removeItem("event_services")

    }, [Lang]);

    const serviceCategoriesInput = React.useRef(null);
    const asylumTypesInput = React.useRef(null);

    function handleRemove(form, i, name) {


        if (name == "asylum_types") {

            list.splice(i, 1);
            localStorage.setItem("event_target", JSON.stringify(list))
            form.setFieldValue(name, [...list])

            if (list.length == 0) {
                asylumTypesInput.current.value = 0;
            }

        } else {

            listII.splice(i, 1);
            localStorage.setItem("event_services", JSON.stringify(listII))
            form.setFieldValue(name, [...listII])

            if (listII.length == 0) {
                serviceCategoriesInput.current.value = 0;
            }

        }

    }

    function formBody({ handleSubmit, values }) {
        return (
            <form className="auth-form step_form" onSubmit={handleSubmit}>

                <div className="row no-gutters justify-content-between">
                    <h3 className="addition-title">{t(`addevent`)}</h3>

                    <div className="d-inline-block mx-4 mb-3 mb-xs-0">

                        <select
                            className="w-100 input-style"
                            style={{ marginTop: "1px" }}
                            onChange={(e) =>
                                handleChange(e, "language", "language")
                            }
                        >

                            {langsList.map((type) => (
                                <option value={type.id}>{type.name}</option>
                            ))}
                        </select>

                    </div>

                    <button
                        type="submit"
                        className="addition-submit next-button py-3 main-buttonII px-5 text-capitalize d-inline-block"
                    >
                        {t("submit")}
                    </button>
                </div>

                <div className="addS_cont">

                    <MainInfo formRef={formRef} type="event" />

                    <div className="row">
                        <div className="col-md-4 row no-gutters">
                            <div className="col-6 p-2">
                                <label className="form-label">{t("images")}</label>

                                <FormikControl
                                    control='photo'
                                    name={`images[${values.images.length}]`}
                                    value={"/img/example.png"}
                                    className="header-images"
                                />
                            </div>

                            <div className="col-6 p-2">
                                <label className="form-label">{t("videos")}</label>
                                <FormikControl
                                    control='video'
                                    name={`videos[${values.videos.length}]`}
                                    className="upload-video"
                                />
                            </div>

                        </div>
                        <div className="col-md-8 col-offset-4">
                            <div className="row px-2">
                                <div className="col-sm-6">
                                    <label>{t("startDate")}</label>

                                    <Field name="from">
                                        {({ form }) => (
                                            <DateTimePickerComponent strictMode={true} step={15} onChange={(e) => handleChange(e, "from")} />
                                        )}
                                    </Field>
                                    <div className="mt-2 text-danger">
                                        <ErrorMessage name="from"></ErrorMessage>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <label>{t("endDate")}</label>

                                    <Field name="to">
                                        {({ form }) => (
                                            <DateTimePickerComponent strictMode={true} step={15} onChange={(e) => handleChange(e, "to")} />
                                        )}
                                    </Field>
                                    <div className="mt-2 text-danger">
                                        <ErrorMessage name="to"></ErrorMessage>
                                    </div>
                                </div>
                            </div>
                            <div className="row px-2 pt-2">

                                <div className="col-sm-6">
                                    <label>{t("auth.register.step_3.col_2.org")}</label>
                                    <Field name="service_categories">
                                        {({ form }) => (
                                            <>
                                                <select
                                                    disabled
                                                    className="w-100 input-style"
                                                    style={{ marginTop: "1px" }}
                                                    name="service_categories"
                                                    ref={serviceCategoriesInput}
                                                    onChange={(e) => handleChange(e, "service_categories")}
                                                >
                                                    <option value="" disabled selected>
                                                        {t("auth.register.step_3.col_2.org_place")}
                                                    </option>

                                                    {servicesList.map((service) => (
                                                        <option value={service.id}>{service.name}</option>
                                                    ))}
                                                </select>

                                                {
                                                    values.service_categories.length > 0
                                                    &&
                                                    <ul className="mt-2 px-0">
                                                        {
                                                            values.service_categories.map((item, i) => {
                                                                return (
                                                                    servicesList.length && servicesList.map((service) => (
                                                                        service.id == parseInt(item)
                                                                        &&
                                                                        <li className="tag_style"><span className="cursor-pointer" onClick={() => handleRemove(form, i, "service_categories")}>x</span> &nbsp;{service.name}</li>
                                                                    ))
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                }
                                            </>
                                        )}
                                    </Field>
                                    <Field
                                        className="w-100 input-style mt-3"
                                        name="other_service"
                                        placeholder={t("otherS")}
                                    />
                                    <div className="mt-2 text-danger">
                                        <ErrorMessage name="service_categories" />
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <label>{t("auth.register.step_3.col_2.target")}</label>
                                    <Field
                                        name="asylum_types"
                                        placeholder={t("auth.register.step_3.col_2.target_place")}
                                    >
                                        {({ form }) => (
                                            <>
                                                <select
                                                    disabled
                                                    ref={asylumTypesInput}
                                                    className="w-100 input-style"
                                                    style={{ marginTop: "1px" }}
                                                    name="asylum_types"
                                                    onChange={(e) => handleChange(e, "asylum_types")}
                                                >
                                                    <option value="" disabled selected>
                                                        {t("auth.register.step_3.col_2.target_place")}
                                                    </option>

                                                    {asylumList.map((asylum) => (
                                                        <option value={asylum.id}>{asylum.name}</option>
                                                    ))}
                                                </select>
                                                {
                                                    values.asylum_types.length > 0
                                                    &&
                                                    <ul className="mt-2 px-0">
                                                        {
                                                            values.asylum_types.map((item, i) => {
                                                                return (
                                                                    asylumList.length && asylumList.map((asylum) => (
                                                                        asylum.id == parseInt(item)
                                                                        &&
                                                                        <li className="tag_style"><span className="cursor-pointer" onClick={() => handleRemove(form, i, "asylum_types")}>x</span> &nbsp;{asylum.name}</li>
                                                                    ))
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                }
                                            </>
                                        )}
                                    </Field>
                                    <div className="mt-2 text-danger">
                                        <ErrorMessage name="asylum_types" />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <div className="row px-2 pt-3">

                                {values.images.length > 0

                                    &&

                                    values.images.map((item, index) => (
                                        <img alt="featured-image" className="col-4 p-2" src={`${URL.createObjectURL(item)}`} />
                                    ))
                                }

                                {values.videos.length > 0

                                    &&

                                    values.videos.map((item, index) => (
                                        <video className="col-4 p-2" src={`${URL.createObjectURL(item)}`} />
                                    ))
                                }

                            </div>
                        </div>
                        <div className="col-8">
                            <label className="m-2">{t('bookable')}</label>
                            <Field>
                                {({ form }) => (
                                    <input type="checkbox"
                                        id="CheckID"
                                        name="secret"
                                        onChange={() => {
                                            form.setFieldValue('bookable', document.getElementById("CheckID").checked ? 1 : 0)
                                        }
                                        }
                                    />
                                )}
                            </Field>
                        </div>
                    </div>


                </div>

            </form>
        )
    }

    function handleSubmit(values) {

        setIsLoading(true);

        values.locations = [...TempReducer, ...values.locations]

        const formData = new FormData();

        for (const [key, val] of Object.entries(values)) {

            if (val !== null || val !== "") {

                switch (key) {

                    case "images":
                        values[key].map((file, i) => {
                            formData.append(`images[${i}][image]`, file);
                        })
                        break;

                    case "videos":
                        values[key].map((file, i) => {
                            formData.append(`videos[${i}][video]`, file);
                        })
                        break;

                    case "service_categories":
                        listII.map((option, i) => {
                            formData.append(`service_categories[${i}]`, option);
                        })
                        break;

                    case "asylum_types":
                        list.map((option, i) => {
                            formData.append(`asylum_types[${i}]`, option);
                        })
                        break;

                    case "locations":
                        val.map((v, i) => {
                            for (const [key2, val2] of Object.entries(v)) {
                                if (val2) {
                                    formData.append(`locations[${i}][${key2}]`, val2);
                                }
                            }
                        });
                        break;

                    case "from":
                    case "to":
                        !val.includes("Invalid")
                            &&
                            formData.append(key, val)
                        break;

                    case "phone":
                        formData.append('phones[0]', val);
                        break;

                    case "addition_phone":
                        val
                            ?
                            formData.append('phones[1]', val)
                            :
                            formData.delete('phones[1]')

                        break;

                    default:
                        formData.append(key, val);
                        break;
                }
            }

        }

        axiosInstance().post(`/event`, formData, { 'headers': { 'locale': contentLang } })
            .then(res => {
                setError(false);
                setIsLoading(false);
                toast.current.show({ severity: 'success', summary: t("success"), detail: t("successMsg"), life: 2000 });
                dispatch(clearTemp());
            })
            .catch(err => {
                setIsLoading(false);
                setError(true);
                if (err.response !== null & err.response !== undefined) {
                    err.response.data.errors
                        ?
                        toast.current.show({ severity: 'warn', summary: t("warn"), detail: Object.values(err.response.data.errors) })
                        :
                        toast.current.show({ severity: 'warn', summary: t("warn"), detail: t("tryLater") })
                } else {
                    toast.current.show({ severity: 'warn', summary: t("warn"), detail: t("tryLater") })
                }

                values.locations && values.locations.length > 1
                    &&
                    values.locations.splice(0, 1);
            })
    }

    // handleValidation
    function handleValidation() {
        const schema = Yup.object().shape({

            title: Yup.string().required(t("req")),
            phone: Yup.string().matches(/^[0-9]+$/, t("mustNum")).min(11, t("minNum")).max(11, t("minNum")).required(t("req")),
            addition_phone: Yup.string().matches(/^[0-9]+$/, t("mustNum")).min(11, t("minNum")).max(11, t("minNum")),
            service_categories: Yup.array().min(1, t("req")),
            description: Yup.string().required(t("req")),
            asylum_types: Yup.array().min(1, t("req")),

            locations: Yup.array()
                .of(
                    Yup.object().shape({
                        governorate_id: Yup.string().required(t("required")),
                        district_id: Yup.string().required(t("required")),
                        street: Yup.string().required(t("required")),
                        building: Yup.string().required(t("required")),
                    })

                )

        })
        return schema;
    }


    return (
        <div className="position-relative">
            {
                isLoading
                &&
                <div className="text-center spinner-container">
                    <i className="fas fa-circle-notch fa-spin fa-4x"></i>
                </div>
            }
            <div className="addition-page min-H">

                {
                    error
                        ?
                        < Toast position="top-center" ref={toast} />
                        :
                        < Toast position="top-center" ref={toast} onRemove={() => window.location.href = "/service-provider/dashboard/events"} />
                }

                <div className="container-fluid px-md-5 my-5">

                    <Formik validationSchema={handleValidation} innerRef={formRef} initialValues={initialValues} onSubmit={handleSubmit}>
                        {formBody}
                    </Formik>

                </div>
            </div>
        </div >
    )
}

export default AdditionE
