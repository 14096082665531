import React, { useEffect } from 'react';
import ServiceCard from "../../../../../components/Cards/ServiceCard";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import axiosInstance from '../../../../services/axiosInstance';
import Pager from '../../../../../components/Pager';

function Services() {

    const { t } = useTranslation();

    // loading state
    const [isLoading, setIsLoading] = React.useState(true);

    const ID = useSelector((state) => state.authReducer.service_provider_id);

    const [servicesList, setServicesList] = React.useState([]);

    const Lang = localStorage.getItem("i18nextLng");

    const [Total, setTotal] = React.useState(0);


    useEffect(() => {

        axiosInstance().get(`/service?serviceProviderId=${ID}`, { 'headers': { 'locale': Lang } })
            .then((res) => {
                setServicesList(res.data.data.collection);
                setIsLoading(false);
                setTotal(res.data.data.pageInfo.total);

            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false)
            })

    }, [])


    const onBasicPageChange = (event) => {
        setIsLoading(true)

        axiosInstance().get(`/service?serviceProviderId=${ID}&page=${event.page + 1}&perPage=${event.rows}`, { 'headers': { 'locale': Lang } })
            .then(res => {
                setIsLoading(false)
                setServicesList(res.data.data.collection);
                setTotal(res.data.data.pageInfo.total);
            }).catch(err => {
                console.log(err);
                setIsLoading(false)

            }
            )
    }

    return (
        <>
            {
                isLoading
                &&
                <div className="text-center spinner-container">
                    <i className="fas fa-circle-notch fa-spin fa-4x"></i>
                </div>
            }
            <div className="py-4">
                <div className="cards_list service_cards row">

                    {
                        servicesList.length > 0
                            ?
                            servicesList.map((item, i) => {
                                return <div key={i} className="col-lg-3 col-md-4 col-sm-6 gallery-img-card mb-4">
                                    <ServiceCard
                                        service={item}
                                    />
                                </div>
                            })
                            :
                            <p className="lead mx-3 no-cont">{t("noContent")}</p>
                    }

                </div>
            </div>

            {servicesList.length > 0 && <Pager total={Total} onPageChange={onBasicPageChange} />}

        </>

    )
}

export default Services
