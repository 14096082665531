import React, { useState, useEffect } from 'react';
import { Formik, Field, ErrorMessage, Form } from "formik";
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { auth } from '../../store/actions/auth';
import { useSelector, useDispatch } from "react-redux";
import { Toast } from 'primereact/toast';

function M_Register() {

    const { t, i18n } = useTranslation();

    let initialValues = {

        name: "",
        username: "",
        email: "",

        id_number: "",
        password: "",
        password_confirmation: "",

        phone: "",
        additional_phone: "",
        country_id: "",

        age: "",
        asylum_type_id: "",
        gender: "",

        secret: 0


    }

    // make the actions on the state
    const dispatch = useDispatch();

    const toast = React.useRef(null);

    // language
    const Lang = localStorage.getItem("i18nextLng");

    // loading state
    const [isLoading, setIsLoading] = React.useState(true);

    const [countriesList, setCountriesList] = useState([
        {
            id: "",
            name: "",
        },
    ]);

    const [asylumList, setAsylumList] = useState([
        {
            id: "",
            name: "",
        },
    ]);

    const [genderList, setGenderList] = useState([
        {
            id: "male",
            name: t("male"),
        },
        {
            id: "female",
            name: t("female"),
        },
        {
            id: "other",
            name: t("other"),
        }
    ]);

    useEffect(() => {

        axios("/countries", { 'headers': { 'locale': Lang } })
            .then((response) => {
                setCountriesList(response.data.data);
                document.querySelectorAll("select")[0].disabled = false;
            })
            .catch((error) => {
                console.log(error);
            });

        axios(`/asylum-types`, { 'headers': { 'locale': Lang } })
            .then((response) => {
                setAsylumList(response.data.data);
                setIsLoading(false);
                document.querySelectorAll("select")[1].disabled = false;
            })
            .catch((error) => {
                setIsLoading(false)
            });

    }, [Lang]);

    function handleSubmit(values) {

        setIsLoading(true)

        // create formdata
        let formData = new FormData();

        for (const [key, val] of Object.entries(values)) {
            formData.append(key, val);
        }

        axios
            .post("/register/migrant", formData)
            .then((response) => {
                setIsLoading(false)
                dispatch(auth(response.data.data));
                window.location.href = "/";
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false)
                toast.current.show({ severity: 'warn', summary: t("warn"), detail: Object.values(err.response.data.errors && err.response.data.errors) })
            });
    }

    function handleChange(e, form, name) {
        form.setFieldValue(name, e.target.value)
    }

    function FormBody({ handleSubmit }) {

        return (

            <Form className="auth-form step_form" onSubmit={handleSubmit}>

                <div className="title">{t("migrant")}</div>

                {/* first */}
                <div className="row mb-0 mb-md-4 no-gutters">

                    <div className="col-sm-4 mb-md-0 mb-3">
                        <label>{t("auth.register.step_1.fullName")}</label>
                        <Field

                            className="w-100 input-style"
                            name="name"
                            placeholder={t("auth.register.step_1.fullName")}
                        />
                        <div className="mt-2 text-danger">
                            <ErrorMessage name="name" />
                        </div>

                    </div>

                    <div className="col-sm-4 mb-md-0 mb-3 px-sm-3">
                        <label>{t("auth.login.user")}</label>
                        <Field

                            className="w-100 input-style"
                            name="username"
                            placeholder={t("auth.login.user")}
                        />
                        <div className="mt-2 text-danger">
                            <ErrorMessage name="username" />
                        </div>
                    </div>

                    <div className="col-sm-4 mb-md-0 mb-3">
                        <label>{t("auth.register.step_1.emailAddressOp")}</label>
                        <Field
                            className="w-100 input-style"
                            type="email"
                            name="email"
                            placeholder={t("auth.register.step_1.emailAddressOp")}
                        />
                    </div>

                </div>

                {/* second */}
                <div className="row mb-0 mb-md-4 no-gutters">

                    <div className="col-sm-4 mb-md-0 mb-3">
                        <label>{t("auth.register.step_1.password")}</label>
                        <Field

                            className="w-100 input-style"
                            type="password"
                            name="password"
                            placeholder={t("auth.register.step_1.password")}
                        />
                        <div className="mt-2 text-danger">
                            <ErrorMessage name="password" />
                        </div>
                    </div>

                    <div className="col-sm-4 mb-md-0 mb-3 px-sm-3">
                        <label>{t("auth.register.step_1.confirmPass")}</label>
                        <Field

                            className="w-100 input-style"
                            type="password"
                            name="password_confirmation"
                            placeholder={t("auth.register.step_1.confirmPass")}
                        />
                        <div className="mt-2 text-danger">
                            <ErrorMessage name="password_confirmation" />
                        </div>
                    </div>

                    <div className="col-sm-4 mb-md-0 mb-3">
                        <label>{t("auth.register.step_1.ID")}</label>
                        <Field

                            className="w-100 input-style"
                            name="id_number"
                            placeholder={t("auth.register.step_1.ID")}
                        />
                        <div className="mt-2 text-danger">
                            <ErrorMessage name="id_number" />
                        </div>
                    </div>

                </div>

                {/* third */}
                <div className="row mb-0 mb-md-4 no-gutters">

                    <div className="col-sm-4 mb-md-0 mb-3">
                        <label>{t("auth.register.step_1.phone")}</label>
                        <Field

                            className="w-100 input-style"
                            name="phone"
                            placeholder={t("auth.register.step_1.phone")}
                        />
                        <div className="mt-2 text-danger">
                            <ErrorMessage name="phone" />
                        </div>

                    </div>

                    <div className="col-sm-4 mb-md-0 mb-3 px-sm-3">
                        <label>{t("auth.register.step_1.phone_op")}</label>
                        <Field
                            className="w-100 input-style"
                            name="additional_phone"
                            placeholder={t("auth.register.step_1.phone_op")}
                        />
                        <div className="mt-2 text-danger">
                            <ErrorMessage name="additional_phone" />
                        </div>
                    </div>

                    <div className="col-sm-4 mb-md-0 mb-3">
                        <label>{t("auth.register.step_1.country")}</label>
                        <Field name="country_id">
                            {({ form }) => (
                                <select

                                    className="w-100 input-style"
                                    style={{ marginTop: "1px" }}
                                    name="country_id"
                                    onChange={(e) =>
                                        handleChange(e, form, "country_id")
                                    }
                                >
                                    <option value="" disabled selected>
                                        {t("auth.register.step_1.country")}
                                    </option>

                                    {countriesList.map((country, i) => (
                                        <option key={i} value={country.id}>{country.name}</option>
                                    ))}
                                </select>
                            )}
                        </Field>

                        <div className="mt-2 text-danger">
                            <ErrorMessage name="country_id" />
                        </div>
                    </div>

                </div>

                {/* fourth */}
                <div className="row mb-0 mb-md-4 no-gutters">


                    <div className="col-sm-4 mb-md-0 mb-3">
                        <label>{t("auth.register.step_1.age")}</label>
                        <Field

                            className="w-100 input-style"
                            name="age"
                            placeholder={t("auth.register.step_1.age")}
                        />
                        <div className="mt-2 text-danger">
                            <ErrorMessage name="age" />
                        </div>
                    </div>

                    <div className="col-sm-4 mb-md-0 mb-3 px-sm-3">
                        <label>{t("SYC")}</label>
                        <Field name="asylum_type_id">
                            {({ form }) => (
                                <select

                                    className="w-100 input-style"
                                    style={{ marginTop: "1px" }}
                                    name="asylum_type_id"
                                    onChange={(e) =>
                                        handleChange(e, form, "asylum_type_id")
                                    }
                                >
                                    <option value="" disabled selected>
                                        {t("auth.register.step_1.type")}
                                    </option>

                                    {asylumList.map((type, i) => (
                                        <option key={i} value={type.id}>{type.name}</option>
                                    ))}
                                </select>
                            )}
                        </Field>
                        <div className="mt-2 text-danger">
                            <ErrorMessage name="asylum_type_id" />
                        </div>
                    </div>

                    <div className="col-sm-4 mb-md-0 mb-3">
                        <label>{t("auth.register.step_1.gender")}</label>
                        <Field name="gender">
                            {({ form }) => (
                                <select

                                    className="w-100 input-style"
                                    style={{ marginTop: "1px" }}
                                    name="gender"
                                    onChange={(e) =>
                                        handleChange(e, form, "gender")
                                    }
                                >
                                    <option value="" disabled selected>
                                        {t("auth.register.step_1.gender")}
                                    </option>

                                    {genderList.map((type, i) => (
                                        <option key={i} value={type.id}>{type.name}</option>
                                    ))}
                                </select>
                            )}
                        </Field>
                        <div className="mt-2 text-danger">
                            <ErrorMessage name="gender" />
                        </div>
                    </div>

                    <div className="col-12 d-flex align-items-center">
                        <label className="m-2">{t('keep_secret')}</label>
                        <Field>
                            {({ form }) => (
                                <input type="checkbox"
                                    id="CheckID"
                                    name="secret"
                                    onChange={() => {
                                        form.setFieldValue('secret', document.getElementById("CheckID").checked ? 1 : 0)
                                    }
                                    }
                                />
                            )}
                        </Field>
                    </div>
                </div>

                <div className="row form-controls">

                    {
                        isLoading
                        &&
                        <div className="p-2 pb-0">
                            <i className="fas fa-circle-notch fa-spin fa-2x"></i>
                        </div>
                    }

                    <a href="/login" className="alreadyReg d-flex align-items-center">{t("auth.register.already")}</a>
                    <button type="submit" className="next-button main-buttonII px-5 text-capitalize d-inline-block">{t("next")}</button>

                </div>

            </Form >
        )
    }

    // handleValidation
    function handleValidation() {
        const schema = Yup.object().shape({

            username: Yup.string().required(t("req")),
            name: Yup.string().required(t("req")),

            password: Yup.string().required(t("req")),
            password_confirmation: Yup.string().required(t("req")).oneOf([Yup.ref('password'), null], t("notSame")),

            phone: Yup.string().matches(/^[0-9]+$/, t("mustNum")).min(11, t("minNum")).max(11, t("minNum")).required(t("req")),
            additional_phone: Yup.string().matches(/^[0-9]+$/, t("mustNum")).min(11, t("minNum")).max(11, t("minNum")),

            country_id: Yup.string().required(t("req")),

            age: Yup.string().required(t("req")),
            asylum_type_id: Yup.string().required(t("req")),
            gender: Yup.string().required(t("req")),

        })
        return schema;
    }

    return (
        <div className="register">

            < Toast position="top-center" ref={toast} />

            <Formik
                initialValues={initialValues}

                onSubmit={handleSubmit}

                // validation schema
                validationSchema={handleValidation}
            >

                {FormBody}

            </Formik>

        </div >
    )
}

export default M_Register;
