import * as Yup from "yup";
import React from "react";
import { Toast } from 'primereact/toast';
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import axiosInstance from "../../services/axiosInstance";
import FocalPoint from '../../../components/formCompoment/FocalPoint';

function FocalPoints() {

  // language
  const Lang = localStorage.getItem("i18nextLng");

  const { t } = useTranslation();

  const formRef = React.useRef();
  const toast = React.useRef(null);
  // loading state
  const [isLoading, setIsLoading] = React.useState(false);
  const localStorageState = JSON.parse(localStorage.getItem('state'))
  const authReducer = (localStorageState && localStorageState.authReducer) ? localStorageState.authReducer : null;

  const [initialValues, setInitialValues] = React.useState({
    'focal_points': []
  })


  function handleSubmit(values) {

    setIsLoading(true);
    axiosInstance().post("/service-provider/profile/focal", values)
      .then(response => {
        toast.current.show({ severity: 'success', summary: t("success"), detail: t("successMsg"), life: 2000 });
        setIsLoading(false)
      })
      .catch(err => {
        setIsLoading(false);
        toast.current.show({ severity: 'warn', summary: t("warn"), detail: Object.values(err.response.data.errors && err.response.data.errors) })
      });
  }

  React.useEffect(() => {

    setIsLoading(true);
    axiosInstance().get(`/service-provider/${authReducer.service_provider_id}/focal`, { 'headers': { 'locale': Lang } })
      .then((response) => {
        setInitialValues({ 'focal_points': response.data.data });
        setIsLoading(false)
      })
  }, [Lang]);

  /**
     * removeFocal
     * call from Focal component props.onRemove
     * @param {integer} i index of Focal section
     * @return void
     */
  function removeFocal(i) {
    let values = { ...initialValues };
    values.focal_points.splice(i, 1);

    setInitialValues(values)
    formRef.current.setFieldTouched('focal_points', true)
  }

  function addFocal() {
    if (!("focal_points" in formRef.current.errors)) {
      let values = { ...initialValues };
      values.focal_points.push({});

      setInitialValues(values)
      formRef.current.setFieldTouched('focal_points', true)
    }
  }

  // handleValidation
  function handleValidation() {
    const schema = Yup.object().shape({

      focal_points: Yup.array()
        .of(
          Yup.object().shape({
            name: Yup.string().required(t("required")),
            phone: Yup.string().required(t("required")).min(11, t("minNum")).max(11, t("minNum")),
            email: Yup.string().required(t("required")),
            position: Yup.string().required(t("required")),
          })

        )

    })
    return schema;
  }

  function formBody({ handleSubmit }) {
    return (
      <form className="auth-form step_form" onSubmit={handleSubmit}>

        {/* Focal Points */}
        <div className="row">
          <div className="col-12">
            <p className="sub-title h3 mb-4">{t("auth.register.step_4.title")}</p>
          </div>
          {
            initialValues.focal_points.length
              ?
              initialValues.focal_points.map((focalPoint, i) =>
                <FocalPoint
                  key={i}
                  i={i}
                  arrayName='focal_points'
                  onRemove={removeFocal}
                ></FocalPoint>
              )
              :
              ""
          }

        </div>


        <div className="row justify-content-end px-5">
          <button type="button" onClick={addFocal} className="mx-auto mx-sm-0 mb-3 mb-sm-0 reset_button px-4 main-color text-capitalize d-inline-block m-0">{t("addAnother")}</button>

          <button
            type="submit"
            className="next-button main-buttonII px-5 text-capitalize d-inline-block mx-auto mx-sm-0"
          >
            {t("save")}
          </button>
        </div>
      </form>
    );
  }

  return (
    <div className="register">
      <Toast position="top-center" ref={toast} />
      {
        isLoading
        &&
        <div className="text-center spinner-container">
          <i className="fas fa-circle-notch fa-spin fa-4x"></i>
        </div>
      }
      <Formik enableReinitialize innerRef={formRef} initialValues={initialValues} onSubmit={handleSubmit} validationSchema={handleValidation}>
        {formBody}
      </Formik>
    </div>
  );
}

export default FocalPoints;
