import React from 'react';

export default function VideoPlaceholder(props) {

    const [src, setSrc] = React.useState(props.src)

    return (
        <div className="video-placeholder">
            <div className="overlay" onClick={() => { props.onClick() }}>
                {/* TODO FIX ICON FONT */}
                <i className="pi pi-times"></i>
            </div>
            <video width="100%">
                <source src={src}></source>
            </video>
        </div>
    )
}

