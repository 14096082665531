import React from 'react';
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { updatePageIndex, addRegData } from '../../../store/actions/auth';
import axios from "axios";
import { Toast } from 'primereact/toast';


function Step_one() {

    // make the actions on the state
    const dispatch = useDispatch();

    const { t } = useTranslation();

    // loading state
    const [isLoading, setIsLoading] = React.useState(false);

    let initialValues = {
        name: "",
        nickname: "",
        email: "",
        password: "",
        password_confirmation: "",
        phone: "",
        website: "",
        facebook: "",
        linkedin: "",
        instagram: "",
        twitter: ""
    }

    const toast = React.useRef(null);

    function handleSubmit(values) {

        setIsLoading(true)

        axios.post("/register/service-provider/first-step", values)
            .then(response => {
                setIsLoading(false)
                dispatch(addRegData(values));
                dispatch(updatePageIndex(2));
                window.location.reload();
            })
            .catch(err => {
                setIsLoading(false);
                toast.current.show({ severity: 'warn', summary: t("warn"), detail: Object.values(err.response.data.errors && err.response.data.errors) })
            });

    }

    // handleValidation
    function handleValidation() {
        const schema = Yup.object().shape({
            phone: Yup.string().matches(/^[0-9]+$/, t("mustNum")).min(11, t("minNum")).max(11, t("minNum")).required(t("req")),
            password: Yup.string().min(8, t("atLeast")),
            password_confirmation: Yup.string().oneOf([Yup.ref('password'), null], t("samePass")),
        })
        return schema;
    }


    function formBody({ handleSubmit }) {

        return (

            <form className="auth-form step_form" onSubmit={handleSubmit}>

                <div className="title">{t("auth.register.step_1.title")}</div>

                <div className="row mb-0 mb-md-4 no-gutters">

                    <div className="col-sm-4 mb-md-0 mb-3">
                        {/* <label>{t("auth.register.step_1.fullName")}</label> */}
                        <Field
                            required
                            className="w-100 input-style"
                            name="name"
                            placeholder={t("auth.register.step_1.fullName")}
                        />
                    </div>

                    <div className="col-sm-4 mb-md-0 mb-3 px-sm-3">
                        {/* <label>{t("auth.register.step_1.shortName")}</label> */}
                        <Field
                            required
                            className="w-100 input-style"
                            name="nickname"
                            placeholder={t("auth.register.step_1.shortName_place")}
                        />
                    </div>

                    <div className="col-sm-4 mb-md-0 mb-3">
                        {/* <label>{t("auth.register.step_1.emailAddress")}</label> */}
                        <Field
                            required
                            className="w-100 input-style"
                            type="email"
                            name="email"
                            placeholder={t("auth.register.step_1.emailAddress")}
                        />
                    </div>

                </div>

                <div className="row mb-0 mb-md-4 no-gutters">

                    <div className="col-sm-4 mb-md-0 mb-3">
                        {/* <label>{t("auth.register.step_1.password")}</label> */}
                        <Field
                            required
                            className="w-100 input-style"
                            type="password"
                            name="password"
                            placeholder={t("auth.register.step_1.password")}
                        />
                        <div className="mt-2 text-danger">
                            <ErrorMessage name="password" />
                        </div>
                    </div>

                    <div className="col-sm-4 mb-md-0 mb-3 px-sm-3">
                        {/* <label>{t("auth.register.step_1.confirmPass")}</label> */}
                        <Field
                            required
                            className="w-100 input-style"
                            type="password"
                            name="password_confirmation"
                            placeholder={t("auth.register.step_1.confirmPass")}
                        />
                        <div className="mt-2 text-danger">
                            <ErrorMessage name="password_confirmation" />
                        </div>
                    </div>

                    <div className="col-sm-4 mb-md-0 mb-3">
                        {/* <label>{t("auth.register.step_1.website")}</label> */}
                        <Field
                            required
                            className="w-100 input-style"
                            name="phone"
                            placeholder={t("auth.register.step_1.phone")}
                        />
                        <div className="mt-2 text-danger">
                            <ErrorMessage name="phone" />
                        </div>
                    </div>

                </div>

                <div className="row mb-0 mb-md-4 no-gutters">

                    <div className="col-sm-4 mb-md-0 mb-3">
                        {/* <label>{t("auth.register.step_1.website")}</label> */}
                        <Field
                            className="w-100 input-style"
                            name="website"
                            placeholder={t("auth.register.step_1.website")}
                        />
                    </div>

                    <div className="col-sm-4 mb-md-0 mb-3 px-sm-3">
                        {/* <label>{t("auth.register.step_1.facebook")}</label> */}
                        <Field
                            className="w-100 input-style"
                            name="facebook"
                            placeholder={t("auth.register.step_1.facebook")}
                        />
                    </div>

                    <div className="col-sm-4 mb-md-0 mb-3">
                        {/* <label>{t("auth.register.step_1.linkedin")}</label> */}
                        <Field
                            className="w-100 input-style"
                            name="linkedin"
                            placeholder={t("auth.register.step_1.linkedin")}
                        />
                    </div>

                </div>

                <div className="row mb-0 mb-md-4 no-gutters">
                    <div className="col-sm-4 mb-md-0 mb-3">
                        {/* <label>{t("auth.register.step_1.instagram")}</label> */}
                        <Field
                            className="w-100 input-style"
                            name="instagram"
                            placeholder={t("auth.register.step_1.instagram")}
                        />
                    </div>

                    <div className="col-sm-4 mb-md-0 mb-3 px-sm-3">
                        {/* <label>{t("auth.register.step_1.twitter")}</label> */}
                        <Field
                            className="w-100 input-style"
                            name="twitter"
                            placeholder={t("auth.register.step_1.twitter")}
                        />
                    </div>

                </div>

                <div className="row form-controls">

                    {
                        isLoading
                        &&
                        <div className="p-2 pb-0">
                            <i className="fas fa-circle-notch fa-spin fa-2x"></i>
                        </div>
                    }

                    <a href="/login" className="alreadyReg d-flex align-items-center">{t("auth.register.already")}</a>
                    <button type="submit" className="next-button main-buttonII px-5 text-capitalize d-inline-block">{t("next")}</button>

                </div>



            </form >
        )
    }

    return (
        <div className="register">
            < Toast position="top-center" ref={toast} />
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={handleValidation}
            >

                {formBody}

            </Formik>
        </div >
    )
}

export default Step_one;
