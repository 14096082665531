import React from 'react';
import { useSelector } from 'react-redux';
import Login from '../../components/Login';
import Register from '../../components/Register';


function Authentication({ type }) {

    const access_token = useSelector((state) => state.authReducer.access_token);

    React.useEffect(() => {
        if (access_token) {
            window.location = "/"
        }
    }, []);

    function specifiedForm() {
        switch (type) {

            case "login":
                return <Login />;

            case "register":
                return <Register />;

            default:
                break;
        }
    }


    return (
        <div className="auth-container">
            <img src="/img/auth-bg.jpg" alt="background" />
            <div className="container position-relative auth-wrapper py-5">
                <h3 className="auth-head text-center">
                    <a href="/">
                        <img src="/img/home/logo.svg" alt="logo" />
                    </a>
                </h3>
                {specifiedForm()}
            </div>
        </div >
    )
}

export default Authentication;