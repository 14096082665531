import React, { useEffect } from 'react'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { Field } from 'formik';
import { useSelector } from 'react-redux';

function Map(props) {

    const TempReducer = useSelector((state) => state.TempReducer);


    const containerStyle = {
        width: '100%',
        height: '150px'
    };

    const center = {
        lat: 30.0609,
        lng: 31.2197
    };


    const { name, ...rest } = props

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyB24nXRdcfIdCul_TaPjZoW5y09tob4VMo"
    })

    const [map, setMap] = React.useState(null)


    useEffect(() => {
        !props.lat
            &&
            setMarkers([])
    }, [TempReducer])

    const onLoad = React.useCallback(function callback(map) {

        const bounds = new window.google.maps.LatLngBounds();
        map.fitBounds(bounds);

        setMarkers([
            {
                lat: parseFloat(props.lat),
                lng: parseFloat(props.long)
            }
        ])
        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    function googleMap(form = null) {
        return <GoogleMap
            mapContainerStyle={containerStyle}
            center={{
                lat: props.lat ? parseFloat(props.lat) : 30.0609,
                lng: props.long ? parseFloat(props.long) : 31.2197
            }}
            zoom={0}
            onLoad={onLoad}
            onUnmount={onUnmount}
            onClick={(e) => {
                if (form) {
                    setMarkers([{
                        lat: e.latLng.lat(),
                        lng: e.latLng.lng()
                    }]);
                    form.setFieldValue(name[0], e.latLng.lat());
                    form.setFieldValue(name[1], e.latLng.lng());
                }
            }
            }
        >
            {
                markers.map((marker, i) => <Marker key={i} position={{ lat: marker.lat, lng: marker.lng }} />)
            }
        </GoogleMap>
    }

    const [markers, setMarkers] = React.useState([]);

    return isLoaded ? (
        typeof name != 'undefined' ?
            <Field>
                {({ form }) => (
                    googleMap(form)
                )}
            </Field>
            :
            googleMap()

    ) : <></>
}

export default React.memo(Map)