import React from 'react'
import { Field } from 'formik'
import { getIn } from "formik";


function Input(props) {

    const { label, type, typePos, qIndex,qType,required,disabled,
        name, className, errors, touched, placeholder, showErrorOnTouch = true, ...rest } = props
    let errorMessage = "" ;
    let errorMessageTouched = false ;
    if (qType) {
        errorMessage = getIn(errors, `Questions.${qIndex}.title`);
    } else {
        errorMessage = getIn(errors, name);
        errorMessageTouched = getIn(touched, name);
    }
    const scrollTo = (ref) => {
        if (ref && ref.current /* + other conditions */) {
          ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
      }
      
    return (

        <div className={` form-control d-flex flex-wrap ${className !== undefined ? className : ""}`} style={{"gap":"10px"}}>
            {label ? <label className="form-label" htmlFor="">{label}</label> : null}
            <Field className="field" id={name} name={name} placeholder={placeholder} {...rest}
                render={({ field, form }) => (
                    <input {...field}
                    // disabled={disabled}
                        placeholder={placeholder}
                        value={field.value !== "null" ? field.value : ""}
                        onChange={(event) => {
                            form.setFieldValue(name, event.target.value)
                            form.setFieldValue(typePos, type)
                        }}
                    />
                )}
            />
            { errorMessage && <span ref={scrollTo} className="error custom-error-msg w-100">{errorMessage}</span>}
        </div>
    )
}
export default Input
