import React from 'react';
import { useTranslation } from 'react-i18next';

function Pending() {

    const { t } = useTranslation();

    return (
        <div className="pending">
            <div className="min-H container-fluid SP-Dash admin-cont d-flex align-items-center justify-content-center">
                <h3 className='sub-title'>
                    {t("pending")}
                </h3>
            </div>
        </div>
    );
}

export default Pending
