import React from 'react';
import { useParams } from 'react-router';
import axiosInstance from "../../../services/axiosInstance";
import { useTranslation } from 'react-i18next';
import MediaView from '../../../../components/MediaView';
import VideoPlaceholder from '../../../../components/VideoPlaceholder';
import { NavLink } from 'react-router-dom';

function News_details({ isAdmin }) {

    const { id } = useParams();

    // loading state
    const [isLoading, setIsLoading] = React.useState(true);

    const { t } = useTranslation();

    const [newsMediaIndex, setEventMediaIndex] = React.useState(0);
    const [newsImages, setNewsImages] = React.useState([]);
    const [newsVideos, setNewsVideos] = React.useState([]);
    const [newsMedia, setEventMedia] = React.useState([]);

    const [news, setEvent] = React.useState([]);
    const [galleryIsOpen, setGalleryIsOpen] = React.useState(false);

    const Lang = localStorage.getItem("i18nextLng");


    React.useEffect(() => {

        setIsLoading(true)

        axiosInstance().get(`/news/${id}`, { 'headers': { 'locale': Lang } })
            .then((response) => {
                setEvent(response.data.data);
                setNewsImages(response.data.data.images);
                setNewsVideos(response.data.data.videos);
                setEventMedia({
                    ...response.data.data.images.map(img => img.url).concat(...response.data.data.videos.map(video =>
                        <iframe title="event-media" className="d-flex flex-center items-center" src={video.url}></iframe>
                    ))
                });
                setIsLoading(false)
            })
            .catch((error) => {
                setIsLoading(false)
            })

    }, [Lang])

    return (
        <>
            {/* videos and images popup */}
            <MediaView handleChange={(index) => setEventMediaIndex(index)} index={newsMediaIndex} handleClose={(isOpen) => setGalleryIsOpen(isOpen)} media={newsMedia} isOpen={galleryIsOpen}></MediaView>

            <div className="position-relative">
                {
                    isLoading
                    &&
                    <div className="text-center spinner-container">
                        <i className="fas fa-circle-notch fa-spin fa-4x"></i>
                    </div>
                }

                <div className={isAdmin ? "min-H pt-5 SP-Dash px-4" : "min-H container-fluid my-5 SP-Dash px-5"}>

                    <div className="d-flex justify-content-between w-100 align-items-center mb-3">
                        <span className="reset-bold text-capitalize"><NavLink className="reset_a" to={isAdmin ? "/admin/news" : "/news"}><i className="fas fa-arrow-left mx-3" />{t("admin.news")}</NavLink></span>
                        {isAdmin && <button className="submit-button m-0 custom"><NavLink className="white" to={`/admin/news/${id}/edit`}>{t("edit")}</NavLink></button>}
                    </div>

                    <div className="row">

                        <div className="col-md-3">

                            {/* news image */}
                            <img alt="news-image" className="img-fluid serv_img w-100" src={news.featured_image || "/img/example.png"} />

                            <div className="details_date">
                                {
                                    news.from
                                    &&
                                    <span className="mt-2 mb-2">{news.from.split(' ')[0]} - {news.to.split(' ')[0]}</span>
                                }

                                {
                                    news.to
                                    &&
                                    <span className="mx-3">{news.from.split(' ')[1]} - {news.to.split(' ')[1]}</span>
                                }

                            </div>

                        </div>
                        {/* news details */}
                        <div className="col-md-9">

                            <div className="texts">
                                <p className="section-title mt-4 mt-md-0">{news.title}</p>
                                <p className="description">{news.description}</p>
                            </div>

                            {/* links */}
                            <div className="row no-gutters mb-5">

                                {news.links && news.links.length > 0 &&
                                    <>
                                        <h4 className='mb-3 pb-1 sub-title'>
                                            {t("admin.links")}
                                        </h4>
                                        {news.links.map(item => (
                                            <div className="col-12">
                                                <a href={item} className='text-break' target="_blank">{item}</a>
                                            </div>
                                        ))}
                                    </>
                                }
                            </div>

                            {/* media */}
                            {
                                newsImages.length || newsVideos.length
                                    ?
                                    <>
                                        <p className="sub-title mt-5">{t("videos_photos")}</p>
                                        <div className="row">
                                            {
                                                newsImages.length > 0 && newsImages.map((img, i) =>
                                                    <div className="col-md-3 col-6 mb-3" key={i}>
                                                        <img alt="media-image" className="img-fluid w-100 img-h" style={{ "cursor": "pointer" }} src={img.url} onClick={() => { setEventMediaIndex(i); setGalleryIsOpen(true); }} />
                                                    </div>

                                                )
                                            }
                                            {
                                                newsVideos.length > 0 && newsVideos.map((video, i) =>
                                                    <div className="col-md-3 col-6 mb-3" key={i} onClick={() => { setGalleryIsOpen(true); setEventMediaIndex(i + newsImages.length) }}>
                                                        <VideoPlaceholder src={video.url}></VideoPlaceholder>
                                                    </div>

                                                )
                                            }
                                        </div>
                                    </>
                                    :
                                    ""
                            }
                        </div>

                    </div>

                </div >
            </div>
        </>
    )
}

export default News_details
