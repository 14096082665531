import React from 'react';

const ServiceProviderCard = ({ serviceProvider }) => {

    return (

        <a href={`/service-provider/${serviceProvider.id}`} className="d-block h-100">

            <div className="card service-card h-100">
                <div className="img-cont">
                    <img alt="card-image" className="cover card-img-top card-animate" src={serviceProvider.logo || "/img/example.png"} />
                </div>
                <div className="pb-4">
                    <div className="card-body">
                        <h5 className="card-title">{serviceProvider.user.name}</h5>
                    </div>
                </div>
            </div>
        </a>
    )
}

export default ServiceProviderCard
