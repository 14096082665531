import React from 'react';
import { useParams } from 'react-router';
import Map from "../../components/Map";
import axiosInstance from "../services/axiosInstance";
import EventCard from '../../components/Cards/EventCard';

import { Toast } from 'primereact/toast';
import { useTranslation } from 'react-i18next';

import ServiceCard from '../../components/Cards/ServiceCard';

function ServiceProviderPage({ isAdmin }) {

    const { id } = useParams();
    const toast = React.useRef(null);

    // loading state
    const [isLoading, setIsLoading] = React.useState(true);
    const { t } = useTranslation();

    const [servicesList, setServicesList] = React.useState([]);
    const [eventsList, setEventsList] = React.useState([]);

    // const [eventList, setEventList] = React.useState([]);
    const [serviceProvider, setServiceProvider] = React.useState({
        "user": {},
    });


    const Lang = localStorage.getItem("i18nextLng");

    // handle submit
    function handleClick(e, id) {

        setIsLoading(true);

        axiosInstance().post(`/service-provider/${e ? "approve" : "reject"}/${id}`)
            .then((res) => {
                setIsLoading(false);;
                toast.current.show({ severity: 'success', summary: t("success"), detail: t("successMsg"), life: 2000 });
                setTimeout(() => {
                    window.location.href = "/admin/service-providers/requests"
                }, 1000);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
                toast.current.show({ severity: 'warn', summary: t("warn"), detail: Object.values(err.response.data.errors && err.response.data.errors) })
            })
    }

    function workingHours() {
        let days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
        let data = [];
        days.forEach(day => {
            data.push({
                "day": t(`days.${day}`),
                "hours": serviceProvider.working_hours && serviceProvider.working_hours[day].from ? `${serviceProvider.working_hours[day].from} - ${serviceProvider.working_hours[day].to}` : t('closed'),
            })
        });

        return data;
    }

    React.useEffect(() => {

        setIsLoading(true)

        axiosInstance().get(`/service-provider/${id}`, { 'headers': { 'locale': Lang } })
            .then((response) => {
                setServiceProvider(response.data.data)
                setServicesList(response.data.data.services.collection);
                setEventsList(response.data.data.events.collection);
                setIsLoading(false)
            })
    }, [Lang])


    return (
        <>
            <div className="position-relative">
                <Toast position="top-center" ref={toast} />

                {
                    isLoading
                    &&
                    <div className="text-center spinner-container">
                        <i className="fas fa-circle-notch fa-spin fa-4x"></i>
                    </div>
                }

                <div className="min-H container-fluid custom-container my-5 px-5">
                    <div></div>
                    <div className="row">
                        <div className="col-md-3">
                            {/* service image */}
                            <img alt="service-image" className="img-fluid serv_img w-100" src={serviceProvider.logo || "/img/example.png"} />

                            {/* social links */}
                            <div className="mt-3 d-flex justify-content-center">
                                {serviceProvider.facebook && <a target="_blank" href={serviceProvider.facebook}><i className="pi pi-facebook mx-2 text-primary"></i></a>}
                                {serviceProvider.twitter && <a target="_blank" href={serviceProvider.twitter}><i className="pi pi-twitter mx-2 text-primary"></i></a>}
                                {serviceProvider.linkedin && <a target="_blank" href={serviceProvider.linkedin}><i className="fab fa-linkedin mx-2 text-primary"></i></a>}
                                {serviceProvider.instagram && <a target="_blank" href={serviceProvider.instagram}><i className="fab fa-instagram mx-2 text-primary"></i></a>}
                            </div>

                            {/* working hours */}
                            <div className="mt-4">
                                {
                                    workingHours().map((item) => (
                                        <div className="row mt-1">
                                            <div className="col-6 text-start">{item.day}</div>
                                            <div className="col-6 text-primary text-end">{item.hours}</div>
                                        </div>
                                    )
                                    )
                                }
                            </div>

                        </div>
                        {/* service details */}
                        <div className="col-md-9">

                            <p className="section-title mt-4 mt-md-0">{serviceProvider.user.name} ({serviceProvider.nickname})</p>
                            {/* service categories */}

                            <div className="noMarginParent">
                                {serviceProvider && serviceProvider.service_categories &&
                                    serviceProvider.service_categories.map((serviceCategory, i) =>
                                        <span className="badge badge-pill badge-primary service-category-small py-2 px-3 mx-2 my-2" key={i}>{serviceCategory.name}</span>
                                    )
                                }
                            </div>

                            <div className="mt-3 noMarginParent">
                                {serviceProvider && serviceProvider.asylum_types &&
                                    serviceProvider.asylum_types.map((asylumType, i) =>
                                        <span className="badge badge-pill badge-primary service-category-small py-2 px-3 mx-2 my-2" key={i}>{asylumType.name}</span>
                                    )
                                }
                            </div>

                            <p className="mt-5 description">{serviceProvider.description}</p>

                            <label className="font-weight-bold d-block mt-4 mb-2">{t("contact")}</label>
                            {serviceProvider.user.phone && <p className="text-primary"><i className="pi pi-phone"></i> {serviceProvider.user.phone}</p>}
                            {serviceProvider.user.email && <p className="text-primary"><i className="pi pi-envelope"></i> {serviceProvider.user.email}</p>}
                            {serviceProvider.website && <p className="text-primary"><i className="pi pi-globe"></i> <a target="_blank" href={serviceProvider.website}>{serviceProvider.website}</a></p>}

                            <label className="font-weight-bold d-block mt-4 mb-2">{t("locationT")}</label>

                            {
                                serviceProvider.locations && serviceProvider.locations.map(location =>
                                    <>
                                        <p className="text-primary"><i className="pi pi-map-marker"></i> {`${location.building} ${location.street} ${location.floor || ""} - ${location.district.name} ${location.governorate.name}`}</p>

                                        <div className="col-12 p-2">
                                            <Map lat={location.lat} long={location.long} />
                                        </div>
                                    </>
                                )
                            }

                        </div>
                    </div>


                    {
                        servicesList && servicesList.length > 0 ?
                            <>
                                <p className="h3 my-4 text-capitalize sub-title" style={{ "color": "#393939" }}>{t("services")}</p>
                                <div className="cards_list service_cards mb-5 row">

                                    {

                                        servicesList.map((item, i) => {
                                            return <div className="col-lg-3 col-md-4 col-sm-6 gallery-img-card mb-4" key={i}>
                                                <ServiceCard
                                                    service={item}
                                                />
                                            </div>
                                        })
                                    }

                                </div>
                            </>
                            : ''
                    }

                    {
                        eventsList && eventsList.length > 0 ?
                            <>
                                <p className="h3 my-4 text-capitalize sub-title" style={{ "color": "#393939" }}>{t("events")}</p>
                                <div className="cards_list event_cards mb-5 row">

                                    {

                                        eventsList.map((item, i) => {
                                            return <div className="col-lg-3 col-md-4 col-sm-6 gallery-img-card mb-4" key={i}>
                                                <EventCard
                                                    event={item}
                                                />
                                            </div>
                                        })
                                    }

                                </div>
                            </>
                            : ''
                    }


                    {
                        isAdmin
                        &&
                        <div className="mt-3 justify-content-end d-flex">
                            <button type="button" onClick={() => handleClick(1, serviceProvider.id)} className="submit-button my-auto mx-3 custom text-capitalize d-inline-block px-2 py-1">{t("admin.approve")}</button>
                            <button type="button" onClick={() => handleClick(0, serviceProvider.id)} className="reset-button text-capitalize d-inline-block">{t("admin.reject")}</button>
                        </div>
                    }


                </div>
            </div>
        </>
    )
}

export default ServiceProviderPage
