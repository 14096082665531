// import i18n from 'i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';
// import { pt, en } from './locales';

// const options = {
//   interpolation: {
//     escapeValue: false, // not needed for react!!
//   },

//   debug: true,

//   // lng: 'en',

//   resources: {
//     pt: {
//       common: pt['pt-BR'],
//     },
//     en: {
//       common: en.en,
//     },
//   },

//   fallbackLng: 'en',

//   ns: ['common'],

//   defaultNS: 'common',

//   react: {
//     wait: false,
//     bindI18n: 'languageChanged loaded',
//     bindStore: 'added removed',
//     nsMode: 'default'
//   },
// };

// i18n
//   .use(LanguageDetector)
//   .init(options)
//   .changeLanguage('en', (err, t) => {
//     if (err) return console.log('something went wrong loading', err);
//   });

// export default i18n;
import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';


i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    react: {
      useSuspense: false
    },
    fallbackLng: localStorage.getItem("i18nextLng") || 'en',
    debug: false,
    lng: localStorage.getItem("i18nextLng") || 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;