import React, { useState } from 'react'
import { Field } from 'formik';
import { Trans, useTranslation } from 'react-i18next';

export default function UploadPhoto(props) {

  const { label, name, typePos, type, meta, value, className,coverTitle, ...rest } = props
  const { t } = useTranslation()
  const [imgSrc, setImgSrc] = useState(value != '' ? value : '/img/example.png');
  const [videoSrc, setVideoSrc] = useState('');
  const inputFile = React.useRef(null);

  const btnClicked = () => {
    inputFile.current.click()
  }

  var reader = new FileReader();

  if (typeof (value) == "object" && value !== null) {

    reader.readAsDataURL(value);
    reader.onload = function (e) {
      setVideoSrc(e.target.result);
    };
  }

  React.useEffect(() => {
    if (value !== "" && typeof (value) === 'string') {
      setImgSrc(value);
    }

  }, [value]);
  

  let fileType;
  switch (props.control) {
    case 'photo':
      fileType = "image/*";
      break;
    case 'video':
      fileType = "video/*";
      break;
    case 'document':
      fileType = ".pdf,.doc,.docx";
      break;

    default:
      break;
  }

  return (
    <div className={`form-control image upload-photo-formik ${className}`}>
      <Field
        className="field"
        id={name}
        name={name}
        {...rest}>

        {({ form }) => (
          <>
            <div className="row">
              <div className="col-md-12">
                {props.control == "video"
                  ? <video controlsList="nodownload" controls className="w-100" src={videoSrc || value}></video>
                  : <img alt="uploaded-image" src={imgSrc} className="img-fluid img-responsive img-header" />
                }
              </div>
            </div>
            <div className="row">
              <div className={`${coverTitle ?  "col-12":"col-12"} d-flex align-items-center parent`} >
                <label className={`form-label ${props.control == "video" ? "d-none" : ""}`} htmlFor=""><Trans i18nKey={label} /></label>
                <button type="button" className={`btn upload-img-btn w-100 ${props.control == "video" ? "video-btn" : ""}`} onClick={btnClicked}>{ props.control == "video"? t('press_video') : t('press_here')}</button>
                <input
                  id="file"
                  name="file"
                  type="file"

                  className="upload-photo-input d-none"
                  accept={fileType}
                  ref={inputFile}
                  onChange={(event) => {


                    let file = event.currentTarget.files[0];
                    let reader = new FileReader();

                    if (file && file.type.match('image.*') || file && file.type.match('video.*')) {
                      reader.readAsDataURL(file);

                      reader.onloadend = function (e) {
                        setImgSrc(reader.result)
                        setVideoSrc(reader.result)
                      };

                      form.setFieldValue(props.name, file);
                      // { typePos && form.setFieldValue(props.typePos, type); }
                      // { props.name !== "background" && form.setFieldValue(typePos, type) }
                      // form.setFieldValue(props.type, props.control);
                    }

                    
                  }}
                />
              </div>

            </div>

          </>
        )}

      </Field >



    
    </div >
  )
}