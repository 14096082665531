import React from 'react';
import { Formik, Field, ErrorMessage, FieldArray, useFormikContext, Form } from "formik";
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from "react-redux";
import { updatePageIndex, updateRegData } from '../../../store/actions/auth';
import { updateTemp, clearTemp } from "../../../store/actions/temp";
import { Toast } from 'primereact/toast';


function Step_three() {

    // make the actions on the state
    const dispatch = useDispatch();

    const { t } = useTranslation();

    let initialValues = {
        focal_points: [{
            name: "",
            email: "",
            phone: "",
            position: ""
        }]
    }

    const formRef = React.useRef();

    const toast = React.useRef(null);

    function handleSubmit(values) {

        dispatch(updateRegData({ focal_points: values.focal_points }));
        dispatch(clearTemp());
        dispatch(updatePageIndex(4));
        window.location.reload();

    }

    function handleAdd() {

        if (!("focal_points" in formRef.current.errors) && formRef.current.dirty) {
            let values = formRef.current.values;
            dispatch(updateTemp(values.focal_points));
        }

    }


    function formBody({ handleSubmit, values }) {

        return (

            <Form className="auth-form step_form" onSubmit={handleSubmit} >

                <div className="title">{t("auth.register.step_4.title")}</div>

                <FieldArray name="focal_points" render={(arrayHelpers) => (
                    <>

                        {
                            values.focal_points.map((item, index) => (
                                <div className='mb-5'>
                                    <div className="row mb-0 mb-md-3 no-gutters">
                                        <div className="col-sm-4 mb-md-0 mb-3">
                                            {/* <label>{t("auth.register.step_1.fullName")}</label> */}
                                            <Field
                                                required
                                                className="w-100 input-style"
                                                name={`focal_points[${index}].name`}
                                                placeholder={t("auth.register.step_1.fullName")}
                                            />
                                        </div>

                                        <div className="col-sm-4 mb-md-0 mb-3 px-sm-3">
                                            {/* <label>{t("auth.register.step_1.emailAddress")}</label> */}
                                            <Field
                                                required
                                                className="w-100 input-style"
                                                type="email"
                                                name={`focal_points[${index}].email`}
                                                placeholder={t("auth.register.step_1.emailAddress")}
                                            />
                                        </div>

                                        <div className="col-sm-4 mb-md-0 mb-3">
                                            {/* <label>{t("auth.register.step_1.website")}</label> */}
                                            <Field
                                                required
                                                className="w-100 input-style"
                                                name={`focal_points[${index}].phone`}
                                                placeholder={t("auth.register.step_1.phone")}
                                            />
                                            <div className="mt-2 text-danger">
                                                <ErrorMessage name={`focal_points[${index}].phone`} />
                                            </div>
                                        </div>

                                    </div>
                                    <div className="row mb-0 mb-md-3 no-gutters">

                                        <div className="col-sm-4 mb-md-0 mb-3">
                                            {/* <label>{t("auth.register.step_1.website")}</label> */}
                                            <Field
                                                required
                                                className="w-100 input-style"
                                                name={`focal_points[${index}].position`}
                                                placeholder={t("auth.register.step_4.position")}
                                            />
                                        </div>

                                    </div>
                                </div>
                            ))
                        }

                        <div className="row justify-content-end">
                            <button type="button" onClick={() => {
                                handleAdd(); arrayHelpers.push({
                                    name: "",
                                    email: "",
                                    phone: "",
                                    position: ""
                                })
                            }} className="reset_button px-4 main-color text-capitalize d-inline-block">{t("addAnother")}</button>
                        </div>
                    </>
                )}
                />

                <div className="row form-controls">

                    <a href="/login" className="alreadyReg d-flex align-items-center">{t("auth.register.already")}</a>
                    <button type="submit" className="next-button main-buttonII px-5 text-capitalize d-inline-block">{t("next")}</button>

                </div>



            </ Form >
        )
    }

    // handleValidation
    function handleValidation() {
        const schema = Yup.object().shape({
            focal_points: Yup.array().of(
                Yup.object().shape({
                    name: Yup.string().required(t("req")),
                    email: Yup.string().required(t("req")),
                    phone: Yup.string().matches(/^[0-9]+$/, t("mustNum")).min(11, t("minNum")).max(11, t("minNum")).required(t("req")),
                    position: Yup.string().required(t("req"))
                })
            )
        })
        return schema;
    }

    return (
        <div className="register">
            < Toast position="top-center" ref={toast} />
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={handleValidation}
                innerRef={formRef}
            >

                {formBody}

            </Formik>
        </div >
    )
}

export default Step_three;
