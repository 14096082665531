import React from 'react';
import * as Yup from "yup";
import { useParams } from "react-router";
import { Toast } from 'primereact/toast';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { Formik, Field, ErrorMessage } from "formik";
import axiosInstance from '../../../containers/services/axiosInstance';

function ApplyEventModal(props) {

  const { id } = useParams();
  const { t } = useTranslation();
  const toast = React.useRef(null);
  const Lang = localStorage.getItem("i18nextLng");
  const [error, setError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const authReducer = JSON.parse(localStorage.getItem('state')).authReducer ? JSON.parse(localStorage.getItem('state')).authReducer : null;
  const [initialValues, setInitialValues] = React.useState({
    secret: 0,
    name: authReducer ? authReducer.user.name : "",
    phone: authReducer ? authReducer.user.phone : "",
    email: authReducer ? authReducer.user.email : "",
    gender: authReducer ? authReducer.gender : "",
    id_number: authReducer ? authReducer.id_number : "",
    comment: "",
  });

  function validationSchema() {
    const schema = Yup.object().shape({
      name: Yup.string().required(t("req")),
      phone: Yup.string().matches(/^[0-9]+$/, t("mustNum")).min(11, t("minNum")).max(11, t("minNum")).required(t("req")),
      gender: Yup.string().required(t("req")),
    })
    return schema;
  }

  function handleSubmit(values) {

    setIsLoading(true);

    axiosInstance().post(`/event/${id}/apply`, values, { 'headers': { 'locale': Lang } })
      .then(res => {
        setError(false);
        setIsLoading(false);
        toast.current.show({ severity: 'success', summary: t("success"), detail: t("successMsg"), life: 2000 });
      })
      .catch(err => {
        setIsLoading(false);
        setError(true);
        if (err.response !== null & err.response !== undefined) {
          err.response.data.errors
            ?
            toast.current.show({ severity: 'warn', summary: t("warn"), detail: Object.values(err.response.data.errors && err.response.data.errors) })
            :
            toast.current.show({ severity: 'warn', summary: t("warn"), detail: err.response.data.details.message })
        } else {
          toast.current.show({ severity: 'warn', summary: t("warn"), detail: t("tryLater") })
        }
      })
  }

  function formBody({ handleSubmit, values }) {
    return (
      <form onSubmit={handleSubmit}>
        <Modal.Body>

          <label>{t('auth.login.userPH')}</label>
          <Field
            className="w-100 input-style mb-2"
            name="name"
            placeholder={t("auth.login.userPH")}
          />
          <div className="mt-2 text-danger mb-3">
            <ErrorMessage name="name" />
          </div>

          <label>{t('auth.register.step_1.phone')}</label>
          <Field
            className="w-100 input-style mb-2"
            name="phone"
            placeholder={t("auth.register.step_1.phone")}
          />
          <div className="mt-2 text-danger mb-3">
            <ErrorMessage name="phone" />
          </div>

          <label>{t('auth.register.step_1.ID')}</label>
          <Field
            className="w-100 input-style mb-2"
            name="id_number"
            placeholder={t("auth.register.step_1.ID")}
          />
          <div className="mt-2 text-danger mb-3">
            <ErrorMessage name="id_number" />
          </div>

          <label>{t('auth.register.step_1.gender')}</label>
          <Field name="gender">
            {({ form }) => (
              <select
                className="w-100 input-style mb-2"
                style={{ marginTop: "1px" }}
                name="gender"
                onChange={(e) => {
                  let scopeValues = { ...values };
                  scopeValues.gender = e.target.value;
                  form.setFieldValue('gender', e.target.value)
                }
                }
              >
                <option value="" disabled selected>
                  {t("auth.register.step_1.gender")}
                </option>
                <option value="male" selected={values.gender == 'male'}>
                  {t("auth.register.step_1.male")}
                </option>
                <option value="female" selected={values.gender == 'female'}>
                  {t("auth.register.step_1.female")}
                </option>
                <option value="other" selected={values.gender == 'other'}>
                  {t("other")}
                </option>
              </select>
            )}
          </Field>
          <div className="mt-2 text-danger mb-3">
            <ErrorMessage name="gender" />
          </div>

          <label>{t('comment')}</label>
          <Field
            className="w-100 input-style mb-2"
            name="comment"
            placeholder={t("comment")}
          />
          <div className="mt-2 text-danger mb-3">
            <ErrorMessage name="comment" />
          </div>

          {/* <label>{t('keep_secret')}</label>
          <Field>
            {({ form }) => (
              <input type="checkbox"
                name="secret"
                onChange={(e) => {
                  let scopeValues = { ...values };
                  scopeValues.secret = e.target.value;
                  form.setFieldValue('secret', e.target.value ? 1 : 0)
                }
                }
              />
            )}
          </Field> */}

        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary mx-3" type="submit">{t('book')}</button>
          <button className="btn btn-secondary mx-3" type="button" onClick={props.onHide}>{t('cancel')}</button>
        </Modal.Footer>
      </form>
    )
  }



  return (
    <div className="position-relative">
      {/* TOAST */}
      {
        error
          ?
          < Toast position="top-center" ref={toast} />
          :
          < Toast position="top-center" ref={toast} onRemove={props.onHide} />
      }

      {
        isLoading
        &&
        <div className="text-center spinner-container">
          <i className="fas fa-circle-notch fa-spin fa-4x"></i>
        </div>
      }

      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Formik validationSchema={validationSchema} initialValues={initialValues} enableReinitialize={true} onSubmit={handleSubmit}>
          {formBody}
        </Formik>
      </Modal>
    </div>
  );
}

export default ApplyEventModal