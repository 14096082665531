import * as actionTypes from "../actions/actionTypes";

const initialState = {
  index: 1,
  approved: "",
  service_provider_id: "",
  user: {
    name: "",
    role: "",
    nickname: "",
    email: "",
    password: "",
    password_confirmation: "",
    phone: "",
    website: "",
    facebook: "",
    linkedin: "",
    instagram: "",
    twitter: ""   
  },

};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.REGISTER_PAGE:
      state.index = action.payload;
      return state;
      
    case actionTypes.ADD_REGISTER_DATA:
      state.user = action.payload;
      return state;
      
    case actionTypes.UPDATE_REGISTER_DATA:
      state.user = {...state.user, ...action.payload}
      return state;

    case actionTypes.AUTH:
      state = action.payload;
      return state;

    case actionTypes.UPAPP:
      state.approved = true;
      return state;

    case actionTypes.UPAUTH:

      state.age = action.payload.age;
      state.id_number = action.payload.id_number;
      state.gender = action.payload.gender;

      state.user = action.payload.user;
      
      return state;

    case actionTypes.UPAUTHM:

      state.user.email = action.payload.email;
      state.user.name = action.payload.name;
      state.user.phone = action.payload.phone;

      return state;

    case actionTypes.LOGOUT:
      localStorage.removeItem("state");
      localStorage.removeItem("target");
      localStorage.removeItem("services");
      localStorage.removeItem("event_target");
      localStorage.removeItem("event_services");
      localStorage.removeItem("state");
      return initialState;

    default:
      return state;
  }
};

export default reducer;
