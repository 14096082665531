import React from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import Pager from '../../../../components/Pager';
import AdminCard from '../../../../components/Cards/AdminCard';
import { NavLink } from 'react-router-dom';


function Resources({ isAdmin }) {

    // loading state
    const [isLoading, setIsLoading] = React.useState(true);

    const { t } = useTranslation();

    const [resourcesList, setResourcesList] = React.useState([]);

    const [total, setTotal] = React.useState(0);

    const Lang = localStorage.getItem("i18nextLng");

    const onBasicPageChange = (event) => {

        setIsLoading(true);
        axios(`/resource?page=${event.page + 1}&perPage=${event.rows}`, { 'headers': { 'locale': Lang } })
            .then((response) => {
                setTotal(response.data.data.pageInfo.total);
                setResourcesList(response.data.data.collection);
                setIsLoading(false)
            })
            .catch((err) => {
                // Handle Error Here
                setIsLoading(false);
            });
    }

    React.useEffect(() => {

        setIsLoading(true)

        axios(`/resource`, { 'headers': { 'locale': Lang } })
            .then((response) => {
                setTotal(response.data.data.pageInfo.total);
                setResourcesList(response.data.data.collection);
                setIsLoading(false)
            })
            .catch((error) => {
                setIsLoading(false)
            })

    }, [Lang])


    return (
        <div className="resources">
            <div className="position-relative">
                {
                    isLoading
                    &&
                    <div className="text-center spinner-container">
                        <i className="fas fa-circle-notch fa-spin fa-4x"></i>
                    </div>
                }
                <div className={isAdmin ? "min-H pt-5 SP-Dash px-4" : "min-H container-fluid my-5 SP-Dash px-5"}>

                    <div className="labels d-flex justify-content-between w-100 align-items-center mb-5">
                        <span className="reset-bold text-capitalize main-title-res">{t("admin.resources")}</span>
                        {isAdmin && <button type="button" className="submit-button my-auto mx-3 custom text-capitalize d-inline-block"><NavLink className="white" to="/admin/resources/add">{t("admin.addNew")}</NavLink></button>}
                    </div>

                    <div className="cards_list service_cards row">

                        {
                            resourcesList.length > 0
                                ?
                                resourcesList.map((item, i) => {
                                    return <div className="col-lg-3 col-md-4 col-sm-6 gallery-img-card mb-4" key={i}>
                                        <AdminCard
                                            type={isAdmin ? "admin/resources" : "resources"}
                                            data={item}
                                        />
                                    </div>
                                })
                                :
                                <p className="lead mx-3 no-cont">{t("noContent")}</p>
                        }

                    </div>
                </div >
            </div>
            <Pager total={total} onPageChange={onBasicPageChange} className={`${resourcesList.length ? '' : 'd-none'}`} />
        </div>
    )
}

export default Resources
