import * as actionTypes from "../actions/actionTypes";


export const setIsLoading = (n) => {
  return {
    type: actionTypes.IS_LOADING,
    payload: n,
  };
};

