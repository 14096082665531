import React from 'react';
import CarouselSection from '../../components/Carousel';

export default function Home() {

    const items = [
        { img: "/img/home/caro1.jpg", title: "WELCOME TO COMPASS | EGYPT", desc: "Free directories: directories are perfect for customers that are searching for a particular topic. What’s great about them?" }
    ]

    return (
        <div className="home-sections">

            <CarouselSection items={items} />

            {/* about */}
            <div className="about-section">
                <div className="container-fluid px-md-5">
                    <h2 className="text-center responsive-head">What is COMPASS | EGYPT</h2>
                    <div className="row">
                        <div className="col-md-6 left-section">
                            <p>
                                Egypt is an origin, transit and destination country for migrants. It is considered as an increasingly important gateway to Europe for migrants originating from African and Arab countries. Egypt is currently experiencing what has been called the permanence of temporary migration, whereby migration towards Arab countries is becoming less temporary and outnumbers long-term migration to Europe and North America.
                            </p>
                        </div>
                        <div className="col-md-6">
                            <video className="w-100" controls>
                                <source className="w-100 h-100" src="./img/video.mp4" type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </div>
            </div>

            {/* How it works */}
            <div className="HIW-section text-center">
                <div className="container-fluid px-md-5">
                    <h2 className="text-center responsive-head">How it works</h2>
                    <div className="row">
                        <div className="col-md-4 mb-3">
                            <img src="./img/home/register.svg" alt="register" />
                            <h3>Register</h3>
                            <p>
                                Most people feel they have some basic flaws with their appearance, and the truth is that the stars are no exception.
                            </p>
                        </div>
                        <div className="col-md-4 mb-3">
                            <img src="./img/home/book.svg" alt="book" />
                            <h3>Book your service</h3>
                            <p>
                                Most people feel they have some basic flaws with their appearance, and the truth is that the stars are no exception.
                            </p>
                        </div>
                        <div className="col-md-4 mb-3">
                            <img src="./img/home/service.png" alt="service" />
                            <h3>Get your service</h3>
                            <p>
                                Most people feel they have some basic flaws with their appearance, and the truth is that the stars are no exception.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {/* download app */}
            <div className="app-section">
                <div className="container-fluid px-md-5">
                    <p className="down-p">
                        Downlaod COMPASS Mobile Application <br />
                        On Your Cellphone !
                    </p>
                    <div className="row position-relative">
                        <div className="col-xl-6 start-section">
                            <h3>Download the App now</h3>
                            <div className="download-pics">
                                <a href=""><img src="./img/home/app-store.png" alt="app store" /></a>
                                <a href=""><img src="./img/home/g-play.png" alt="google play" /></a>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <img className="w-100" src="./img/home/mob.png" alt="mobile" />
                        </div>
                    </div>
                </div>
            </div>

        </div >
    )
}
