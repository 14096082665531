import * as actionTypes from "./actionTypes";


export const updateTemp = (n) => {
  return {
    type: actionTypes.UPDATE_TEMP,
    payload: n,
  };
};

export const clearTemp = (n) => {
  return {
    type: actionTypes.CLEAR_TEMP,
    payload: n,
  };
};

export const updateTags = (n) => {
  return {
    type: actionTypes.UPDATE_TAGS,
    payload: n,
  };
};

