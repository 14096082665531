import React from 'react';
import Input from './Input';
import UploadPhoto from './UploadPhoto';
import UploadDocument from './UploadDocument';
import UploadAudio from './UploadAudio';


export default function FormikControl (props){
    const { control, ...rest } = props
    switch (control) {
        case 'input':
            return <Input name={props.name} {...rest} />
        // case 'texteditor':
        //     return <FormEditor  {...rest}/>
        case 'photo':
            return <UploadPhoto control={props.control}  {...rest}/>
        case 'video':
            return <UploadPhoto control={props.control}  {...rest}/>
        case 'audio':
            return <UploadAudio control={props.control}  {...rest}/>
        case 'document':
            return <UploadDocument control={props.control}  {...rest}/>
       
        default:
            return null;
    }
};

